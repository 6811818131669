"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDisplayName = getDisplayName;
/**
 * Retrieve component display name for HOC components, according to:
 * https://reactjs.org/docs/higher-order-components.html
 */
function getDisplayName(component) {
    return component.displayName || component.name || 'Component';
}
