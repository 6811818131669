"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateInput = void 0;
const translation_1 = require("../../common/js/helper/translation");
const extendedLatinPattern = /^[\u0020-\u007F\u00A0-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF\uAB30-\uAB6F]*$/;
const validateInput = (input) => {
    const value = input.value;
    const isValid = extendedLatinPattern.test(value ?? '');
    input.setCustomValidity(isValid ? '' : (0, translation_1.t)('registration.error.only_latin_characters'));
};
exports.validateInput = validateInput;
