"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findRecord = exports.concat = exports.nonNullOrUndefined = exports.simpleUnique = exports.last = exports.first = void 0;
/**
 * Get the first element from an array.
 *
 * @param {T[]} array
 * @return {T | undefined}
 */
const first = (array) => {
    return array[0];
};
exports.first = first;
/**
 * Get the last element from an array.
 *
 * @param {T[]} array
 * @return {T | undefined}
 */
const last = (array) => {
    const length = array.length;
    if (length < 1) {
        return;
    }
    return array[length - 1];
};
exports.last = last;
/**
 * Remove duplicates by a simple compare.
 *
 * @param {T[]} array
 * @return {T[]}
 */
const simpleUnique = (array) => {
    return array.filter((item, index, items) => items.indexOf(item) === index);
};
exports.simpleUnique = simpleUnique;
/**
 * Remove all null and undefined values.
 *
 * @param {Array<undefined | null | T>} array
 * @return {T[]}
 */
const nonNullOrUndefined = (array) => {
    return array.filter((item) => item !== null && item !== undefined);
};
exports.nonNullOrUndefined = nonNullOrUndefined;
/**
 * Concatenates all elements of an array.
 *
 * @param {Array<undefined | null | string>} array
 * @param {string | null} separator
 *
 * @return {string}
 */
const concat = (array, separator) => {
    return array
        .filter((value) => {
        return value && value.trim().length > 0;
    })
        .join(separator ?? undefined);
};
exports.concat = concat;
const findRecord = (values, property, value) => {
    return values.find((item) => item[property] === value);
};
exports.findRecord = findRecord;
