"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassiveCustomer = instanceOfMassiveCustomer;
exports.MassiveCustomerFromJSON = MassiveCustomerFromJSON;
exports.MassiveCustomerFromJSONTyped = MassiveCustomerFromJSONTyped;
exports.MassiveCustomerToJSON = MassiveCustomerToJSON;
const runtime_1 = require("../runtime");
const Customer_1 = require("./Customer");
const MassiveAddress_1 = require("./MassiveAddress");
const MassiveCompany_1 = require("./MassiveCompany");
/**
 * Check if a given object implements the MassiveCustomer interface.
 */
function instanceOfMassiveCustomer(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "defaultMassiveAddressId" in value;
    isInstance = isInstance && "massiveCompanyId" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function MassiveCustomerFromJSON(json) {
    return MassiveCustomerFromJSONTyped(json, false);
}
function MassiveCustomerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'customerId': json['customerId'],
        'defaultMassiveAddressId': json['defaultMassiveAddressId'],
        'massiveCompanyId': json['massiveCompanyId'],
        'phoneNumber1': !(0, runtime_1.exists)(json, 'phoneNumber1') ? undefined : json['phoneNumber1'],
        'phoneNumber2': !(0, runtime_1.exists)(json, 'phoneNumber2') ? undefined : json['phoneNumber2'],
        'department': !(0, runtime_1.exists)(json, 'department') ? undefined : json['department'],
        'externalId': !(0, runtime_1.exists)(json, 'externalId') ? undefined : json['externalId'],
        'role': !(0, runtime_1.exists)(json, 'role') ? undefined : json['role'],
        'portalRole': !(0, runtime_1.exists)(json, 'portalRole') ? undefined : json['portalRole'],
        'iotRole': !(0, runtime_1.exists)(json, 'iotRole') ? undefined : json['iotRole'],
        'deleted': json['deleted'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'customer': !(0, runtime_1.exists)(json, 'customer') ? undefined : (0, Customer_1.CustomerFromJSON)(json['customer']),
        'defaultMassiveAddress': !(0, runtime_1.exists)(json, 'defaultMassiveAddress') ? undefined : (0, MassiveAddress_1.MassiveAddressFromJSON)(json['defaultMassiveAddress']),
        'massiveCompany': !(0, runtime_1.exists)(json, 'massiveCompany') ? undefined : (0, MassiveCompany_1.MassiveCompanyFromJSON)(json['massiveCompany']),
    };
}
function MassiveCustomerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'customerId': value.customerId,
        'defaultMassiveAddressId': value.defaultMassiveAddressId,
        'massiveCompanyId': value.massiveCompanyId,
        'phoneNumber1': value.phoneNumber1,
        'phoneNumber2': value.phoneNumber2,
        'department': value.department,
        'externalId': value.externalId,
        'role': value.role,
        'portalRole': value.portalRole,
        'iotRole': value.iotRole,
        'deleted': value.deleted,
        'customer': (0, Customer_1.CustomerToJSON)(value.customer),
        'defaultMassiveAddress': (0, MassiveAddress_1.MassiveAddressToJSON)(value.defaultMassiveAddress),
        'massiveCompany': (0, MassiveCompany_1.MassiveCompanyToJSON)(value.massiveCompany),
    };
}
