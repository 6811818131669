"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDateFormat = void 0;
const getDateFormat = (date, options) => {
    if (!date) {
        return null;
    }
    const formatOptions = {
        dateStyle: 'medium',
        ...options,
    };
    return date.toLocaleString(navigator.language, formatOptions);
};
exports.getDateFormat = getDateFormat;
