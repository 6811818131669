"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLanguageFromLocale = void 0;
const array_1 = require("./array");
const getLanguageFromLocale = (locale) => {
    if (!locale) {
        return null;
    }
    const language = (0, array_1.first)(locale.split('-'));
    return !!language ? language : null;
};
exports.getLanguageFromLocale = getLanguageFromLocale;
