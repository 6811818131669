"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOrder = instanceOfOrder;
exports.OrderFromJSON = OrderFromJSON;
exports.OrderFromJSONTyped = OrderFromJSONTyped;
exports.OrderToJSON = OrderToJSON;
const runtime_1 = require("../runtime");
const CartDelivery_1 = require("./CartDelivery");
const CartPrice_1 = require("./CartPrice");
const Currency_1 = require("./Currency");
const LineItem_1 = require("./LineItem");
const OrderAddress_1 = require("./OrderAddress");
const OrderCustomFields_1 = require("./OrderCustomFields");
const OrderCustomer_1 = require("./OrderCustomer");
const OrderExtensions_1 = require("./OrderExtensions");
const OrderTransaction_1 = require("./OrderTransaction");
const ShippingCosts_1 = require("./ShippingCosts");
const StateMachineState_1 = require("./StateMachineState");
/**
 * Check if a given object implements the Order interface.
 */
function instanceOfOrder(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "orderNumber" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "lineItems" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "deepLinkCode" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "orderDateTime" in value;
    return isInstance;
}
function OrderFromJSON(json) {
    return OrderFromJSONTyped(json, false);
}
function OrderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'orderNumber': json['orderNumber'],
        'orderCustomer': !(0, runtime_1.exists)(json, 'orderCustomer') ? undefined : (0, OrderCustomer_1.OrderCustomerFromJSON)(json['orderCustomer']),
        'token': json['token'],
        'lineItems': (json['lineItems'] === null ? null : json['lineItems'].map(LineItem_1.LineItemFromJSON)),
        'price': (0, CartPrice_1.CartPriceFromJSON)(json['price']),
        'transactions': !(0, runtime_1.exists)(json, 'transactions') ? undefined : (json['transactions'] === null ? null : json['transactions'].map(OrderTransaction_1.OrderTransactionFromJSON)),
        'stateMachineState': !(0, runtime_1.exists)(json, 'stateMachineState') ? undefined : (0, StateMachineState_1.StateMachineStateFromJSON)(json['stateMachineState']),
        'deepLinkCode': json['deepLinkCode'],
        'createdAt': (new Date(json['createdAt'])),
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : (0, OrderCustomFields_1.OrderCustomFieldsFromJSON)(json['customFields']),
        'currency': !(0, runtime_1.exists)(json, 'currency') ? undefined : (0, Currency_1.CurrencyFromJSON)(json['currency']),
        'orderDateTime': (new Date(json['orderDateTime'])),
        'shippingCosts': !(0, runtime_1.exists)(json, 'shippingCosts') ? undefined : (0, ShippingCosts_1.ShippingCostsFromJSON)(json['shippingCosts']),
        'deliveries': !(0, runtime_1.exists)(json, 'deliveries') ? undefined : (json['deliveries'].map(CartDelivery_1.CartDeliveryFromJSON)),
        'billingAddress': !(0, runtime_1.exists)(json, 'billingAddress') ? undefined : (0, OrderAddress_1.OrderAddressFromJSON)(json['billingAddress']),
        'customerComment': !(0, runtime_1.exists)(json, 'customerComment') ? undefined : json['customerComment'],
        'extensions': !(0, runtime_1.exists)(json, 'extensions') ? undefined : (0, OrderExtensions_1.OrderExtensionsFromJSON)(json['extensions']),
    };
}
function OrderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'orderNumber': value.orderNumber,
        'orderCustomer': (0, OrderCustomer_1.OrderCustomerToJSON)(value.orderCustomer),
        'token': value.token,
        'lineItems': (value.lineItems === null ? null : value.lineItems.map(LineItem_1.LineItemToJSON)),
        'price': (0, CartPrice_1.CartPriceToJSON)(value.price),
        'transactions': value.transactions === undefined ? undefined : (value.transactions === null ? null : value.transactions.map(OrderTransaction_1.OrderTransactionToJSON)),
        'stateMachineState': (0, StateMachineState_1.StateMachineStateToJSON)(value.stateMachineState),
        'deepLinkCode': value.deepLinkCode,
        'customFields': (0, OrderCustomFields_1.OrderCustomFieldsToJSON)(value.customFields),
        'currency': (0, Currency_1.CurrencyToJSON)(value.currency),
        'shippingCosts': (0, ShippingCosts_1.ShippingCostsToJSON)(value.shippingCosts),
        'deliveries': value.deliveries === undefined ? undefined : (value.deliveries.map(CartDelivery_1.CartDeliveryToJSON)),
        'billingAddress': (0, OrderAddress_1.OrderAddressToJSON)(value.billingAddress),
        'customerComment': value.customerComment,
        'extensions': (0, OrderExtensions_1.OrderExtensionsToJSON)(value.extensions),
    };
}
