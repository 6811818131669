"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassiveCompanyValidateResult = instanceOfMassiveCompanyValidateResult;
exports.MassiveCompanyValidateResultFromJSON = MassiveCompanyValidateResultFromJSON;
exports.MassiveCompanyValidateResultFromJSONTyped = MassiveCompanyValidateResultFromJSONTyped;
exports.MassiveCompanyValidateResultToJSON = MassiveCompanyValidateResultToJSON;
const MassiveCompanyValidateResultData_1 = require("./MassiveCompanyValidateResultData");
/**
 * Check if a given object implements the MassiveCompanyValidateResult interface.
 */
function instanceOfMassiveCompanyValidateResult(value) {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    return isInstance;
}
function MassiveCompanyValidateResultFromJSON(json) {
    return MassiveCompanyValidateResultFromJSONTyped(json, false);
}
function MassiveCompanyValidateResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': (0, MassiveCompanyValidateResultData_1.MassiveCompanyValidateResultDataFromJSON)(json['data']),
    };
}
function MassiveCompanyValidateResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': (0, MassiveCompanyValidateResultData_1.MassiveCompanyValidateResultDataToJSON)(value.data),
    };
}
