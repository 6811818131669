"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfProductCalculatedListingPriceItem = instanceOfProductCalculatedListingPriceItem;
exports.ProductCalculatedListingPriceItemFromJSON = ProductCalculatedListingPriceItemFromJSON;
exports.ProductCalculatedListingPriceItemFromJSONTyped = ProductCalculatedListingPriceItemFromJSONTyped;
exports.ProductCalculatedListingPriceItemToJSON = ProductCalculatedListingPriceItemToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ProductCalculatedListingPriceItem interface.
 */
function instanceOfProductCalculatedListingPriceItem(value) {
    let isInstance = true;
    return isInstance;
}
function ProductCalculatedListingPriceItemFromJSON(json) {
    return ProductCalculatedListingPriceItemFromJSONTyped(json, false);
}
function ProductCalculatedListingPriceItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'unitPrice': !(0, runtime_1.exists)(json, 'unitPrice') ? undefined : json['unitPrice'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
        'totalPrice': !(0, runtime_1.exists)(json, 'totalPrice') ? undefined : json['totalPrice'],
    };
}
function ProductCalculatedListingPriceItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'unitPrice': value.unitPrice,
        'quantity': value.quantity,
        'totalPrice': value.totalPrice,
    };
}
