"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CmsBlockTypeEnum = void 0;
exports.instanceOfCmsBlock = instanceOfCmsBlock;
exports.CmsBlockFromJSON = CmsBlockFromJSON;
exports.CmsBlockFromJSONTyped = CmsBlockFromJSONTyped;
exports.CmsBlockToJSON = CmsBlockToJSON;
const runtime_1 = require("../runtime");
const CmsSection_1 = require("./CmsSection");
const CmsSlot_1 = require("./CmsSlot");
const Media_1 = require("./Media");
/**
 * @export
 */
exports.CmsBlockTypeEnum = {
    Text: 'text',
    TextTwoColumn: 'text-two-column',
    TextThreeColumn: 'text-three-column',
    TextOnImage: 'text-on-image'
};
/**
 * Check if a given object implements the CmsBlock interface.
 */
function instanceOfCmsBlock(value) {
    let isInstance = true;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "sectionId" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function CmsBlockFromJSON(json) {
    return CmsBlockFromJSONTyped(json, false);
}
function CmsBlockFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'position': json['position'],
        'type': json['type'],
        'locked': !(0, runtime_1.exists)(json, 'locked') ? undefined : json['locked'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'sectionPosition': !(0, runtime_1.exists)(json, 'sectionPosition') ? undefined : json['sectionPosition'],
        'marginTop': !(0, runtime_1.exists)(json, 'marginTop') ? undefined : json['marginTop'],
        'marginBottom': !(0, runtime_1.exists)(json, 'marginBottom') ? undefined : json['marginBottom'],
        'marginLeft': !(0, runtime_1.exists)(json, 'marginLeft') ? undefined : json['marginLeft'],
        'marginRight': !(0, runtime_1.exists)(json, 'marginRight') ? undefined : json['marginRight'],
        'backgroundColor': !(0, runtime_1.exists)(json, 'backgroundColor') ? undefined : json['backgroundColor'],
        'backgroundMediaId': !(0, runtime_1.exists)(json, 'backgroundMediaId') ? undefined : json['backgroundMediaId'],
        'backgroundMediaMode': !(0, runtime_1.exists)(json, 'backgroundMediaMode') ? undefined : json['backgroundMediaMode'],
        'cssClass': !(0, runtime_1.exists)(json, 'cssClass') ? undefined : json['cssClass'],
        'sectionId': json['sectionId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'section': !(0, runtime_1.exists)(json, 'section') ? undefined : (0, CmsSection_1.CmsSectionFromJSON)(json['section']),
        'backgroundMedia': !(0, runtime_1.exists)(json, 'backgroundMedia') ? undefined : (0, Media_1.MediaFromJSON)(json['backgroundMedia']),
        'slots': !(0, runtime_1.exists)(json, 'slots') ? undefined : (json['slots'].map(CmsSlot_1.CmsSlotFromJSON)),
    };
}
function CmsBlockToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'position': value.position,
        'type': value.type,
        'locked': value.locked,
        'name': value.name,
        'sectionPosition': value.sectionPosition,
        'marginTop': value.marginTop,
        'marginBottom': value.marginBottom,
        'marginLeft': value.marginLeft,
        'marginRight': value.marginRight,
        'backgroundColor': value.backgroundColor,
        'backgroundMediaId': value.backgroundMediaId,
        'backgroundMediaMode': value.backgroundMediaMode,
        'cssClass': value.cssClass,
        'sectionId': value.sectionId,
        'section': (0, CmsSection_1.CmsSectionToJSON)(value.section),
        'backgroundMedia': (0, Media_1.MediaToJSON)(value.backgroundMedia),
        'slots': value.slots === undefined ? undefined : (value.slots.map(CmsSlot_1.CmsSlotToJSON)),
    };
}
