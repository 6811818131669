"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numericCompare = exports.naturalCompare = exports.localeCompare = exports.priceCompare = void 0;
const money_1 = require("./money");
const priceCompare = (a, b) => (0, money_1.lowestPriceFromProduct)(a) - (0, money_1.lowestPriceFromProduct)(b);
exports.priceCompare = priceCompare;
const localeCompare = (a, b) => a.localeCompare(b, undefined, { numeric: true });
exports.localeCompare = localeCompare;
const naturalCompare = (a, b, localeCode) => a.toLowerCase().localeCompare(b.toLowerCase(), localeCode, { sensitivity: 'base' });
exports.naturalCompare = naturalCompare;
const numericCompare = (localeCode) => new Intl.Collator(localeCode, { numeric: true }).compare;
exports.numericCompare = numericCompare;
