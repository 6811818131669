"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MassiveCategoryFilterPropertyFilterTypeEnum = void 0;
exports.instanceOfMassiveCategoryFilterProperty = instanceOfMassiveCategoryFilterProperty;
exports.MassiveCategoryFilterPropertyFromJSON = MassiveCategoryFilterPropertyFromJSON;
exports.MassiveCategoryFilterPropertyFromJSONTyped = MassiveCategoryFilterPropertyFromJSONTyped;
exports.MassiveCategoryFilterPropertyToJSON = MassiveCategoryFilterPropertyToJSON;
/**
 * @export
 */
exports.MassiveCategoryFilterPropertyFilterTypeEnum = {
    SingleChoice: 'singleChoice',
    MultiChoice: 'multiChoice',
    Range: 'range'
};
/**
 * Check if a given object implements the MassiveCategoryFilterProperty interface.
 */
function instanceOfMassiveCategoryFilterProperty(value) {
    let isInstance = true;
    isInstance = isInstance && "propertyName" in value;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "filterType" in value;
    isInstance = isInstance && "position" in value;
    return isInstance;
}
function MassiveCategoryFilterPropertyFromJSON(json) {
    return MassiveCategoryFilterPropertyFromJSONTyped(json, false);
}
function MassiveCategoryFilterPropertyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'propertyName': json['propertyName'],
        'propertyId': json['propertyId'],
        'filterType': json['filterType'],
        'position': json['position'],
    };
}
function MassiveCategoryFilterPropertyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'propertyName': value.propertyName,
        'propertyId': value.propertyId,
        'filterType': value.filterType,
        'position': value.position,
    };
}
