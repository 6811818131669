"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTaxRule = instanceOfTaxRule;
exports.TaxRuleFromJSON = TaxRuleFromJSON;
exports.TaxRuleFromJSONTyped = TaxRuleFromJSONTyped;
exports.TaxRuleToJSON = TaxRuleToJSON;
const runtime_1 = require("../runtime");
const Country_1 = require("./Country");
const Tax_1 = require("./Tax");
const TaxRuleData_1 = require("./TaxRuleData");
const TaxRuleType_1 = require("./TaxRuleType");
/**
 * Check if a given object implements the TaxRule interface.
 */
function instanceOfTaxRule(value) {
    let isInstance = true;
    isInstance = isInstance && "taxRuleTypeId" in value;
    isInstance = isInstance && "countryId" in value;
    isInstance = isInstance && "taxRate" in value;
    isInstance = isInstance && "taxId" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function TaxRuleFromJSON(json) {
    return TaxRuleFromJSONTyped(json, false);
}
function TaxRuleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'taxRuleTypeId': json['taxRuleTypeId'],
        'countryId': json['countryId'],
        'taxRate': json['taxRate'],
        'data': !(0, runtime_1.exists)(json, 'data') ? undefined : (0, TaxRuleData_1.TaxRuleDataFromJSON)(json['data']),
        'taxId': json['taxId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, TaxRuleType_1.TaxRuleTypeFromJSON)(json['type']),
        'country': !(0, runtime_1.exists)(json, 'country') ? undefined : (0, Country_1.CountryFromJSON)(json['country']),
        'tax': !(0, runtime_1.exists)(json, 'tax') ? undefined : (0, Tax_1.TaxFromJSON)(json['tax']),
    };
}
function TaxRuleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'taxRuleTypeId': value.taxRuleTypeId,
        'countryId': value.countryId,
        'taxRate': value.taxRate,
        'data': (0, TaxRuleData_1.TaxRuleDataToJSON)(value.data),
        'taxId': value.taxId,
        'type': (0, TaxRuleType_1.TaxRuleTypeToJSON)(value.type),
        'country': (0, Country_1.CountryToJSON)(value.country),
        'tax': (0, Tax_1.TaxToJSON)(value.tax),
    };
}
