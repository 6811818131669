"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassiveLicensePatchRequestBody = instanceOfMassiveLicensePatchRequestBody;
exports.MassiveLicensePatchRequestBodyFromJSON = MassiveLicensePatchRequestBodyFromJSON;
exports.MassiveLicensePatchRequestBodyFromJSONTyped = MassiveLicensePatchRequestBodyFromJSONTyped;
exports.MassiveLicensePatchRequestBodyToJSON = MassiveLicensePatchRequestBodyToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the MassiveLicensePatchRequestBody interface.
 */
function instanceOfMassiveLicensePatchRequestBody(value) {
    let isInstance = true;
    return isInstance;
}
function MassiveLicensePatchRequestBodyFromJSON(json) {
    return MassiveLicensePatchRequestBodyFromJSONTyped(json, false);
}
function MassiveLicensePatchRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'usage': !(0, runtime_1.exists)(json, 'usage') ? undefined : json['usage'],
        'contactId': !(0, runtime_1.exists)(json, 'contactId') ? undefined : json['contactId'],
    };
}
function MassiveLicensePatchRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'usage': value.usage,
        'contactId': value.contactId,
    };
}
