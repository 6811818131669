"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZkpProductDataCustomerTypeEnum = void 0;
exports.instanceOfZkpProductData = instanceOfZkpProductData;
exports.ZkpProductDataFromJSON = ZkpProductDataFromJSON;
exports.ZkpProductDataFromJSONTyped = ZkpProductDataFromJSONTyped;
exports.ZkpProductDataToJSON = ZkpProductDataToJSON;
const runtime_1 = require("../runtime");
/**
 * @export
 */
exports.ZkpProductDataCustomerTypeEnum = {
    Customer: 'customer',
    CustomerDiscount: 'customer_discount',
    Reseller: 'reseller',
    Zxx: 'zxx'
};
/**
 * Check if a given object implements the ZkpProductData interface.
 */
function instanceOfZkpProductData(value) {
    let isInstance = true;
    isInstance = isInstance && "currencyIsoCode" in value;
    return isInstance;
}
function ZkpProductDataFromJSON(json) {
    return ZkpProductDataFromJSONTyped(json, false);
}
function ZkpProductDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currencyIsoCode': json['currencyIsoCode'],
        'customerType': !(0, runtime_1.exists)(json, 'customerType') ? undefined : json['customerType'],
    };
}
function ZkpProductDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'currencyIsoCode': value.currencyIsoCode,
        'customerType': value.customerType,
    };
}
