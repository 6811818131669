"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfShippingService = instanceOfShippingService;
exports.ShippingServiceFromJSON = ShippingServiceFromJSON;
exports.ShippingServiceFromJSONTyped = ShippingServiceFromJSONTyped;
exports.ShippingServiceToJSON = ShippingServiceToJSON;
const ShippingMethodType_1 = require("./ShippingMethodType");
/**
 * Check if a given object implements the ShippingService interface.
 */
function instanceOfShippingService(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "currencyIsoCode" in value;
    isInstance = isInstance && "totalCharges" in value;
    isInstance = isInstance && "position" in value;
    return isInstance;
}
function ShippingServiceFromJSON(json) {
    return ShippingServiceFromJSONTyped(json, false);
}
function ShippingServiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, ShippingMethodType_1.ShippingMethodTypeFromJSON)(json['type']),
        'code': json['code'],
        'description': json['description'],
        'currencyIsoCode': json['currencyIsoCode'],
        'totalCharges': json['totalCharges'],
        'position': json['position'],
    };
}
function ShippingServiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, ShippingMethodType_1.ShippingMethodTypeToJSON)(value.type),
    };
}
