"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TooltipPosition;
(function (TooltipPosition) {
    TooltipPosition["bottomCenter"] = "bottom-center";
    TooltipPosition["bottomLeft"] = "bottom-left";
    TooltipPosition["bottomRight"] = "bottom-right";
    TooltipPosition["leftCenter"] = "left";
    TooltipPosition["rightCenter"] = "right";
    TooltipPosition["topCenter"] = "top-center";
    TooltipPosition["topLeft"] = "top-left";
    TooltipPosition["topRight"] = "top-right";
})(TooltipPosition || (TooltipPosition = {}));
exports.default = TooltipPosition;
