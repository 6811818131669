"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Sulu - Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisrupterItemVisibilityConditionEnum = void 0;
exports.instanceOfDisrupterItem = instanceOfDisrupterItem;
exports.DisrupterItemFromJSON = DisrupterItemFromJSON;
exports.DisrupterItemFromJSONTyped = DisrupterItemFromJSONTyped;
exports.DisrupterItemToJSON = DisrupterItemToJSON;
const runtime_1 = require("../runtime");
const DisrupterItemLink_1 = require("./DisrupterItemLink");
const DisrupterItemMedia_1 = require("./DisrupterItemMedia");
/**
 * @export
 */
exports.DisrupterItemVisibilityConditionEnum = {
    Always: 'always',
    NoZundConnectLicence: 'no_zund_connect_licence'
};
/**
 * Check if a given object implements the DisrupterItem interface.
 */
function instanceOfDisrupterItem(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "visibilityCondition" in value;
    return isInstance;
}
function DisrupterItemFromJSON(json) {
    return DisrupterItemFromJSONTyped(json, false);
}
function DisrupterItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'headline': !(0, runtime_1.exists)(json, 'headline') ? undefined : json['headline'],
        'description': json['description'],
        'visibilityCondition': json['visibilityCondition'],
        'link': !(0, runtime_1.exists)(json, 'link') ? undefined : (0, DisrupterItemLink_1.DisrupterItemLinkFromJSON)(json['link']),
        'media': !(0, runtime_1.exists)(json, 'media') ? undefined : (0, DisrupterItemMedia_1.DisrupterItemMediaFromJSON)(json['media']),
        'mediaLink': !(0, runtime_1.exists)(json, 'mediaLink') ? undefined : (0, DisrupterItemLink_1.DisrupterItemLinkFromJSON)(json['mediaLink']),
    };
}
function DisrupterItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'headline': value.headline,
        'description': value.description,
        'visibilityCondition': value.visibilityCondition,
        'link': (0, DisrupterItemLink_1.DisrupterItemLinkToJSON)(value.link),
        'media': (0, DisrupterItemMedia_1.DisrupterItemMediaToJSON)(value.media),
        'mediaLink': (0, DisrupterItemLink_1.DisrupterItemLinkToJSON)(value.mediaLink),
    };
}
