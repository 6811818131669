"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Vault - Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceFormType = void 0;
exports.ServiceFormTypeFromJSON = ServiceFormTypeFromJSON;
exports.ServiceFormTypeFromJSONTyped = ServiceFormTypeFromJSONTyped;
exports.ServiceFormTypeToJSON = ServiceFormTypeToJSON;
/**
 *
 * @export
 */
exports.ServiceFormType = {
    G3: 'G3',
    QLine: 'QLine'
};
function ServiceFormTypeFromJSON(json) {
    return ServiceFormTypeFromJSONTyped(json, false);
}
function ServiceFormTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ServiceFormTypeToJSON(value) {
    return value;
}
