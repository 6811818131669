"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfProductExtensions = instanceOfProductExtensions;
exports.ProductExtensionsFromJSON = ProductExtensionsFromJSON;
exports.ProductExtensionsFromJSONTyped = ProductExtensionsFromJSONTyped;
exports.ProductExtensionsToJSON = ProductExtensionsToJSON;
const runtime_1 = require("../runtime");
const MassiveApplication_1 = require("./MassiveApplication");
const MassiveCategoryTree_1 = require("./MassiveCategoryTree");
const MassiveComputedDownload_1 = require("./MassiveComputedDownload");
const MassiveComputedProduct_1 = require("./MassiveComputedProduct");
const MassiveComputedSoftwareLicense_1 = require("./MassiveComputedSoftwareLicense");
const MassiveDeferPriceDisplay_1 = require("./MassiveDeferPriceDisplay");
const MassiveMaterial_1 = require("./MassiveMaterial");
const MassiveTool_1 = require("./MassiveTool");
const PropertyGroupOption_1 = require("./PropertyGroupOption");
const ZkpProductData_1 = require("./ZkpProductData");
/**
 * Check if a given object implements the ProductExtensions interface.
 */
function instanceOfProductExtensions(value) {
    let isInstance = true;
    return isInstance;
}
function ProductExtensionsFromJSON(json) {
    return ProductExtensionsFromJSONTyped(json, false);
}
function ProductExtensionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'massiveApplications': !(0, runtime_1.exists)(json, 'massiveApplications') ? undefined : (json['massiveApplications'] === null ? null : json['massiveApplications'].map(MassiveApplication_1.MassiveApplicationFromJSON)),
        'massiveMaterials': !(0, runtime_1.exists)(json, 'massiveMaterials') ? undefined : (json['massiveMaterials'] === null ? null : json['massiveMaterials'].map(MassiveMaterial_1.MassiveMaterialFromJSON)),
        'massiveTools': !(0, runtime_1.exists)(json, 'massiveTools') ? undefined : (json['massiveTools'] === null ? null : json['massiveTools'].map(MassiveTool_1.MassiveToolFromJSON)),
        'massiveProperties': !(0, runtime_1.exists)(json, 'massiveProperties') ? undefined : (json['massiveProperties'].map(PropertyGroupOption_1.PropertyGroupOptionFromJSON)),
        'massiveCategoryTree': !(0, runtime_1.exists)(json, 'massiveCategoryTree') ? undefined : (0, MassiveCategoryTree_1.MassiveCategoryTreeFromJSON)(json['massiveCategoryTree']),
        'massiveComputedSoftwareLicense': !(0, runtime_1.exists)(json, 'massiveComputedSoftwareLicense') ? undefined : (0, MassiveComputedSoftwareLicense_1.MassiveComputedSoftwareLicenseFromJSON)(json['massiveComputedSoftwareLicense']),
        'massiveComputedDownload': !(0, runtime_1.exists)(json, 'massiveComputedDownload') ? undefined : (0, MassiveComputedDownload_1.MassiveComputedDownloadFromJSON)(json['massiveComputedDownload']),
        'massiveComputedProduct': !(0, runtime_1.exists)(json, 'massiveComputedProduct') ? undefined : (0, MassiveComputedProduct_1.MassiveComputedProductFromJSON)(json['massiveComputedProduct']),
        'massiveDeferPriceDisplay': !(0, runtime_1.exists)(json, 'massiveDeferPriceDisplay') ? undefined : (0, MassiveDeferPriceDisplay_1.MassiveDeferPriceDisplayFromJSON)(json['massiveDeferPriceDisplay']),
        'zkpProductData': !(0, runtime_1.exists)(json, 'zkpProductData') ? undefined : (0, ZkpProductData_1.ZkpProductDataFromJSON)(json['zkpProductData']),
    };
}
function ProductExtensionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'massiveApplications': value.massiveApplications === undefined ? undefined : (value.massiveApplications === null ? null : value.massiveApplications.map(MassiveApplication_1.MassiveApplicationToJSON)),
        'massiveMaterials': value.massiveMaterials === undefined ? undefined : (value.massiveMaterials === null ? null : value.massiveMaterials.map(MassiveMaterial_1.MassiveMaterialToJSON)),
        'massiveTools': value.massiveTools === undefined ? undefined : (value.massiveTools === null ? null : value.massiveTools.map(MassiveTool_1.MassiveToolToJSON)),
        'massiveProperties': value.massiveProperties === undefined ? undefined : (value.massiveProperties.map(PropertyGroupOption_1.PropertyGroupOptionToJSON)),
        'massiveCategoryTree': (0, MassiveCategoryTree_1.MassiveCategoryTreeToJSON)(value.massiveCategoryTree),
        'massiveComputedSoftwareLicense': (0, MassiveComputedSoftwareLicense_1.MassiveComputedSoftwareLicenseToJSON)(value.massiveComputedSoftwareLicense),
        'massiveComputedDownload': (0, MassiveComputedDownload_1.MassiveComputedDownloadToJSON)(value.massiveComputedDownload),
        'massiveComputedProduct': (0, MassiveComputedProduct_1.MassiveComputedProductToJSON)(value.massiveComputedProduct),
        'massiveDeferPriceDisplay': (0, MassiveDeferPriceDisplay_1.MassiveDeferPriceDisplayToJSON)(value.massiveDeferPriceDisplay),
        'zkpProductData': (0, ZkpProductData_1.ZkpProductDataToJSON)(value.zkpProductData),
    };
}
