"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfZxxSalesChannelCountPerCountry = instanceOfZxxSalesChannelCountPerCountry;
exports.ZxxSalesChannelCountPerCountryFromJSON = ZxxSalesChannelCountPerCountryFromJSON;
exports.ZxxSalesChannelCountPerCountryFromJSONTyped = ZxxSalesChannelCountPerCountryFromJSONTyped;
exports.ZxxSalesChannelCountPerCountryToJSON = ZxxSalesChannelCountPerCountryToJSON;
const ZxxSalesChannelCountPerCountryBucket_1 = require("./ZxxSalesChannelCountPerCountryBucket");
/**
 * Check if a given object implements the ZxxSalesChannelCountPerCountry interface.
 */
function instanceOfZxxSalesChannelCountPerCountry(value) {
    let isInstance = true;
    isInstance = isInstance && "buckets" in value;
    return isInstance;
}
function ZxxSalesChannelCountPerCountryFromJSON(json) {
    return ZxxSalesChannelCountPerCountryFromJSONTyped(json, false);
}
function ZxxSalesChannelCountPerCountryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'buckets': (json['buckets'].map(ZxxSalesChannelCountPerCountryBucket_1.ZxxSalesChannelCountPerCountryBucketFromJSON)),
    };
}
function ZxxSalesChannelCountPerCountryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'buckets': (value.buckets.map(ZxxSalesChannelCountPerCountryBucket_1.ZxxSalesChannelCountPerCountryBucketToJSON)),
    };
}
