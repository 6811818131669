"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCountryStateTranslated = instanceOfCountryStateTranslated;
exports.CountryStateTranslatedFromJSON = CountryStateTranslatedFromJSON;
exports.CountryStateTranslatedFromJSONTyped = CountryStateTranslatedFromJSONTyped;
exports.CountryStateTranslatedToJSON = CountryStateTranslatedToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CountryStateTranslated interface.
 */
function instanceOfCountryStateTranslated(value) {
    let isInstance = true;
    return isInstance;
}
function CountryStateTranslatedFromJSON(json) {
    return CountryStateTranslatedFromJSONTyped(json, false);
}
function CountryStateTranslatedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
    };
}
function CountryStateTranslatedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
    };
}
