"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MassiveLicenseRuntimeUnitEnum = exports.MassiveLicenseTypeEnum = exports.MassiveLicenseModelEnum = exports.MassiveLicenseLockTypeEnum = void 0;
exports.instanceOfMassiveLicense = instanceOfMassiveLicense;
exports.MassiveLicenseFromJSON = MassiveLicenseFromJSON;
exports.MassiveLicenseFromJSONTyped = MassiveLicenseFromJSONTyped;
exports.MassiveLicenseToJSON = MassiveLicenseToJSON;
const runtime_1 = require("../runtime");
const MassiveLicenseMassiveMachine_1 = require("./MassiveLicenseMassiveMachine");
const Media_1 = require("./Media");
/**
 * @export
 */
exports.MassiveLicenseLockTypeEnum = {
    Hardlock: 'Hardlock',
    Softlock: 'Softlock',
    Cloud: 'Cloud'
};
/**
 * @export
 */
exports.MassiveLicenseModelEnum = {
    Perpetual: 'Perpetual',
    PerpetualWithMaintenance: 'Perpetual with maintenance',
    Subscription: 'Subscription',
    UsagePeriod: 'Usage period',
    TimeLimited: 'Time limited',
    Confirmed: 'confirmed',
    NA: 'n.a.'
};
/**
 * @export
 */
exports.MassiveLicenseTypeEnum = {
    Base: 'Base',
    Option: 'Option'
};
/**
 * @export
 */
exports.MassiveLicenseRuntimeUnitEnum = {
    D: 'd',
    M: 'm',
    Y: 'y',
    U: 'u'
};
/**
 * Check if a given object implements the MassiveLicense interface.
 */
function instanceOfMassiveLicense(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "massiveCompanyId" in value;
    isInstance = isInstance && "deletedContact" in value;
    isInstance = isInstance && "lockType" in value;
    isInstance = isInstance && "model" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "renewed" in value;
    isInstance = isInstance && "activated" in value;
    isInstance = isInstance && "runtime" in value;
    isInstance = isInstance && "externalId" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function MassiveLicenseFromJSON(json) {
    return MassiveLicenseFromJSONTyped(json, false);
}
function MassiveLicenseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'massiveCompanyId': json['massiveCompanyId'],
        'massiveMachineId': !(0, runtime_1.exists)(json, 'massiveMachineId') ? undefined : json['massiveMachineId'],
        'massiveMachine': !(0, runtime_1.exists)(json, 'massiveMachine') ? undefined : (0, MassiveLicenseMassiveMachine_1.MassiveLicenseMassiveMachineFromJSON)(json['massiveMachine']),
        'successorMassiveLicenseId': !(0, runtime_1.exists)(json, 'successorMassiveLicenseId') ? undefined : json['successorMassiveLicenseId'],
        'activationCode': !(0, runtime_1.exists)(json, 'activationCode') ? undefined : json['activationCode'],
        'activationUrl': !(0, runtime_1.exists)(json, 'activationUrl') ? undefined : json['activationUrl'],
        'clientName': !(0, runtime_1.exists)(json, 'clientName') ? undefined : json['clientName'],
        'containerNumber': !(0, runtime_1.exists)(json, 'containerNumber') ? undefined : json['containerNumber'],
        'deletedContact': json['deletedContact'],
        'fileName': !(0, runtime_1.exists)(json, 'fileName') ? undefined : json['fileName'],
        'lockType': json['lockType'],
        'model': json['model'],
        'name': json['name'],
        'productId': json['productId'],
        'productType': !(0, runtime_1.exists)(json, 'productType') ? undefined : json['productType'],
        'type': json['type'],
        'deleted': json['deleted'],
        'renewed': json['renewed'],
        'activationDate': !(0, runtime_1.exists)(json, 'activationDate') ? undefined : (new Date(json['activationDate'])),
        'endDate': !(0, runtime_1.exists)(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'activated': json['activated'],
        'runtime': json['runtime'],
        'externalId': json['externalId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'mediaId': !(0, runtime_1.exists)(json, 'mediaId') ? undefined : json['mediaId'],
        'media': !(0, runtime_1.exists)(json, 'media') ? undefined : (0, Media_1.MediaFromJSON)(json['media']),
        'consented': !(0, runtime_1.exists)(json, 'consented') ? undefined : json['consented'],
        'userCount': !(0, runtime_1.exists)(json, 'userCount') ? undefined : json['userCount'],
        'licenseNumber': !(0, runtime_1.exists)(json, 'licenseNumber') ? undefined : json['licenseNumber'],
        'runtimeUnit': !(0, runtime_1.exists)(json, 'runtimeUnit') ? undefined : json['runtimeUnit'],
        'terminated': !(0, runtime_1.exists)(json, 'terminated') ? undefined : json['terminated'],
        'licenseContactId': !(0, runtime_1.exists)(json, 'licenseContactId') ? undefined : json['licenseContactId'],
        'licenseContactName': !(0, runtime_1.exists)(json, 'licenseContactName') ? undefined : json['licenseContactName'],
        'usage': !(0, runtime_1.exists)(json, 'usage') ? undefined : json['usage'],
    };
}
function MassiveLicenseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'massiveCompanyId': value.massiveCompanyId,
        'massiveMachineId': value.massiveMachineId,
        'massiveMachine': (0, MassiveLicenseMassiveMachine_1.MassiveLicenseMassiveMachineToJSON)(value.massiveMachine),
        'successorMassiveLicenseId': value.successorMassiveLicenseId,
        'activationCode': value.activationCode,
        'activationUrl': value.activationUrl,
        'clientName': value.clientName,
        'containerNumber': value.containerNumber,
        'deletedContact': value.deletedContact,
        'fileName': value.fileName,
        'lockType': value.lockType,
        'model': value.model,
        'name': value.name,
        'productId': value.productId,
        'productType': value.productType,
        'type': value.type,
        'deleted': value.deleted,
        'renewed': value.renewed,
        'activated': value.activated,
        'runtime': value.runtime,
        'externalId': value.externalId,
        'mediaId': value.mediaId,
        'media': (0, Media_1.MediaToJSON)(value.media),
        'consented': value.consented,
        'userCount': value.userCount,
        'licenseNumber': value.licenseNumber,
        'runtimeUnit': value.runtimeUnit,
        'terminated': value.terminated,
        'licenseContactId': value.licenseContactId,
        'licenseContactName': value.licenseContactName,
        'usage': value.usage,
    };
}
