"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOrderTransaction = instanceOfOrderTransaction;
exports.OrderTransactionFromJSON = OrderTransactionFromJSON;
exports.OrderTransactionFromJSONTyped = OrderTransactionFromJSONTyped;
exports.OrderTransactionToJSON = OrderTransactionToJSON;
const runtime_1 = require("../runtime");
const Order_1 = require("./Order");
const OrderTransactionAmount_1 = require("./OrderTransactionAmount");
const PaymentMethod_1 = require("./PaymentMethod");
const StateMachineState_1 = require("./StateMachineState");
/**
 * Check if a given object implements the OrderTransaction interface.
 */
function instanceOfOrderTransaction(value) {
    let isInstance = true;
    isInstance = isInstance && "orderId" in value;
    isInstance = isInstance && "paymentMethodId" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "stateId" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function OrderTransactionFromJSON(json) {
    return OrderTransactionFromJSONTyped(json, false);
}
function OrderTransactionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'versionId': !(0, runtime_1.exists)(json, 'versionId') ? undefined : json['versionId'],
        'orderId': json['orderId'],
        'orderVersionId': !(0, runtime_1.exists)(json, 'orderVersionId') ? undefined : json['orderVersionId'],
        'paymentMethodId': json['paymentMethodId'],
        'amount': (0, OrderTransactionAmount_1.OrderTransactionAmountFromJSON)(json['amount']),
        'stateId': json['stateId'],
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : json['customFields'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'stateMachineState': !(0, runtime_1.exists)(json, 'stateMachineState') ? undefined : (0, StateMachineState_1.StateMachineStateFromJSON)(json['stateMachineState']),
        'order': !(0, runtime_1.exists)(json, 'order') ? undefined : (0, Order_1.OrderFromJSON)(json['order']),
        'paymentMethod': !(0, runtime_1.exists)(json, 'paymentMethod') ? undefined : (0, PaymentMethod_1.PaymentMethodFromJSON)(json['paymentMethod']),
    };
}
function OrderTransactionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'versionId': value.versionId,
        'orderId': value.orderId,
        'orderVersionId': value.orderVersionId,
        'paymentMethodId': value.paymentMethodId,
        'amount': (0, OrderTransactionAmount_1.OrderTransactionAmountToJSON)(value.amount),
        'customFields': value.customFields,
        'stateMachineState': (0, StateMachineState_1.StateMachineStateToJSON)(value.stateMachineState),
        'order': (0, Order_1.OrderToJSON)(value.order),
        'paymentMethod': (0, PaymentMethod_1.PaymentMethodToJSON)(value.paymentMethod),
    };
}
