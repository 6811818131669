"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfProductMedia = instanceOfProductMedia;
exports.ProductMediaFromJSON = ProductMediaFromJSON;
exports.ProductMediaFromJSONTyped = ProductMediaFromJSONTyped;
exports.ProductMediaToJSON = ProductMediaToJSON;
const runtime_1 = require("../runtime");
const Media_1 = require("./Media");
/**
 * Check if a given object implements the ProductMedia interface.
 */
function instanceOfProductMedia(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "versionId" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "productVersionId" in value;
    isInstance = isInstance && "mediaId" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function ProductMediaFromJSON(json) {
    return ProductMediaFromJSONTyped(json, false);
}
function ProductMediaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'versionId': json['versionId'],
        'productId': json['productId'],
        'productVersionId': json['productVersionId'],
        'mediaId': json['mediaId'],
        'position': !(0, runtime_1.exists)(json, 'position') ? undefined : json['position'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'media': !(0, runtime_1.exists)(json, 'media') ? undefined : (0, Media_1.MediaFromJSON)(json['media']),
    };
}
function ProductMediaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'versionId': value.versionId,
        'productId': value.productId,
        'productVersionId': value.productVersionId,
        'mediaId': value.mediaId,
        'position': value.position,
        'media': (0, Media_1.MediaToJSON)(value.media),
    };
}
