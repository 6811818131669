"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCmsSlot = instanceOfCmsSlot;
exports.CmsSlotFromJSON = CmsSlotFromJSON;
exports.CmsSlotFromJSONTyped = CmsSlotFromJSONTyped;
exports.CmsSlotToJSON = CmsSlotToJSON;
const runtime_1 = require("../runtime");
const CmsSlotTranslated_1 = require("./CmsSlotTranslated");
/**
 * Check if a given object implements the CmsSlot interface.
 */
function instanceOfCmsSlot(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "slot" in value;
    isInstance = isInstance && "config" in value;
    isInstance = isInstance && "blockId" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function CmsSlotFromJSON(json) {
    return CmsSlotFromJSONTyped(json, false);
}
function CmsSlotFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'versionId': !(0, runtime_1.exists)(json, 'versionId') ? undefined : json['versionId'],
        'type': json['type'],
        'slot': json['slot'],
        'locked': !(0, runtime_1.exists)(json, 'locked') ? undefined : json['locked'],
        'config': json['config'],
        'data': !(0, runtime_1.exists)(json, 'data') ? undefined : json['data'],
        'blockId': json['blockId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, CmsSlotTranslated_1.CmsSlotTranslatedFromJSON)(json['translated']),
    };
}
function CmsSlotToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'versionId': value.versionId,
        'type': value.type,
        'slot': value.slot,
        'locked': value.locked,
        'config': value.config,
        'blockId': value.blockId,
        'translated': (0, CmsSlotTranslated_1.CmsSlotTranslatedToJSON)(value.translated),
    };
}
