"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfProduct = instanceOfProduct;
exports.ProductFromJSON = ProductFromJSON;
exports.ProductFromJSONTyped = ProductFromJSONTyped;
exports.ProductToJSON = ProductToJSON;
const runtime_1 = require("../runtime");
const CalculatedPrice_1 = require("./CalculatedPrice");
const Category_1 = require("./Category");
const CrossSelling_1 = require("./CrossSelling");
const ProductCalculatedListingPrice_1 = require("./ProductCalculatedListingPrice");
const ProductExtensions_1 = require("./ProductExtensions");
const ProductMedia_1 = require("./ProductMedia");
const ProductTranslation_1 = require("./ProductTranslation");
const Unit_1 = require("./Unit");
/**
 * Check if a given object implements the Product interface.
 */
function instanceOfProduct(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "productNumber" in value;
    return isInstance;
}
function ProductFromJSON(json) {
    return ProductFromJSONTyped(json, false);
}
function ProductFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'calculatedListingPrice': !(0, runtime_1.exists)(json, 'calculatedListingPrice') ? undefined : (0, ProductCalculatedListingPrice_1.ProductCalculatedListingPriceFromJSON)(json['calculatedListingPrice']),
        'calculatedPrices': !(0, runtime_1.exists)(json, 'calculatedPrices') ? undefined : (json['calculatedPrices'] === null ? null : json['calculatedPrices'].map(CalculatedPrice_1.CalculatedPriceFromJSON)),
        'calculatedPrice': !(0, runtime_1.exists)(json, 'calculatedPrice') ? undefined : (0, CalculatedPrice_1.CalculatedPriceFromJSON)(json['calculatedPrice']),
        'sortedProperties': !(0, runtime_1.exists)(json, 'sortedProperties') ? undefined : json['sortedProperties'],
        'isNew': !(0, runtime_1.exists)(json, 'isNew') ? undefined : json['isNew'],
        'calculatedMaxPurchase': !(0, runtime_1.exists)(json, 'calculatedMaxPurchase') ? undefined : json['calculatedMaxPurchase'],
        'parentId': !(0, runtime_1.exists)(json, 'parentId') ? undefined : json['parentId'],
        'childCount': !(0, runtime_1.exists)(json, 'childCount') ? undefined : json['childCount'],
        'autoIncrement': !(0, runtime_1.exists)(json, 'autoIncrement') ? undefined : json['autoIncrement'],
        'taxId': !(0, runtime_1.exists)(json, 'taxId') ? undefined : json['taxId'],
        'manufacturerId': !(0, runtime_1.exists)(json, 'manufacturerId') ? undefined : json['manufacturerId'],
        'unitId': !(0, runtime_1.exists)(json, 'unitId') ? undefined : json['unitId'],
        'unit': !(0, runtime_1.exists)(json, 'unit') ? undefined : (0, Unit_1.UnitFromJSON)(json['unit']),
        'purchaseUnit': !(0, runtime_1.exists)(json, 'purchaseUnit') ? undefined : json['purchaseUnit'],
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'displayGroup': !(0, runtime_1.exists)(json, 'displayGroup') ? undefined : json['displayGroup'],
        'manufacturerNumber': !(0, runtime_1.exists)(json, 'manufacturerNumber') ? undefined : json['manufacturerNumber'],
        'productNumber': json['productNumber'],
        'stock': !(0, runtime_1.exists)(json, 'stock') ? undefined : json['stock'],
        'availableStock': !(0, runtime_1.exists)(json, 'availableStock') ? undefined : json['availableStock'],
        'available': !(0, runtime_1.exists)(json, 'available') ? undefined : json['available'],
        'categoryTree': !(0, runtime_1.exists)(json, 'categoryTree') ? undefined : json['categoryTree'],
        'propertyIds': !(0, runtime_1.exists)(json, 'propertyIds') ? undefined : json['propertyIds'],
        'isCloseout': !(0, runtime_1.exists)(json, 'isCloseout') ? undefined : json['isCloseout'],
        'purchaseSteps': !(0, runtime_1.exists)(json, 'purchaseSteps') ? undefined : json['purchaseSteps'],
        'maxPurchase': !(0, runtime_1.exists)(json, 'maxPurchase') ? undefined : json['maxPurchase'],
        'minPurchase': !(0, runtime_1.exists)(json, 'minPurchase') ? undefined : json['minPurchase'],
        'media': !(0, runtime_1.exists)(json, 'media') ? undefined : (json['media'] === null ? null : json['media'].map(ProductMedia_1.ProductMediaFromJSON)),
        'crossSellings': !(0, runtime_1.exists)(json, 'crossSellings') ? undefined : (json['crossSellings'] === null ? null : json['crossSellings'].map(CrossSelling_1.CrossSellingFromJSON)),
        'cover': !(0, runtime_1.exists)(json, 'cover') ? undefined : (0, ProductMedia_1.ProductMediaFromJSON)(json['cover']),
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, ProductTranslation_1.ProductTranslationFromJSON)(json['translated']),
        'extensions': !(0, runtime_1.exists)(json, 'extensions') ? undefined : (0, ProductExtensions_1.ProductExtensionsFromJSON)(json['extensions']),
        'categories': !(0, runtime_1.exists)(json, 'categories') ? undefined : (json['categories'].map(Category_1.CategoryFromJSON)),
        'optionIds': !(0, runtime_1.exists)(json, 'optionIds') ? undefined : json['optionIds'],
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
    };
}
function ProductToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'calculatedListingPrice': (0, ProductCalculatedListingPrice_1.ProductCalculatedListingPriceToJSON)(value.calculatedListingPrice),
        'calculatedPrices': value.calculatedPrices === undefined ? undefined : (value.calculatedPrices === null ? null : value.calculatedPrices.map(CalculatedPrice_1.CalculatedPriceToJSON)),
        'calculatedPrice': (0, CalculatedPrice_1.CalculatedPriceToJSON)(value.calculatedPrice),
        'sortedProperties': value.sortedProperties,
        'isNew': value.isNew,
        'calculatedMaxPurchase': value.calculatedMaxPurchase,
        'parentId': value.parentId,
        'childCount': value.childCount,
        'autoIncrement': value.autoIncrement,
        'taxId': value.taxId,
        'manufacturerId': value.manufacturerId,
        'unitId': value.unitId,
        'unit': (0, Unit_1.UnitToJSON)(value.unit),
        'purchaseUnit': value.purchaseUnit,
        'active': value.active,
        'displayGroup': value.displayGroup,
        'manufacturerNumber': value.manufacturerNumber,
        'productNumber': value.productNumber,
        'stock': value.stock,
        'availableStock': value.availableStock,
        'available': value.available,
        'categoryTree': value.categoryTree,
        'propertyIds': value.propertyIds,
        'isCloseout': value.isCloseout,
        'purchaseSteps': value.purchaseSteps,
        'maxPurchase': value.maxPurchase,
        'minPurchase': value.minPurchase,
        'media': value.media === undefined ? undefined : (value.media === null ? null : value.media.map(ProductMedia_1.ProductMediaToJSON)),
        'crossSellings': value.crossSellings === undefined ? undefined : (value.crossSellings === null ? null : value.crossSellings.map(CrossSelling_1.CrossSellingToJSON)),
        'cover': (0, ProductMedia_1.ProductMediaToJSON)(value.cover),
        'translated': (0, ProductTranslation_1.ProductTranslationToJSON)(value.translated),
        'extensions': (0, ProductExtensions_1.ProductExtensionsToJSON)(value.extensions),
        'categories': value.categories === undefined ? undefined : (value.categories.map(Category_1.CategoryToJSON)),
        'optionIds': value.optionIds,
    };
}
