"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CustomerType;
(function (CustomerType) {
    CustomerType["customer"] = "customer";
    CustomerType["customerDiscount"] = "customer_discount";
    CustomerType["demo"] = "demo";
    CustomerType["reseller"] = "reseller";
    CustomerType["zxx"] = "zxx";
})(CustomerType || (CustomerType = {}));
exports.default = CustomerType;
