"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MassiveLicenseApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class MassiveLicenseApi extends runtime.BaseAPI {
    /**
     * Creates or updates the consent given to the presented ToS.
     */
    async consentLicenseRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling consentLicense.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/store-api/massive/v1/_action/license/{id}/consent`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Creates or updates the consent given to the presented ToS.
     */
    async consentLicense(requestParameters, initOverrides) {
        await this.consentLicenseRaw(requestParameters, initOverrides);
    }
    /**
     * Download licenses as ZIP archive.
     */
    async downloadMassiveLicenseRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling downloadMassiveLicense.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/store-api/massive/v1/license/download/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.BlobApiResponse(response);
    }
    /**
     * Download licenses as ZIP archive.
     */
    async downloadMassiveLicense(requestParameters, initOverrides) {
        const response = await this.downloadMassiveLicenseRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Lists the licenses assigned to the current user.
     */
    async readMassiveLicenseListRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.associations !== undefined) {
            queryParameters['associations'] = requestParameters.associations;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/store-api/massive/v1/license`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.MassiveLicenseFromJSON));
    }
    /**
     * Lists the licenses assigned to the current user.
     */
    async readMassiveLicenseList(requestParameters = {}, initOverrides) {
        const response = await this.readMassiveLicenseListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Update license.
     */
    async updateLicenseRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateLicense.');
        }
        if (requestParameters.massiveLicensePatchRequestBody === null || requestParameters.massiveLicensePatchRequestBody === undefined) {
            throw new runtime.RequiredError('massiveLicensePatchRequestBody', 'Required parameter requestParameters.massiveLicensePatchRequestBody was null or undefined when calling updateLicense.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/store-api/massive/v1/license/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.MassiveLicensePatchRequestBodyToJSON)(requestParameters.massiveLicensePatchRequestBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Update license.
     */
    async updateLicense(requestParameters, initOverrides) {
        await this.updateLicenseRaw(requestParameters, initOverrides);
    }
}
exports.MassiveLicenseApi = MassiveLicenseApi;
