"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPaymentMethodExtensions = instanceOfPaymentMethodExtensions;
exports.PaymentMethodExtensionsFromJSON = PaymentMethodExtensionsFromJSON;
exports.PaymentMethodExtensionsFromJSONTyped = PaymentMethodExtensionsFromJSONTyped;
exports.PaymentMethodExtensionsToJSON = PaymentMethodExtensionsToJSON;
const runtime_1 = require("../runtime");
const PaymentMethodAdyenData_1 = require("./PaymentMethodAdyenData");
/**
 * Check if a given object implements the PaymentMethodExtensions interface.
 */
function instanceOfPaymentMethodExtensions(value) {
    let isInstance = true;
    return isInstance;
}
function PaymentMethodExtensionsFromJSON(json) {
    return PaymentMethodExtensionsFromJSONTyped(json, false);
}
function PaymentMethodExtensionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'adyenData': !(0, runtime_1.exists)(json, 'adyenData') ? undefined : (0, PaymentMethodAdyenData_1.PaymentMethodAdyenDataFromJSON)(json['adyenData']),
    };
}
function PaymentMethodExtensionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'adyenData': (0, PaymentMethodAdyenData_1.PaymentMethodAdyenDataToJSON)(value.adyenData),
    };
}
