"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Sulu - Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfNavigationItem = instanceOfNavigationItem;
exports.NavigationItemFromJSON = NavigationItemFromJSON;
exports.NavigationItemFromJSONTyped = NavigationItemFromJSONTyped;
exports.NavigationItemToJSON = NavigationItemToJSON;
/**
 * Check if a given object implements the NavigationItem interface.
 */
function instanceOfNavigationItem(value) {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "order" in value;
    return isInstance;
}
function NavigationItemFromJSON(json) {
    return NavigationItemFromJSONTyped(json, false);
}
function NavigationItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': json['title'],
        'url': json['url'],
        'order': json['order'],
    };
}
function NavigationItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'url': value.url,
        'order': value.order,
    };
}
