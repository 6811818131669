"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleAddToCartErrorResponse = void 0;
const react_toastify_1 = require("react-toastify");
const array_1 = require("../../common/js/helper/array");
const translation_1 = require("../../common/js/helper/translation");
const error_1 = require("./error");
const handleAddToCartErrorResponse = (response) => {
    return (0, error_1.handleErrorResponse)(response).then((errorsObject) => {
        const code = (0, array_1.first)(errorsObject?.errors ?? [])?.code;
        if (!code) {
            return Promise.reject();
        }
        const message = (0, translation_1.t)('add_to_cart_button.error', { code });
        if (!message) {
            return Promise.reject();
        }
        react_toastify_1.toast.error(message);
        return Promise.reject();
    });
};
exports.handleAddToCartErrorResponse = handleAddToCartErrorResponse;
