"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAccountRegister = instanceOfAccountRegister;
exports.AccountRegisterFromJSON = AccountRegisterFromJSON;
exports.AccountRegisterFromJSONTyped = AccountRegisterFromJSONTyped;
exports.AccountRegisterToJSON = AccountRegisterToJSON;
const runtime_1 = require("../runtime");
const AccountRegisterCompany_1 = require("./AccountRegisterCompany");
const AccountRegisterPersonal_1 = require("./AccountRegisterPersonal");
/**
 * Check if a given object implements the AccountRegister interface.
 */
function instanceOfAccountRegister(value) {
    let isInstance = true;
    isInstance = isInstance && "personal" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "newsletter" in value;
    isInstance = isInstance && "termsAndConditions" in value;
    return isInstance;
}
function AccountRegisterFromJSON(json) {
    return AccountRegisterFromJSONTyped(json, false);
}
function AccountRegisterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'personal': (0, AccountRegisterPersonal_1.AccountRegisterPersonalFromJSON)(json['personal']),
        'company': !(0, runtime_1.exists)(json, 'company') ? undefined : (0, AccountRegisterCompany_1.AccountRegisterCompanyFromJSON)(json['company']),
        'companyExternalId': !(0, runtime_1.exists)(json, 'companyExternalId') ? undefined : json['companyExternalId'],
        'countryCode': json['countryCode'],
        'countryStateCode': !(0, runtime_1.exists)(json, 'countryStateCode') ? undefined : json['countryStateCode'],
        'customerId': !(0, runtime_1.exists)(json, 'customerId') ? undefined : json['customerId'],
        'newsletter': json['newsletter'],
        'reseller': !(0, runtime_1.exists)(json, 'reseller') ? undefined : json['reseller'],
        'serialNumber': !(0, runtime_1.exists)(json, 'serialNumber') ? undefined : json['serialNumber'],
        'termsAndConditions': json['termsAndConditions'],
        'verificationCode': !(0, runtime_1.exists)(json, 'verificationCode') ? undefined : json['verificationCode'],
        'zipCode': !(0, runtime_1.exists)(json, 'zipCode') ? undefined : json['zipCode'],
    };
}
function AccountRegisterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'personal': (0, AccountRegisterPersonal_1.AccountRegisterPersonalToJSON)(value.personal),
        'company': (0, AccountRegisterCompany_1.AccountRegisterCompanyToJSON)(value.company),
        'companyExternalId': value.companyExternalId,
        'countryCode': value.countryCode,
        'countryStateCode': value.countryStateCode,
        'customerId': value.customerId,
        'newsletter': value.newsletter,
        'reseller': value.reseller,
        'serialNumber': value.serialNumber,
        'termsAndConditions': value.termsAndConditions,
        'verificationCode': value.verificationCode,
        'zipCode': value.zipCode,
    };
}
