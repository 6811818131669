"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.matchesPropertyWithRangeSelection = exports.matchesPropertyWithMultiSelection = exports.matchesPropertyWithSingleSelection = exports.matchesTool = exports.matchesMaterial = exports.matchesMaterialGroup = exports.matchesPriceTo = exports.matchesPriceFrom = void 0;
const money_1 = require("./money");
const matchesPriceFrom = (product, priceFrom) => {
    if (isNaN(priceFrom)) {
        return false;
    }
    return (0, money_1.lowestPriceFromProduct)(product) >= priceFrom;
};
exports.matchesPriceFrom = matchesPriceFrom;
const matchesPriceTo = (product, priceFrom) => {
    if (isNaN(priceFrom)) {
        return false;
    }
    return (0, money_1.lowestPriceFromProduct)(product) <= priceFrom;
};
exports.matchesPriceTo = matchesPriceTo;
const matchesMaterialGroup = (product, materialGroupIds) => {
    return (product.extensions?.massiveMaterials?.some((material) => {
        return materialGroupIds.indexOf(material?.massiveMaterialGroupId ?? '') !== -1;
    }) ?? false);
};
exports.matchesMaterialGroup = matchesMaterialGroup;
const matchesMaterial = (product, materialIds) => {
    return (product.extensions?.massiveMaterials?.some((material) => {
        return materialIds.indexOf(material?.id ?? '') !== -1;
    }) ?? false);
};
exports.matchesMaterial = matchesMaterial;
const matchesTool = (product, toolIds) => {
    return (product.extensions?.massiveTools?.some((massiveTool) => {
        return toolIds.indexOf(massiveTool.id ?? '') !== -1;
    }) ?? false);
};
exports.matchesTool = matchesTool;
const matchesPropertyWithSingleSelection = (product, valueId) => {
    return product.extensions?.massiveProperties?.some((property) => property.id === valueId) ?? false;
};
exports.matchesPropertyWithSingleSelection = matchesPropertyWithSingleSelection;
const matchesPropertyWithMultiSelection = (product, valueIds) => {
    return (product.extensions?.massiveProperties?.some((property) => valueIds.indexOf(property.id ?? '') !== -1) ?? false);
};
exports.matchesPropertyWithMultiSelection = matchesPropertyWithMultiSelection;
const matchesPropertyWithRangeSelection = (product, products, propertyGroupId, value) => {
    const productProperties = product.extensions?.massiveProperties?.filter((property) => property.groupId === propertyGroupId);
    return productProperties?.some((property) => parseInt(property.translated?.name ?? '0') >= value) ?? false;
};
exports.matchesPropertyWithRangeSelection = matchesPropertyWithRangeSelection;
