"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOrderTransactionAmount = instanceOfOrderTransactionAmount;
exports.OrderTransactionAmountFromJSON = OrderTransactionAmountFromJSON;
exports.OrderTransactionAmountFromJSONTyped = OrderTransactionAmountFromJSONTyped;
exports.OrderTransactionAmountToJSON = OrderTransactionAmountToJSON;
const runtime_1 = require("../runtime");
const CalculatedTax_1 = require("./CalculatedTax");
const CartTaxRule_1 = require("./CartTaxRule");
const OrderTransactionAmountListPrice_1 = require("./OrderTransactionAmountListPrice");
/**
 * Check if a given object implements the OrderTransactionAmount interface.
 */
function instanceOfOrderTransactionAmount(value) {
    let isInstance = true;
    isInstance = isInstance && "unitPrice" in value;
    isInstance = isInstance && "totalPrice" in value;
    isInstance = isInstance && "quantity" in value;
    return isInstance;
}
function OrderTransactionAmountFromJSON(json) {
    return OrderTransactionAmountFromJSONTyped(json, false);
}
function OrderTransactionAmountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'unitPrice': json['unitPrice'],
        'totalPrice': json['totalPrice'],
        'quantity': json['quantity'],
        'calculatedTaxes': !(0, runtime_1.exists)(json, 'calculatedTaxes') ? undefined : (json['calculatedTaxes'].map(CalculatedTax_1.CalculatedTaxFromJSON)),
        'taxRules': !(0, runtime_1.exists)(json, 'taxRules') ? undefined : (json['taxRules'].map(CartTaxRule_1.CartTaxRuleFromJSON)),
        'referencePrice': !(0, runtime_1.exists)(json, 'referencePrice') ? undefined : json['referencePrice'],
        'listPrice': !(0, runtime_1.exists)(json, 'listPrice') ? undefined : (0, OrderTransactionAmountListPrice_1.OrderTransactionAmountListPriceFromJSON)(json['listPrice']),
    };
}
function OrderTransactionAmountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'unitPrice': value.unitPrice,
        'totalPrice': value.totalPrice,
        'quantity': value.quantity,
        'calculatedTaxes': value.calculatedTaxes === undefined ? undefined : (value.calculatedTaxes.map(CalculatedTax_1.CalculatedTaxToJSON)),
        'taxRules': value.taxRules === undefined ? undefined : (value.taxRules.map(CartTaxRule_1.CartTaxRuleToJSON)),
        'referencePrice': value.referencePrice,
        'listPrice': (0, OrderTransactionAmountListPrice_1.OrderTransactionAmountListPriceToJSON)(value.listPrice),
    };
}
