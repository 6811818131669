"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOrderTransactionAmountListPrice = instanceOfOrderTransactionAmountListPrice;
exports.OrderTransactionAmountListPriceFromJSON = OrderTransactionAmountListPriceFromJSON;
exports.OrderTransactionAmountListPriceFromJSONTyped = OrderTransactionAmountListPriceFromJSONTyped;
exports.OrderTransactionAmountListPriceToJSON = OrderTransactionAmountListPriceToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the OrderTransactionAmountListPrice interface.
 */
function instanceOfOrderTransactionAmountListPrice(value) {
    let isInstance = true;
    return isInstance;
}
function OrderTransactionAmountListPriceFromJSON(json) {
    return OrderTransactionAmountListPriceFromJSONTyped(json, false);
}
function OrderTransactionAmountListPriceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'price': !(0, runtime_1.exists)(json, 'price') ? undefined : json['price'],
        'discount': !(0, runtime_1.exists)(json, 'discount') ? undefined : json['discount'],
        'percentage': !(0, runtime_1.exists)(json, 'percentage') ? undefined : json['percentage'],
    };
}
function OrderTransactionAmountListPriceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'price': value.price,
        'discount': value.discount,
        'percentage': value.percentage,
    };
}
