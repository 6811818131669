"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassivePropertyGroupTranslated = instanceOfMassivePropertyGroupTranslated;
exports.MassivePropertyGroupTranslatedFromJSON = MassivePropertyGroupTranslatedFromJSON;
exports.MassivePropertyGroupTranslatedFromJSONTyped = MassivePropertyGroupTranslatedFromJSONTyped;
exports.MassivePropertyGroupTranslatedToJSON = MassivePropertyGroupTranslatedToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the MassivePropertyGroupTranslated interface.
 */
function instanceOfMassivePropertyGroupTranslated(value) {
    let isInstance = true;
    return isInstance;
}
function MassivePropertyGroupTranslatedFromJSON(json) {
    return MassivePropertyGroupTranslatedFromJSONTyped(json, false);
}
function MassivePropertyGroupTranslatedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'filterName': !(0, runtime_1.exists)(json, 'filterName') ? undefined : json['filterName'],
    };
}
function MassivePropertyGroupTranslatedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'filterName': value.filterName,
    };
}
