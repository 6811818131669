"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOrderAddress = instanceOfOrderAddress;
exports.OrderAddressFromJSON = OrderAddressFromJSON;
exports.OrderAddressFromJSONTyped = OrderAddressFromJSONTyped;
exports.OrderAddressToJSON = OrderAddressToJSON;
const runtime_1 = require("../runtime");
const Country_1 = require("./Country");
const CountryState_1 = require("./CountryState");
/**
 * Check if a given object implements the OrderAddress interface.
 */
function instanceOfOrderAddress(value) {
    let isInstance = true;
    return isInstance;
}
function OrderAddressFromJSON(json) {
    return OrderAddressFromJSONTyped(json, false);
}
function OrderAddressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'city': !(0, runtime_1.exists)(json, 'city') ? undefined : json['city'],
        'company': !(0, runtime_1.exists)(json, 'company') ? undefined : json['company'],
        'country': !(0, runtime_1.exists)(json, 'country') ? undefined : (0, Country_1.CountryFromJSON)(json['country']),
        'countryState': !(0, runtime_1.exists)(json, 'countryState') ? undefined : (0, CountryState_1.CountryStateFromJSON)(json['countryState']),
        'street': !(0, runtime_1.exists)(json, 'street') ? undefined : json['street'],
        'zipcode': !(0, runtime_1.exists)(json, 'zipcode') ? undefined : json['zipcode'],
    };
}
function OrderAddressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'city': value.city,
        'company': value.company,
        'country': (0, Country_1.CountryToJSON)(value.country),
        'countryState': (0, CountryState_1.CountryStateToJSON)(value.countryState),
        'street': value.street,
        'zipcode': value.zipcode,
    };
}
