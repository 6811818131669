"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const SocialMediaLinks = [
    {
        title: 'LinkedIn',
        url: 'https://www.linkedin.com/company/zund-systemtechnik-ag',
        icon: 'icon-linkedin',
    },
    {
        title: 'Instagram',
        url: 'https://www.instagram.com/zundcutter_ch',
        icon: 'icon-instagram',
    },
    {
        title: 'Twitter',
        url: 'https://twitter.com/zundcutter',
        icon: 'icon-twitter',
    },
    {
        title: 'Facebook',
        url: 'https://www.facebook.com/ZundCutter',
        icon: 'icon-facebook',
    },
    {
        title: 'YouTube',
        url: 'https://www.youtube.com/zundtube',
        icon: 'icon-youtube',
    },
    {
        title: 'Flickr',
        url: 'https://www.flickr.com/photos/zundcutter/sets',
        icon: 'icon-flickr',
    },
    {
        title: 'Pinterest',
        url: 'https://www.pinterest.com/zundcutter/',
        icon: 'icon-pinterest',
    },
];
exports.default = SocialMediaLinks;
