"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfZxxSalesChannelCount = instanceOfZxxSalesChannelCount;
exports.ZxxSalesChannelCountFromJSON = ZxxSalesChannelCountFromJSON;
exports.ZxxSalesChannelCountFromJSONTyped = ZxxSalesChannelCountFromJSONTyped;
exports.ZxxSalesChannelCountToJSON = ZxxSalesChannelCountToJSON;
/**
 * Check if a given object implements the ZxxSalesChannelCount interface.
 */
function instanceOfZxxSalesChannelCount(value) {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    return isInstance;
}
function ZxxSalesChannelCountFromJSON(json) {
    return ZxxSalesChannelCountFromJSONTyped(json, false);
}
function ZxxSalesChannelCountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'count': json['count'],
    };
}
function ZxxSalesChannelCountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'count': value.count,
    };
}
