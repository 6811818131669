"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CartErrors;
(function (CartErrors) {
    CartErrors["avataxTaxCalculationFailed"] = "massive-cart-error-avatax-tax-calculation-failed";
    CartErrors["consumablesCheckoutDeactivated"] = "massive-cart-error-consumables-checkout-deactivated";
    CartErrors["containsConsumableProduct"] = "massive-cart-error-contains-consumable-product";
    CartErrors["containsSoftwareProduct"] = "massive-cart-error-contains-software-product";
    CartErrors["deferredProductPrice"] = "massive-cart-error-deferred-product-price";
    CartErrors["orderOnAccount"] = "massive-cart-error-order-on-account";
    CartErrors["productQuantityChanged"] = "massive-cart-error-product-quantity-changed";
    CartErrors["salesChannelDisabled"] = "massive-cart-error-sales-channel-disabled";
})(CartErrors || (CartErrors = {}));
exports.default = CartErrors;
