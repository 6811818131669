"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassiveSalutation = instanceOfMassiveSalutation;
exports.MassiveSalutationFromJSON = MassiveSalutationFromJSON;
exports.MassiveSalutationFromJSONTyped = MassiveSalutationFromJSONTyped;
exports.MassiveSalutationToJSON = MassiveSalutationToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the MassiveSalutation interface.
 */
function instanceOfMassiveSalutation(value) {
    let isInstance = true;
    isInstance = isInstance && "salutationId" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function MassiveSalutationFromJSON(json) {
    return MassiveSalutationFromJSONTyped(json, false);
}
function MassiveSalutationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'salutationId': json['salutationId'],
        'externalId': !(0, runtime_1.exists)(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}
function MassiveSalutationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'salutationId': value.salutationId,
        'externalId': value.externalId,
    };
}
