"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPaymentMethodsResponse = instanceOfPaymentMethodsResponse;
exports.PaymentMethodsResponseFromJSON = PaymentMethodsResponseFromJSON;
exports.PaymentMethodsResponseFromJSONTyped = PaymentMethodsResponseFromJSONTyped;
exports.PaymentMethodsResponseToJSON = PaymentMethodsResponseToJSON;
const PaymentMethod_1 = require("./PaymentMethod");
/**
 * Check if a given object implements the PaymentMethodsResponse interface.
 */
function instanceOfPaymentMethodsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "elements" in value;
    return isInstance;
}
function PaymentMethodsResponseFromJSON(json) {
    return PaymentMethodsResponseFromJSONTyped(json, false);
}
function PaymentMethodsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'elements': (json['elements'].map(PaymentMethod_1.PaymentMethodFromJSON)),
    };
}
function PaymentMethodsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'elements': (value.elements.map(PaymentMethod_1.PaymentMethodToJSON)),
    };
}
