"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Sulu - Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfResellerPageContent = instanceOfResellerPageContent;
exports.ResellerPageContentFromJSON = ResellerPageContentFromJSON;
exports.ResellerPageContentFromJSONTyped = ResellerPageContentFromJSONTyped;
exports.ResellerPageContentToJSON = ResellerPageContentToJSON;
/**
 * Check if a given object implements the ResellerPageContent interface.
 */
function instanceOfResellerPageContent(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "content" in value;
    return isInstance;
}
function ResellerPageContentFromJSON(json) {
    return ResellerPageContentFromJSONTyped(json, false);
}
function ResellerPageContentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': json['type'],
        'content': json['content'],
    };
}
function ResellerPageContentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'content': value.content,
    };
}
