"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOrdersResponseOrders = instanceOfOrdersResponseOrders;
exports.OrdersResponseOrdersFromJSON = OrdersResponseOrdersFromJSON;
exports.OrdersResponseOrdersFromJSONTyped = OrdersResponseOrdersFromJSONTyped;
exports.OrdersResponseOrdersToJSON = OrdersResponseOrdersToJSON;
const runtime_1 = require("../runtime");
const Order_1 = require("./Order");
/**
 * Check if a given object implements the OrdersResponseOrders interface.
 */
function instanceOfOrdersResponseOrders(value) {
    let isInstance = true;
    return isInstance;
}
function OrdersResponseOrdersFromJSON(json) {
    return OrdersResponseOrdersFromJSONTyped(json, false);
}
function OrdersResponseOrdersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'elements': !(0, runtime_1.exists)(json, 'elements') ? undefined : (json['elements'] === null ? null : json['elements'].map(Order_1.OrderFromJSON)),
    };
}
function OrdersResponseOrdersToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'elements': value.elements === undefined ? undefined : (value.elements === null ? null : value.elements.map(Order_1.OrderToJSON)),
    };
}
