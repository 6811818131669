"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CustomerRoles;
(function (CustomerRoles) {
    CustomerRoles["ACCOUNTING"] = "Accounting";
    CustomerRoles["CEO"] = "CEO";
    CustomerRoles["CFO"] = "CFO";
    CustomerRoles["COO"] = "COO";
    CustomerRoles["CTO"] = "CTO";
    CustomerRoles["EMPLOYEE"] = "Employee";
    CustomerRoles["MANAGER"] = "Manager";
    CustomerRoles["OPERATOR"] = "Operator";
    CustomerRoles["OTHER"] = "Other";
    CustomerRoles["TECHNICIAN"] = "Technician";
})(CustomerRoles || (CustomerRoles = {}));
exports.default = CustomerRoles;
