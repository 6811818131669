"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class ProductApi extends runtime.BaseAPI {
    /**
     * Loads all cross sellings for a given product.
     */
    async readCrossSellingProductsMappingsRaw(requestParameters, initOverrides) {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId', 'Required parameter requestParameters.productId was null or undefined when calling readCrossSellingProductsMappings.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/store-api/product/{productId}/cross-selling`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.CrossSellingProductsMappingFromJSON));
    }
    /**
     * Loads all cross sellings for a given product.
     */
    async readCrossSellingProductsMappings(requestParameters, initOverrides) {
        const response = await this.readCrossSellingProductsMappingsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async readProductRaw(requestParameters, initOverrides) {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId', 'Required parameter requestParameters.productId was null or undefined when calling readProduct.');
        }
        const queryParameters = {};
        if (requestParameters.includeNotPurchasable !== undefined) {
            queryParameters['includeNotPurchasable'] = requestParameters.includeNotPurchasable;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/store-api/product/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.RequestBodyToJSON)(requestParameters.requestBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ProductResponseFromJSON)(jsonValue));
    }
    /**
     */
    async readProduct(requestParameters, initOverrides) {
        const response = await this.readProductRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async readProductListRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/store-api/product`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.RequestBodyToJSON)(requestParameters.requestBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ProductListFromJSON)(jsonValue));
    }
    /**
     */
    async readProductList(requestParameters = {}, initOverrides) {
        const response = await this.readProductListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Loads all available products for a given category
     */
    async readProductsInCategoryRaw(requestParameters, initOverrides) {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId', 'Required parameter requestParameters.categoryId was null or undefined when calling readProductsInCategory.');
        }
        const queryParameters = {};
        if (requestParameters.includeNotPurchasable !== undefined) {
            queryParameters['includeNotPurchasable'] = requestParameters.includeNotPurchasable;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/store-api/product-listing/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.RequestBodyToJSON)(requestParameters.requestBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ProductListFromJSON)(jsonValue));
    }
    /**
     * Loads all available products for a given category
     */
    async readProductsInCategory(requestParameters, initOverrides) {
        const response = await this.readProductsInCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.ProductApi = ProductApi;
