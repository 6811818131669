"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numberFormat = void 0;
/**
 * Formats a number according to the official language from browser.
 *
 * @param {number} amount
 * @return {string}
 */
const numberFormat = (amount) => {
    if (!amount) {
        return '';
    }
    const formatter = new Intl.NumberFormat(navigator.language);
    return formatter.format(amount);
};
exports.numberFormat = numberFormat;
