"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Downloads - Software API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSoftwareMediaCategory = instanceOfSoftwareMediaCategory;
exports.SoftwareMediaCategoryFromJSON = SoftwareMediaCategoryFromJSON;
exports.SoftwareMediaCategoryFromJSONTyped = SoftwareMediaCategoryFromJSONTyped;
exports.SoftwareMediaCategoryToJSON = SoftwareMediaCategoryToJSON;
const runtime_1 = require("../runtime");
const SoftwareMediaFile_1 = require("./SoftwareMediaFile");
const SoftwareMediaUrl_1 = require("./SoftwareMediaUrl");
/**
 * Check if a given object implements the SoftwareMediaCategory interface.
 */
function instanceOfSoftwareMediaCategory(value) {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
function SoftwareMediaCategoryFromJSON(json) {
    return SoftwareMediaCategoryFromJSONTyped(json, false);
}
function SoftwareMediaCategoryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': json['key'],
        'name': json['name'],
        'files': !(0, runtime_1.exists)(json, 'files') ? undefined : (json['files'].map(SoftwareMediaFile_1.SoftwareMediaFileFromJSON)),
        'urls': !(0, runtime_1.exists)(json, 'urls') ? undefined : (json['urls'].map(SoftwareMediaUrl_1.SoftwareMediaUrlFromJSON)),
    };
}
function SoftwareMediaCategoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
        'name': value.name,
        'files': value.files === undefined ? undefined : (value.files.map(SoftwareMediaFile_1.SoftwareMediaFileToJSON)),
        'urls': value.urls === undefined ? undefined : (value.urls.map(SoftwareMediaUrl_1.SoftwareMediaUrlToJSON)),
    };
}
