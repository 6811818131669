"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Assets - Assets API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSoftwareOffer = instanceOfSoftwareOffer;
exports.SoftwareOfferFromJSON = SoftwareOfferFromJSON;
exports.SoftwareOfferFromJSONTyped = SoftwareOfferFromJSONTyped;
exports.SoftwareOfferToJSON = SoftwareOfferToJSON;
const runtime_1 = require("../runtime");
const SoftwareOfferItem_1 = require("./SoftwareOfferItem");
/**
 * Check if a given object implements the SoftwareOffer interface.
 */
function instanceOfSoftwareOffer(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "offerId" in value;
    isInstance = isInstance && "offerDate" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "isAdvancedPayment" in value;
    isInstance = isInstance && "currencyCode" in value;
    isInstance = isInstance && "items" in value;
    return isInstance;
}
function SoftwareOfferFromJSON(json) {
    return SoftwareOfferFromJSONTyped(json, false);
}
function SoftwareOfferFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'offerId': json['offerId'],
        'offerDate': (new Date(json['offerDate'])),
        'price': json['price'],
        'licenseCount': !(0, runtime_1.exists)(json, 'licenseCount') ? undefined : json['licenseCount'],
        'orderedAt': !(0, runtime_1.exists)(json, 'orderedAt') ? undefined : (json['orderedAt'] === null ? null : new Date(json['orderedAt'])),
        'isAdvancedPayment': json['isAdvancedPayment'],
        'currencyCode': json['currencyCode'],
        'offerEndDate': !(0, runtime_1.exists)(json, 'offerEndDate') ? undefined : (json['offerEndDate'] === null ? null : new Date(json['offerEndDate'])),
        'clientId': !(0, runtime_1.exists)(json, 'clientId') ? undefined : json['clientId'],
        'items': (json['items'].map(SoftwareOfferItem_1.SoftwareOfferItemFromJSON)),
        'shopOrderId': !(0, runtime_1.exists)(json, 'shopOrderId') ? undefined : json['shopOrderId'],
        'shopOrderNumber': !(0, runtime_1.exists)(json, 'shopOrderNumber') ? undefined : json['shopOrderNumber'],
    };
}
function SoftwareOfferToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'offerId': value.offerId,
        'offerDate': (value.offerDate.toISOString()),
        'price': value.price,
        'licenseCount': value.licenseCount,
        'orderedAt': value.orderedAt === undefined ? undefined : (value.orderedAt === null ? null : value.orderedAt.toISOString()),
        'isAdvancedPayment': value.isAdvancedPayment,
        'currencyCode': value.currencyCode,
        'offerEndDate': value.offerEndDate === undefined ? undefined : (value.offerEndDate === null ? null : value.offerEndDate.toISOString()),
        'clientId': value.clientId,
        'items': (value.items.map(SoftwareOfferItem_1.SoftwareOfferItemToJSON)),
        'shopOrderId': value.shopOrderId,
        'shopOrderNumber': value.shopOrderNumber,
    };
}
