"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfShippingMethodsResponse = instanceOfShippingMethodsResponse;
exports.ShippingMethodsResponseFromJSON = ShippingMethodsResponseFromJSON;
exports.ShippingMethodsResponseFromJSONTyped = ShippingMethodsResponseFromJSONTyped;
exports.ShippingMethodsResponseToJSON = ShippingMethodsResponseToJSON;
const ShippingMethod_1 = require("./ShippingMethod");
/**
 * Check if a given object implements the ShippingMethodsResponse interface.
 */
function instanceOfShippingMethodsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "elements" in value;
    return isInstance;
}
function ShippingMethodsResponseFromJSON(json) {
    return ShippingMethodsResponseFromJSONTyped(json, false);
}
function ShippingMethodsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'elements': (json['elements'].map(ShippingMethod_1.ShippingMethodFromJSON)),
    };
}
function ShippingMethodsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'elements': (value.elements.map(ShippingMethod_1.ShippingMethodToJSON)),
    };
}
