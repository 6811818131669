"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCmsSection = instanceOfCmsSection;
exports.CmsSectionFromJSON = CmsSectionFromJSON;
exports.CmsSectionFromJSONTyped = CmsSectionFromJSONTyped;
exports.CmsSectionToJSON = CmsSectionToJSON;
const runtime_1 = require("../runtime");
const CmsBlock_1 = require("./CmsBlock");
const CmsPage_1 = require("./CmsPage");
const Media_1 = require("./Media");
/**
 * Check if a given object implements the CmsSection interface.
 */
function instanceOfCmsSection(value) {
    let isInstance = true;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "pageId" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function CmsSectionFromJSON(json) {
    return CmsSectionFromJSONTyped(json, false);
}
function CmsSectionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'position': json['position'],
        'type': json['type'],
        'locked': !(0, runtime_1.exists)(json, 'locked') ? undefined : json['locked'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'sizingMode': !(0, runtime_1.exists)(json, 'sizingMode') ? undefined : json['sizingMode'],
        'mobileBehavior': !(0, runtime_1.exists)(json, 'mobileBehavior') ? undefined : json['mobileBehavior'],
        'backgroundColor': !(0, runtime_1.exists)(json, 'backgroundColor') ? undefined : json['backgroundColor'],
        'backgroundMediaId': !(0, runtime_1.exists)(json, 'backgroundMediaId') ? undefined : json['backgroundMediaId'],
        'backgroundMediaMode': !(0, runtime_1.exists)(json, 'backgroundMediaMode') ? undefined : json['backgroundMediaMode'],
        'cssClass': !(0, runtime_1.exists)(json, 'cssClass') ? undefined : json['cssClass'],
        'pageId': json['pageId'],
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : json['customFields'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'page': !(0, runtime_1.exists)(json, 'page') ? undefined : (0, CmsPage_1.CmsPageFromJSON)(json['page']),
        'backgroundMedia': !(0, runtime_1.exists)(json, 'backgroundMedia') ? undefined : (0, Media_1.MediaFromJSON)(json['backgroundMedia']),
        'blocks': !(0, runtime_1.exists)(json, 'blocks') ? undefined : (json['blocks'].map(CmsBlock_1.CmsBlockFromJSON)),
    };
}
function CmsSectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'position': value.position,
        'type': value.type,
        'locked': value.locked,
        'name': value.name,
        'sizingMode': value.sizingMode,
        'mobileBehavior': value.mobileBehavior,
        'backgroundColor': value.backgroundColor,
        'backgroundMediaId': value.backgroundMediaId,
        'backgroundMediaMode': value.backgroundMediaMode,
        'cssClass': value.cssClass,
        'pageId': value.pageId,
        'customFields': value.customFields,
        'page': (0, CmsPage_1.CmsPageToJSON)(value.page),
        'backgroundMedia': (0, Media_1.MediaToJSON)(value.backgroundMedia),
        'blocks': value.blocks === undefined ? undefined : (value.blocks.map(CmsBlock_1.CmsBlockToJSON)),
    };
}
