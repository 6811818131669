"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SessionStorageIds;
(function (SessionStorageIds) {
    SessionStorageIds["personalData"] = "9268f747-c868-4df3-9dd1-9032a4f200d7";
    SessionStorageIds["companyData"] = "83b0cb99-0594-4184-b43e-2df1817497c9";
    SessionStorageIds["generalData"] = "50f770f4-7269-41f1-b767-c7f2d865bdc1";
    // Deprecated IDs
    SessionStorageIds["oldPersonalInformation"] = "personalInformation";
    SessionStorageIds["oldCompanyInformation"] = "companyInformation";
    SessionStorageIds["oldNecessaryInformation"] = "necessaryInformation";
})(SessionStorageIds || (SessionStorageIds = {}));
exports.default = SessionStorageIds;
