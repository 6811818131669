"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestBodyTotalCountModeEnum = void 0;
exports.instanceOfRequestBody = instanceOfRequestBody;
exports.RequestBodyFromJSON = RequestBodyFromJSON;
exports.RequestBodyFromJSONTyped = RequestBodyFromJSONTyped;
exports.RequestBodyToJSON = RequestBodyToJSON;
const runtime_1 = require("../runtime");
const RequestBodyFilterInner_1 = require("./RequestBodyFilterInner");
const RequestBodySortInner_1 = require("./RequestBodySortInner");
/**
 * @export
 */
exports.RequestBodyTotalCountModeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 * Check if a given object implements the RequestBody interface.
 */
function instanceOfRequestBody(value) {
    let isInstance = true;
    return isInstance;
}
function RequestBodyFromJSON(json) {
    return RequestBodyFromJSONTyped(json, false);
}
function RequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'includes': !(0, runtime_1.exists)(json, 'includes') ? undefined : json['includes'],
        'ids': !(0, runtime_1.exists)(json, 'ids') ? undefined : json['ids'],
        'totalCountMode': !(0, runtime_1.exists)(json, 'total-count-mode') ? undefined : json['total-count-mode'],
        'filter': !(0, runtime_1.exists)(json, 'filter') ? undefined : (json['filter'].map(RequestBodyFilterInner_1.RequestBodyFilterInnerFromJSON)),
        'aggregations': !(0, runtime_1.exists)(json, 'aggregations') ? undefined : json['aggregations'],
        'sort': !(0, runtime_1.exists)(json, 'sort') ? undefined : (json['sort'].map(RequestBodySortInner_1.RequestBodySortInnerFromJSON)),
        'page': !(0, runtime_1.exists)(json, 'page') ? undefined : json['page'],
        'limit': !(0, runtime_1.exists)(json, 'limit') ? undefined : json['limit'],
        'associations': !(0, runtime_1.exists)(json, 'associations') ? undefined : json['associations'],
    };
}
function RequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'includes': value.includes,
        'ids': value.ids,
        'total-count-mode': value.totalCountMode,
        'filter': value.filter === undefined ? undefined : (value.filter.map(RequestBodyFilterInner_1.RequestBodyFilterInnerToJSON)),
        'aggregations': value.aggregations,
        'sort': value.sort === undefined ? undefined : (value.sort.map(RequestBodySortInner_1.RequestBodySortInnerToJSON)),
        'page': value.page,
        'limit': value.limit,
        'associations': value.associations,
    };
}
