"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Downloads - Software API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoftwareApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class SoftwareApi extends runtime.BaseAPI {
    /**
     * Downloads a given software media file.
     */
    async getSoftwareDownloadRaw(requestParameters, initOverrides) {
        if (requestParameters.software === null || requestParameters.software === undefined) {
            throw new runtime.RequiredError('software', 'Required parameter requestParameters.software was null or undefined when calling getSoftwareDownload.');
        }
        if (requestParameters.category === null || requestParameters.category === undefined) {
            throw new runtime.RequiredError('category', 'Required parameter requestParameters.category was null or undefined when calling getSoftwareDownload.');
        }
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file', 'Required parameter requestParameters.file was null or undefined when calling getSoftwareDownload.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerToken", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerTokenQuery", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/software/{software}/{category}/{file}`.replace(`{${"software"}}`, encodeURIComponent(String(requestParameters.software))).replace(`{${"category"}}`, encodeURIComponent(String(requestParameters.category))).replace(`{${"file"}}`, encodeURIComponent(String(requestParameters.file))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.BlobApiResponse(response);
    }
    /**
     * Downloads a given software media file.
     */
    async getSoftwareDownload(requestParameters, initOverrides) {
        const response = await this.getSoftwareDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Lists all available categories with media for the given software.
     */
    async getSoftwareMediaRaw(requestParameters, initOverrides) {
        if (requestParameters.software === null || requestParameters.software === undefined) {
            throw new runtime.RequiredError('software', 'Required parameter requestParameters.software was null or undefined when calling getSoftwareMedia.');
        }
        const queryParameters = {};
        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerToken", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerTokenQuery", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/software/{software}`.replace(`{${"software"}}`, encodeURIComponent(String(requestParameters.software))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.SoftwareMediaCategoryFromJSON));
    }
    /**
     * Lists all available categories with media for the given software.
     */
    async getSoftwareMedia(requestParameters, initOverrides) {
        const response = await this.getSoftwareMediaRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.SoftwareApi = SoftwareApi;
