"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassiveAddress = instanceOfMassiveAddress;
exports.MassiveAddressFromJSON = MassiveAddressFromJSON;
exports.MassiveAddressFromJSONTyped = MassiveAddressFromJSONTyped;
exports.MassiveAddressToJSON = MassiveAddressToJSON;
const runtime_1 = require("../runtime");
const Country_1 = require("./Country");
const CountryState_1 = require("./CountryState");
/**
 * Check if a given object implements the MassiveAddress interface.
 */
function instanceOfMassiveAddress(value) {
    let isInstance = true;
    isInstance = isInstance && "countryId" in value;
    isInstance = isInstance && "name1" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "zipcode" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function MassiveAddressFromJSON(json) {
    return MassiveAddressFromJSONTyped(json, false);
}
function MassiveAddressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'countryId': json['countryId'],
        'countryStateId': !(0, runtime_1.exists)(json, 'countryStateId') ? undefined : json['countryStateId'],
        'localeId': !(0, runtime_1.exists)(json, 'localeId') ? undefined : json['localeId'],
        'name1': json['name1'],
        'name2': !(0, runtime_1.exists)(json, 'name2') ? undefined : json['name2'],
        'name3': !(0, runtime_1.exists)(json, 'name3') ? undefined : json['name3'],
        'additionalAddressLine': !(0, runtime_1.exists)(json, 'additionalAddressLine') ? undefined : json['additionalAddressLine'],
        'city': json['city'],
        'street': json['street'],
        'zipcode': json['zipcode'],
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'fax': !(0, runtime_1.exists)(json, 'fax') ? undefined : json['fax'],
        'website': !(0, runtime_1.exists)(json, 'website') ? undefined : json['website'],
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'externalId': !(0, runtime_1.exists)(json, 'externalId') ? undefined : json['externalId'],
        'deleted': json['deleted'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'dataUpdateTriggeredAt': !(0, runtime_1.exists)(json, 'dataUpdateTriggeredAt') ? undefined : (json['dataUpdateTriggeredAt'] === null ? null : new Date(json['dataUpdateTriggeredAt'])),
        'country': !(0, runtime_1.exists)(json, 'country') ? undefined : (0, Country_1.CountryFromJSON)(json['country']),
        'countryState': !(0, runtime_1.exists)(json, 'countryState') ? undefined : (0, CountryState_1.CountryStateFromJSON)(json['countryState']),
    };
}
function MassiveAddressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'countryId': value.countryId,
        'countryStateId': value.countryStateId,
        'localeId': value.localeId,
        'name1': value.name1,
        'name2': value.name2,
        'name3': value.name3,
        'additionalAddressLine': value.additionalAddressLine,
        'city': value.city,
        'street': value.street,
        'zipcode': value.zipcode,
        'phoneNumber': value.phoneNumber,
        'fax': value.fax,
        'website': value.website,
        'email': value.email,
        'externalId': value.externalId,
        'deleted': value.deleted,
        'name': value.name,
        'country': (0, Country_1.CountryToJSON)(value.country),
        'countryState': (0, CountryState_1.CountryStateToJSON)(value.countryState),
    };
}
