"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCategoryExtensions = instanceOfCategoryExtensions;
exports.CategoryExtensionsFromJSON = CategoryExtensionsFromJSON;
exports.CategoryExtensionsFromJSONTyped = CategoryExtensionsFromJSONTyped;
exports.CategoryExtensionsToJSON = CategoryExtensionsToJSON;
const runtime_1 = require("../runtime");
const MassiveCategory_1 = require("./MassiveCategory");
/**
 * Check if a given object implements the CategoryExtensions interface.
 */
function instanceOfCategoryExtensions(value) {
    let isInstance = true;
    return isInstance;
}
function CategoryExtensionsFromJSON(json) {
    return CategoryExtensionsFromJSONTyped(json, false);
}
function CategoryExtensionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'massiveCategory': !(0, runtime_1.exists)(json, 'massiveCategory') ? undefined : (0, MassiveCategory_1.MassiveCategoryFromJSON)(json['massiveCategory']),
    };
}
function CategoryExtensionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'massiveCategory': (0, MassiveCategory_1.MassiveCategoryToJSON)(value.massiveCategory),
    };
}
