"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTax = instanceOfTax;
exports.TaxFromJSON = TaxFromJSON;
exports.TaxFromJSONTyped = TaxFromJSONTyped;
exports.TaxToJSON = TaxToJSON;
const runtime_1 = require("../runtime");
const CrossSelling_1 = require("./CrossSelling");
const ProductTranslation_1 = require("./ProductTranslation");
const TaxCover_1 = require("./TaxCover");
/**
 * Check if a given object implements the Tax interface.
 */
function instanceOfTax(value) {
    let isInstance = true;
    isInstance = isInstance && "taxRate" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
function TaxFromJSON(json) {
    return TaxFromJSONTyped(json, false);
}
function TaxFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'taxRate': json['taxRate'],
        'name': json['name'],
        'stock': !(0, runtime_1.exists)(json, 'stock') ? undefined : json['stock'],
        'availableStock': !(0, runtime_1.exists)(json, 'availableStock') ? undefined : json['availableStock'],
        'available': !(0, runtime_1.exists)(json, 'available') ? undefined : json['available'],
        'categoryTree': !(0, runtime_1.exists)(json, 'categoryTree') ? undefined : json['categoryTree'],
        'propertyIds': !(0, runtime_1.exists)(json, 'propertyIds') ? undefined : json['propertyIds'],
        'crossSellings': !(0, runtime_1.exists)(json, 'crossSellings') ? undefined : (json['crossSellings'] === null ? null : json['crossSellings'].map(CrossSelling_1.CrossSellingFromJSON)),
        'cover': !(0, runtime_1.exists)(json, 'cover') ? undefined : (0, TaxCover_1.TaxCoverFromJSON)(json['cover']),
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, ProductTranslation_1.ProductTranslationFromJSON)(json['translated']),
    };
}
function TaxToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'taxRate': value.taxRate,
        'name': value.name,
        'stock': value.stock,
        'availableStock': value.availableStock,
        'available': value.available,
        'categoryTree': value.categoryTree,
        'propertyIds': value.propertyIds,
        'crossSellings': value.crossSellings === undefined ? undefined : (value.crossSellings === null ? null : value.crossSellings.map(CrossSelling_1.CrossSellingToJSON)),
        'cover': (0, TaxCover_1.TaxCoverToJSON)(value.cover),
        'translated': (0, ProductTranslation_1.ProductTranslationToJSON)(value.translated),
    };
}
