"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassiveCompany = instanceOfMassiveCompany;
exports.MassiveCompanyFromJSON = MassiveCompanyFromJSON;
exports.MassiveCompanyFromJSONTyped = MassiveCompanyFromJSONTyped;
exports.MassiveCompanyToJSON = MassiveCompanyToJSON;
const runtime_1 = require("../runtime");
const Currency_1 = require("./Currency");
const MassiveAddress_1 = require("./MassiveAddress");
const MassiveCustomer_1 = require("./MassiveCustomer");
const MassiveIndustry_1 = require("./MassiveIndustry");
const SalesChannel_1 = require("./SalesChannel");
/**
 * Check if a given object implements the MassiveCompany interface.
 */
function instanceOfMassiveCompany(value) {
    let isInstance = true;
    isInstance = isInstance && "salesChannelId" in value;
    isInstance = isInstance && "defaultShippingMassiveAddressId" in value;
    isInstance = isInstance && "defaultBillingMassiveAddressId" in value;
    isInstance = isInstance && "currencyId" in value;
    isInstance = isInstance && "massiveIndustryId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function MassiveCompanyFromJSON(json) {
    return MassiveCompanyFromJSONTyped(json, false);
}
function MassiveCompanyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'salesChannelId': json['salesChannelId'],
        'salesOrganizationMassiveAddressId': !(0, runtime_1.exists)(json, 'salesOrganizationMassiveAddressId') ? undefined : json['salesOrganizationMassiveAddressId'],
        'serviceOrganizationMassiveAddressId': !(0, runtime_1.exists)(json, 'serviceOrganizationMassiveAddressId') ? undefined : json['serviceOrganizationMassiveAddressId'],
        'defaultMassiveAddressId': !(0, runtime_1.exists)(json, 'defaultMassiveAddressId') ? undefined : json['defaultMassiveAddressId'],
        'defaultShippingMassiveAddressId': json['defaultShippingMassiveAddressId'],
        'defaultBillingMassiveAddressId': json['defaultBillingMassiveAddressId'],
        'currencyId': json['currencyId'],
        'massiveIndustryId': json['massiveIndustryId'],
        'name': json['name'],
        'type': json['type'],
        'externalId': !(0, runtime_1.exists)(json, 'externalId') ? undefined : json['externalId'],
        'salesOrganizationExternalId': !(0, runtime_1.exists)(json, 'salesOrganizationExternalId') ? undefined : json['salesOrganizationExternalId'],
        'serviceOrganizationExternalId': !(0, runtime_1.exists)(json, 'serviceOrganizationExternalId') ? undefined : json['serviceOrganizationExternalId'],
        'deleted': json['deleted'],
        'vatId': !(0, runtime_1.exists)(json, 'vatId') ? undefined : json['vatId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'salesChannel': !(0, runtime_1.exists)(json, 'salesChannel') ? undefined : (0, SalesChannel_1.SalesChannelFromJSON)(json['salesChannel']),
        'serviceOrganizationMassiveAddress': !(0, runtime_1.exists)(json, 'serviceOrganizationMassiveAddress') ? undefined : (0, MassiveAddress_1.MassiveAddressFromJSON)(json['serviceOrganizationMassiveAddress']),
        'salesOrganizationMassiveAddress': !(0, runtime_1.exists)(json, 'salesOrganizationMassiveAddress') ? undefined : (0, MassiveAddress_1.MassiveAddressFromJSON)(json['salesOrganizationMassiveAddress']),
        'defaultMassiveAddress': !(0, runtime_1.exists)(json, 'defaultMassiveAddress') ? undefined : (0, MassiveAddress_1.MassiveAddressFromJSON)(json['defaultMassiveAddress']),
        'defaultShippingMassiveAddress': !(0, runtime_1.exists)(json, 'defaultShippingMassiveAddress') ? undefined : (0, MassiveAddress_1.MassiveAddressFromJSON)(json['defaultShippingMassiveAddress']),
        'defaultBillingMassiveAddress': !(0, runtime_1.exists)(json, 'defaultBillingMassiveAddress') ? undefined : (0, MassiveAddress_1.MassiveAddressFromJSON)(json['defaultBillingMassiveAddress']),
        'currency': !(0, runtime_1.exists)(json, 'currency') ? undefined : (0, Currency_1.CurrencyFromJSON)(json['currency']),
        'massiveIndustry': !(0, runtime_1.exists)(json, 'massiveIndustry') ? undefined : (0, MassiveIndustry_1.MassiveIndustryFromJSON)(json['massiveIndustry']),
        'additionalMassiveAddresses': !(0, runtime_1.exists)(json, 'additionalMassiveAddresses') ? undefined : (json['additionalMassiveAddresses'] === null ? null : json['additionalMassiveAddresses'].map(MassiveAddress_1.MassiveAddressFromJSON)),
        'massiveCustomers': !(0, runtime_1.exists)(json, 'massiveCustomers') ? undefined : (json['massiveCustomers'].map(MassiveCustomer_1.MassiveCustomerFromJSON)),
    };
}
function MassiveCompanyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'salesChannelId': value.salesChannelId,
        'salesOrganizationMassiveAddressId': value.salesOrganizationMassiveAddressId,
        'serviceOrganizationMassiveAddressId': value.serviceOrganizationMassiveAddressId,
        'defaultMassiveAddressId': value.defaultMassiveAddressId,
        'defaultShippingMassiveAddressId': value.defaultShippingMassiveAddressId,
        'defaultBillingMassiveAddressId': value.defaultBillingMassiveAddressId,
        'currencyId': value.currencyId,
        'massiveIndustryId': value.massiveIndustryId,
        'name': value.name,
        'type': value.type,
        'externalId': value.externalId,
        'salesOrganizationExternalId': value.salesOrganizationExternalId,
        'serviceOrganizationExternalId': value.serviceOrganizationExternalId,
        'deleted': value.deleted,
        'vatId': value.vatId,
        'salesChannel': (0, SalesChannel_1.SalesChannelToJSON)(value.salesChannel),
        'serviceOrganizationMassiveAddress': (0, MassiveAddress_1.MassiveAddressToJSON)(value.serviceOrganizationMassiveAddress),
        'salesOrganizationMassiveAddress': (0, MassiveAddress_1.MassiveAddressToJSON)(value.salesOrganizationMassiveAddress),
        'defaultMassiveAddress': (0, MassiveAddress_1.MassiveAddressToJSON)(value.defaultMassiveAddress),
        'defaultShippingMassiveAddress': (0, MassiveAddress_1.MassiveAddressToJSON)(value.defaultShippingMassiveAddress),
        'defaultBillingMassiveAddress': (0, MassiveAddress_1.MassiveAddressToJSON)(value.defaultBillingMassiveAddress),
        'currency': (0, Currency_1.CurrencyToJSON)(value.currency),
        'massiveIndustry': (0, MassiveIndustry_1.MassiveIndustryToJSON)(value.massiveIndustry),
        'additionalMassiveAddresses': value.additionalMassiveAddresses === undefined ? undefined : (value.additionalMassiveAddresses === null ? null : value.additionalMassiveAddresses.map(MassiveAddress_1.MassiveAddressToJSON)),
        'massiveCustomers': value.massiveCustomers === undefined ? undefined : (value.massiveCustomers.map(MassiveCustomer_1.MassiveCustomerToJSON)),
    };
}
