"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSalutationExtensions = instanceOfSalutationExtensions;
exports.SalutationExtensionsFromJSON = SalutationExtensionsFromJSON;
exports.SalutationExtensionsFromJSONTyped = SalutationExtensionsFromJSONTyped;
exports.SalutationExtensionsToJSON = SalutationExtensionsToJSON;
const runtime_1 = require("../runtime");
const MassiveSalutation_1 = require("./MassiveSalutation");
/**
 * Check if a given object implements the SalutationExtensions interface.
 */
function instanceOfSalutationExtensions(value) {
    let isInstance = true;
    return isInstance;
}
function SalutationExtensionsFromJSON(json) {
    return SalutationExtensionsFromJSONTyped(json, false);
}
function SalutationExtensionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'massiveSalutaion': !(0, runtime_1.exists)(json, 'massiveSalutaion') ? undefined : (0, MassiveSalutation_1.MassiveSalutationFromJSON)(json['massiveSalutaion']),
    };
}
function SalutationExtensionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'massiveSalutaion': (0, MassiveSalutation_1.MassiveSalutationToJSON)(value.massiveSalutaion),
    };
}
