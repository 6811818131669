"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOrderExtensionAdyen = instanceOfOrderExtensionAdyen;
exports.OrderExtensionAdyenFromJSON = OrderExtensionAdyenFromJSON;
exports.OrderExtensionAdyenFromJSONTyped = OrderExtensionAdyenFromJSONTyped;
exports.OrderExtensionAdyenToJSON = OrderExtensionAdyenToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the OrderExtensionAdyen interface.
 */
function instanceOfOrderExtensionAdyen(value) {
    let isInstance = true;
    return isInstance;
}
function OrderExtensionAdyenFromJSON(json) {
    return OrderExtensionAdyenFromJSONTyped(json, false);
}
function OrderExtensionAdyenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'clientKey': !(0, runtime_1.exists)(json, 'clientKey') ? undefined : json['clientKey'],
    };
}
function OrderExtensionAdyenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'clientKey': value.clientKey,
    };
}
