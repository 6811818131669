"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTaxes = void 0;
const money_1 = require("./money");
const calculateTaxes = (order, useCurrencyCode) => {
    const calculatedTaxes = order?.price.calculatedTaxes ?? [];
    if (calculatedTaxes.length === 0) {
        return null;
    }
    const totalTaxes = calculatedTaxes.reduce((sum, item) => sum + item.tax, 0);
    if (!useCurrencyCode || !order?.currency?.isoCode) {
        return totalTaxes;
    }
    return (0, money_1.moneyFormat)(totalTaxes, order?.currency?.isoCode);
};
exports.calculateTaxes = calculateTaxes;
