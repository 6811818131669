"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCart = instanceOfCart;
exports.CartFromJSON = CartFromJSON;
exports.CartFromJSONTyped = CartFromJSONTyped;
exports.CartToJSON = CartToJSON;
const runtime_1 = require("../runtime");
const CartDelivery_1 = require("./CartDelivery");
const CartExtensions_1 = require("./CartExtensions");
const CartPrice_1 = require("./CartPrice");
const LineItem_1 = require("./LineItem");
/**
 * Check if a given object implements the Cart interface.
 */
function instanceOfCart(value) {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "lineItems" in value;
    isInstance = isInstance && "price" in value;
    return isInstance;
}
function CartFromJSON(json) {
    return CartFromJSONTyped(json, false);
}
function CartFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'token': json['token'],
        'lineItems': (json['lineItems'].map(LineItem_1.LineItemFromJSON)),
        'deliveries': !(0, runtime_1.exists)(json, 'deliveries') ? undefined : (json['deliveries'].map(CartDelivery_1.CartDeliveryFromJSON)),
        'errors': !(0, runtime_1.exists)(json, 'errors') ? undefined : json['errors'],
        'price': (0, CartPrice_1.CartPriceFromJSON)(json['price']),
        'customerComment': !(0, runtime_1.exists)(json, 'customerComment') ? undefined : json['customerComment'],
        'extensions': !(0, runtime_1.exists)(json, 'extensions') ? undefined : (0, CartExtensions_1.CartExtensionsFromJSON)(json['extensions']),
    };
}
function CartToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token': value.token,
        'lineItems': (value.lineItems.map(LineItem_1.LineItemToJSON)),
        'deliveries': value.deliveries === undefined ? undefined : (value.deliveries.map(CartDelivery_1.CartDeliveryToJSON)),
        'errors': value.errors,
        'price': (0, CartPrice_1.CartPriceToJSON)(value.price),
        'customerComment': value.customerComment,
        'extensions': (0, CartExtensions_1.CartExtensionsToJSON)(value.extensions),
    };
}
