"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOrderCustomer = instanceOfOrderCustomer;
exports.OrderCustomerFromJSON = OrderCustomerFromJSON;
exports.OrderCustomerFromJSONTyped = OrderCustomerFromJSONTyped;
exports.OrderCustomerToJSON = OrderCustomerToJSON;
const runtime_1 = require("../runtime");
const Order_1 = require("./Order");
const Salutation_1 = require("./Salutation");
/**
 * Check if a given object implements the OrderCustomer interface.
 */
function instanceOfOrderCustomer(value) {
    let isInstance = true;
    isInstance = isInstance && "orderId" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "salutationId" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function OrderCustomerFromJSON(json) {
    return OrderCustomerFromJSONTyped(json, false);
}
function OrderCustomerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'customerId': !(0, runtime_1.exists)(json, 'customerId') ? undefined : json['customerId'],
        'orderId': json['orderId'],
        'email': json['email'],
        'salutationId': json['salutationId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'company': !(0, runtime_1.exists)(json, 'company') ? undefined : json['company'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'customerNumber': !(0, runtime_1.exists)(json, 'customerNumber') ? undefined : json['customerNumber'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'order': !(0, runtime_1.exists)(json, 'order') ? undefined : (0, Order_1.OrderFromJSON)(json['order']),
        'salutation': !(0, runtime_1.exists)(json, 'salutation') ? undefined : (0, Salutation_1.SalutationFromJSON)(json['salutation']),
    };
}
function OrderCustomerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'customerId': value.customerId,
        'orderId': value.orderId,
        'email': value.email,
        'salutationId': value.salutationId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'company': value.company,
        'title': value.title,
        'customerNumber': value.customerNumber,
        'order': (0, Order_1.OrderToJSON)(value.order),
        'salutation': (0, Salutation_1.SalutationToJSON)(value.salutation),
    };
}
