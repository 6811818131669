"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LineItemPayloadZkpCartItemGroupEnum = void 0;
exports.instanceOfLineItemPayload = instanceOfLineItemPayload;
exports.LineItemPayloadFromJSON = LineItemPayloadFromJSON;
exports.LineItemPayloadFromJSONTyped = LineItemPayloadFromJSONTyped;
exports.LineItemPayloadToJSON = LineItemPayloadToJSON;
const runtime_1 = require("../runtime");
/**
 * @export
 */
exports.LineItemPayloadZkpCartItemGroupEnum = {
    Others: 'others',
    Products: 'products',
    Software: 'software',
    SoftwareOffers: 'softwareOffers',
    SoftwareWithMachine: 'softwareWithMachine'
};
/**
 * Check if a given object implements the LineItemPayload interface.
 */
function instanceOfLineItemPayload(value) {
    let isInstance = true;
    return isInstance;
}
function LineItemPayloadFromJSON(json) {
    return LineItemPayloadFromJSONTyped(json, false);
}
function LineItemPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'code': !(0, runtime_1.exists)(json, 'code') ? undefined : json['code'],
        'productNumber': !(0, runtime_1.exists)(json, 'productNumber') ? undefined : json['productNumber'],
        'massiveAdditionalSeatsCount': !(0, runtime_1.exists)(json, 'massiveAdditionalSeatsCount') ? undefined : json['massiveAdditionalSeatsCount'],
        'massiveIsAdditionalSeats': !(0, runtime_1.exists)(json, 'massiveIsAdditionalSeats') ? undefined : json['massiveIsAdditionalSeats'],
        'zkpMachineId': !(0, runtime_1.exists)(json, 'zkpMachineId') ? undefined : json['zkpMachineId'],
        'zkpMachineSerialNumber': !(0, runtime_1.exists)(json, 'zkpMachineSerialNumber') ? undefined : json['zkpMachineSerialNumber'],
        'zkpSoftwareOfferEndDate': !(0, runtime_1.exists)(json, 'zkpSoftwareOfferEndDate') ? undefined : (new Date(json['zkpSoftwareOfferEndDate'])),
        'zkpSoftwareOfferId': !(0, runtime_1.exists)(json, 'zkpSoftwareOfferId') ? undefined : json['zkpSoftwareOfferId'],
        'zkpSoftwareOfferLicenseId': !(0, runtime_1.exists)(json, 'zkpSoftwareOfferLicenseId') ? undefined : json['zkpSoftwareOfferLicenseId'],
        'zkpSoftwareOfferMachineSerialNumber': !(0, runtime_1.exists)(json, 'zkpSoftwareOfferMachineSerialNumber') ? undefined : json['zkpSoftwareOfferMachineSerialNumber'],
        'zkpSoftwareOfferOfferEndDate': !(0, runtime_1.exists)(json, 'zkpSoftwareOfferOfferEndDate') ? undefined : (new Date(json['zkpSoftwareOfferOfferEndDate'])),
        'zkpSoftwareOfferStartDate': !(0, runtime_1.exists)(json, 'zkpSoftwareOfferStartDate') ? undefined : (new Date(json['zkpSoftwareOfferStartDate'])),
        'zkpCartItemGroup': !(0, runtime_1.exists)(json, 'zkpCartItemGroup') ? undefined : json['zkpCartItemGroup'],
        'zkpPaid': !(0, runtime_1.exists)(json, 'zkpPaid') ? undefined : json['zkpPaid'],
    };
}
function LineItemPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'code': value.code,
        'productNumber': value.productNumber,
        'massiveAdditionalSeatsCount': value.massiveAdditionalSeatsCount,
        'massiveIsAdditionalSeats': value.massiveIsAdditionalSeats,
        'zkpMachineId': value.zkpMachineId,
        'zkpMachineSerialNumber': value.zkpMachineSerialNumber,
        'zkpSoftwareOfferEndDate': value.zkpSoftwareOfferEndDate === undefined ? undefined : (value.zkpSoftwareOfferEndDate.toISOString()),
        'zkpSoftwareOfferId': value.zkpSoftwareOfferId,
        'zkpSoftwareOfferLicenseId': value.zkpSoftwareOfferLicenseId,
        'zkpSoftwareOfferMachineSerialNumber': value.zkpSoftwareOfferMachineSerialNumber,
        'zkpSoftwareOfferOfferEndDate': value.zkpSoftwareOfferOfferEndDate === undefined ? undefined : (value.zkpSoftwareOfferOfferEndDate.toISOString()),
        'zkpSoftwareOfferStartDate': value.zkpSoftwareOfferStartDate === undefined ? undefined : (value.zkpSoftwareOfferStartDate.toISOString()),
        'zkpCartItemGroup': value.zkpCartItemGroup,
        'zkpPaid': value.zkpPaid,
    };
}
