"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassiveCategory = instanceOfMassiveCategory;
exports.MassiveCategoryFromJSON = MassiveCategoryFromJSON;
exports.MassiveCategoryFromJSONTyped = MassiveCategoryFromJSONTyped;
exports.MassiveCategoryToJSON = MassiveCategoryToJSON;
const runtime_1 = require("../runtime");
const MassiveCategoryDisplayedProperty_1 = require("./MassiveCategoryDisplayedProperty");
const MassiveCategoryFilterProperty_1 = require("./MassiveCategoryFilterProperty");
/**
 * Check if a given object implements the MassiveCategory interface.
 */
function instanceOfMassiveCategory(value) {
    let isInstance = true;
    isInstance = isInstance && "categoryId" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function MassiveCategoryFromJSON(json) {
    return MassiveCategoryFromJSONTyped(json, false);
}
function MassiveCategoryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'categoryId': json['categoryId'],
        'displayedProperties': !(0, runtime_1.exists)(json, 'displayedProperties') ? undefined : (json['displayedProperties'] === null ? null : json['displayedProperties'].map(MassiveCategoryDisplayedProperty_1.MassiveCategoryDisplayedPropertyFromJSON)),
        'filterProperties': !(0, runtime_1.exists)(json, 'filterProperties') ? undefined : (json['filterProperties'] === null ? null : json['filterProperties'].map(MassiveCategoryFilterProperty_1.MassiveCategoryFilterPropertyFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'externalId': !(0, runtime_1.exists)(json, 'externalId') ? undefined : json['externalId'],
    };
}
function MassiveCategoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'categoryId': value.categoryId,
        'displayedProperties': value.displayedProperties === undefined ? undefined : (value.displayedProperties === null ? null : value.displayedProperties.map(MassiveCategoryDisplayedProperty_1.MassiveCategoryDisplayedPropertyToJSON)),
        'filterProperties': value.filterProperties === undefined ? undefined : (value.filterProperties === null ? null : value.filterProperties.map(MassiveCategoryFilterProperty_1.MassiveCategoryFilterPropertyToJSON)),
        'externalId': value.externalId,
    };
}
