"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfProductCustomFields = instanceOfProductCustomFields;
exports.ProductCustomFieldsFromJSON = ProductCustomFieldsFromJSON;
exports.ProductCustomFieldsFromJSONTyped = ProductCustomFieldsFromJSONTyped;
exports.ProductCustomFieldsToJSON = ProductCustomFieldsToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ProductCustomFields interface.
 */
function instanceOfProductCustomFields(value) {
    let isInstance = true;
    return isInstance;
}
function ProductCustomFieldsFromJSON(json) {
    return ProductCustomFieldsFromJSONTyped(json, false);
}
function ProductCustomFieldsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'massiveSoftwareConfigurationTitleInProductList': !(0, runtime_1.exists)(json, 'massive_software_configuration_title_in_product_list') ? undefined : json['massive_software_configuration_title_in_product_list'],
        'massiveSoftwareConfigurationDescription': !(0, runtime_1.exists)(json, 'massive_software_configuration_description') ? undefined : json['massive_software_configuration_description'],
        'massiveSoftwareConfigurationCompareTable': !(0, runtime_1.exists)(json, 'massive_software_configuration_compare_table') ? undefined : json['massive_software_configuration_compare_table'],
        'massiveSoftwareConfigurationUrlReadMore': !(0, runtime_1.exists)(json, 'massive_software_configuration_url_read_more') ? undefined : json['massive_software_configuration_url_read_more'],
        'massiveSoftwareConfigurationAdditionalSeatProduct': !(0, runtime_1.exists)(json, 'massive_software_configuration_additional_seat_product') ? undefined : json['massive_software_configuration_additional_seat_product'],
        'zkpSoftwareRequiresMachineSelection': !(0, runtime_1.exists)(json, 'zkp_software_requires_machine_selection') ? undefined : json['zkp_software_requires_machine_selection'],
    };
}
function ProductCustomFieldsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'massive_software_configuration_title_in_product_list': value.massiveSoftwareConfigurationTitleInProductList,
        'massive_software_configuration_description': value.massiveSoftwareConfigurationDescription,
        'massive_software_configuration_compare_table': value.massiveSoftwareConfigurationCompareTable,
        'massive_software_configuration_url_read_more': value.massiveSoftwareConfigurationUrlReadMore,
        'massive_software_configuration_additional_seat_product': value.massiveSoftwareConfigurationAdditionalSeatProduct,
        'zkp_software_requires_machine_selection': value.zkpSoftwareRequiresMachineSelection,
    };
}
