"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ContactContentClickTypes;
(function (ContactContentClickTypes) {
    ContactContentClickTypes["email"] = "E-Mail";
    ContactContentClickTypes["phone"] = "Telefon";
    ContactContentClickTypes["fax"] = "Fax";
    ContactContentClickTypes["website"] = "Website";
})(ContactContentClickTypes || (ContactContentClickTypes = {}));
exports.default = ContactContentClickTypes;
