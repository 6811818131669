"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MassiveComputedSoftwareLicenseStatusEnum = void 0;
exports.instanceOfMassiveComputedSoftwareLicense = instanceOfMassiveComputedSoftwareLicense;
exports.MassiveComputedSoftwareLicenseFromJSON = MassiveComputedSoftwareLicenseFromJSON;
exports.MassiveComputedSoftwareLicenseFromJSONTyped = MassiveComputedSoftwareLicenseFromJSONTyped;
exports.MassiveComputedSoftwareLicenseToJSON = MassiveComputedSoftwareLicenseToJSON;
/**
 * @export
 */
exports.MassiveComputedSoftwareLicenseStatusEnum = {
    NotActivated: 'not_activated',
    AlreadyBought: 'already_bought'
};
/**
 * Check if a given object implements the MassiveComputedSoftwareLicense interface.
 */
function instanceOfMassiveComputedSoftwareLicense(value) {
    let isInstance = true;
    isInstance = isInstance && "licenseCount" in value;
    isInstance = isInstance && "licenseId" in value;
    isInstance = isInstance && "productType" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
function MassiveComputedSoftwareLicenseFromJSON(json) {
    return MassiveComputedSoftwareLicenseFromJSONTyped(json, false);
}
function MassiveComputedSoftwareLicenseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'licenseCount': json['licenseCount'],
        'licenseId': json['licenseId'],
        'productType': json['productType'],
        'status': json['status'],
    };
}
function MassiveComputedSoftwareLicenseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'licenseCount': value.licenseCount,
        'licenseId': value.licenseId,
        'productType': value.productType,
        'status': value.status,
    };
}
