"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSalesChannelCustomFields = instanceOfSalesChannelCustomFields;
exports.SalesChannelCustomFieldsFromJSON = SalesChannelCustomFieldsFromJSON;
exports.SalesChannelCustomFieldsFromJSONTyped = SalesChannelCustomFieldsFromJSONTyped;
exports.SalesChannelCustomFieldsToJSON = SalesChannelCustomFieldsToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the SalesChannelCustomFields interface.
 */
function instanceOfSalesChannelCustomFields(value) {
    let isInstance = true;
    return isInstance;
}
function SalesChannelCustomFieldsFromJSON(json) {
    return SalesChannelCustomFieldsFromJSONTyped(json, false);
}
function SalesChannelCustomFieldsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'massiveCheckoutHideTaxesAndShippingCosts': !(0, runtime_1.exists)(json, 'massive_checkout_hide_taxes_and_shipping_costs') ? undefined : json['massive_checkout_hide_taxes_and_shipping_costs'],
        'massivePonumberRequired': !(0, runtime_1.exists)(json, 'massive_ponumber_required') ? undefined : json['massive_ponumber_required'],
        'massiveSoftwareCategory': !(0, runtime_1.exists)(json, 'massive_software_category') ? undefined : json['massive_software_category'],
        'massiveCheckoutBlockedError': !(0, runtime_1.exists)(json, 'massive_checkout_blocked_error') ? undefined : json['massive_checkout_blocked_error'],
        'massiveConsumableCheckoutBlockedErrorTitle': !(0, runtime_1.exists)(json, 'massive_consumable_checkout_blocked_error_title') ? undefined : json['massive_consumable_checkout_blocked_error_title'],
        'massiveConsumableCheckoutBlockedErrorMessage': !(0, runtime_1.exists)(json, 'massive_consumable_checkout_blocked_error_message') ? undefined : json['massive_consumable_checkout_blocked_error_message'],
        'massiveCheckoutTermsConditions': !(0, runtime_1.exists)(json, 'massive_checkout_terms_conditions') ? undefined : json['massive_checkout_terms_conditions'],
        'massivePonumberPlaceholder': !(0, runtime_1.exists)(json, 'massive_ponumber_placeholder') ? undefined : json['massive_ponumber_placeholder'],
        'massiveActivateSoftwareTitle': !(0, runtime_1.exists)(json, 'massive_activate_software_title') ? undefined : json['massive_activate_software_title'],
        'massiveActivateSoftwareConsentText': !(0, runtime_1.exists)(json, 'massive_activate_software_consent_text') ? undefined : json['massive_activate_software_consent_text'],
        'massiveActivateSoftwareCheckboxText': !(0, runtime_1.exists)(json, 'massive_activate_software_checkbox_text') ? undefined : json['massive_activate_software_checkbox_text'],
        'massiveResellerInfoText': !(0, runtime_1.exists)(json, 'massive_reseller_info_text') ? undefined : json['massive_reseller_info_text'],
        'massiveDownloadLicenseTitle': !(0, runtime_1.exists)(json, 'massive_download_license_title') ? undefined : json['massive_download_license_title'],
        'massiveDownloadLicenseConsentText': !(0, runtime_1.exists)(json, 'massive_download_license_consent_text') ? undefined : json['massive_download_license_consent_text'],
        'massiveDownloadLicenseCheckboxText': !(0, runtime_1.exists)(json, 'massive_download_license_checkbox_text') ? undefined : json['massive_download_license_checkbox_text'],
        'massiveCheckoutConfirmationOrderHeadline': !(0, runtime_1.exists)(json, 'massive_checkout_confirmation_order_headline') ? undefined : json['massive_checkout_confirmation_order_headline'],
        'massiveCheckoutConfirmationOrderText': !(0, runtime_1.exists)(json, 'massive_checkout_confirmation_order_text') ? undefined : json['massive_checkout_confirmation_order_text'],
        'massiveCheckoutConfirmationInquiryHeadline': !(0, runtime_1.exists)(json, 'massive_checkout_confirmation_inquiry_headline') ? undefined : json['massive_checkout_confirmation_inquiry_headline'],
        'massiveCheckoutConfirmationInquiryText': !(0, runtime_1.exists)(json, 'massive_checkout_confirmation_inquiry_text') ? undefined : json['massive_checkout_confirmation_inquiry_text'],
        'massivePopoverLoggedInText': !(0, runtime_1.exists)(json, 'massive_popover_logged_in_text') ? undefined : json['massive_popover_logged_in_text'],
        'massiveAdvancePaymentText': !(0, runtime_1.exists)(json, 'massive_advance_payment_text') ? undefined : json['massive_advance_payment_text'],
    };
}
function SalesChannelCustomFieldsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'massive_checkout_hide_taxes_and_shipping_costs': value.massiveCheckoutHideTaxesAndShippingCosts,
        'massive_ponumber_required': value.massivePonumberRequired,
        'massive_software_category': value.massiveSoftwareCategory,
        'massive_checkout_blocked_error': value.massiveCheckoutBlockedError,
        'massive_consumable_checkout_blocked_error_title': value.massiveConsumableCheckoutBlockedErrorTitle,
        'massive_consumable_checkout_blocked_error_message': value.massiveConsumableCheckoutBlockedErrorMessage,
        'massive_checkout_terms_conditions': value.massiveCheckoutTermsConditions,
        'massive_ponumber_placeholder': value.massivePonumberPlaceholder,
        'massive_activate_software_title': value.massiveActivateSoftwareTitle,
        'massive_activate_software_consent_text': value.massiveActivateSoftwareConsentText,
        'massive_activate_software_checkbox_text': value.massiveActivateSoftwareCheckboxText,
        'massive_reseller_info_text': value.massiveResellerInfoText,
        'massive_download_license_title': value.massiveDownloadLicenseTitle,
        'massive_download_license_consent_text': value.massiveDownloadLicenseConsentText,
        'massive_download_license_checkbox_text': value.massiveDownloadLicenseCheckboxText,
        'massive_checkout_confirmation_order_headline': value.massiveCheckoutConfirmationOrderHeadline,
        'massive_checkout_confirmation_order_text': value.massiveCheckoutConfirmationOrderText,
        'massive_checkout_confirmation_inquiry_headline': value.massiveCheckoutConfirmationInquiryHeadline,
        'massive_checkout_confirmation_inquiry_text': value.massiveCheckoutConfirmationInquiryText,
        'massive_popover_logged_in_text': value.massivePopoverLoggedInText,
        'massive_advance_payment_text': value.massiveAdvancePaymentText,
    };
}
