"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Activities - Activities API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfGeneralFailureError = instanceOfGeneralFailureError;
exports.GeneralFailureErrorFromJSON = GeneralFailureErrorFromJSON;
exports.GeneralFailureErrorFromJSONTyped = GeneralFailureErrorFromJSONTyped;
exports.GeneralFailureErrorToJSON = GeneralFailureErrorToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the GeneralFailureError interface.
 */
function instanceOfGeneralFailureError(value) {
    let isInstance = true;
    return isInstance;
}
function GeneralFailureErrorFromJSON(json) {
    return GeneralFailureErrorFromJSONTyped(json, false);
}
function GeneralFailureErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : json['status'],
        'code': !(0, runtime_1.exists)(json, 'code') ? undefined : json['code'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'detail': !(0, runtime_1.exists)(json, 'detail') ? undefined : json['detail'],
        'meta': !(0, runtime_1.exists)(json, 'meta') ? undefined : json['meta'],
    };
}
function GeneralFailureErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': value.status,
        'code': value.code,
        'title': value.title,
        'detail': value.detail,
        'meta': value.meta,
    };
}
