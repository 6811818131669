"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MANUFACTURER_ADDRESS = void 0;
exports.MANUFACTURER_ADDRESS = {
    id: '123',
    name1: 'Zünd Systemtechnik AG',
    street: 'Industriestrasse 8',
    zipcode: '9450',
    city: 'Altstätten',
    countryId: 'e2f49a5a607f4f619a1356d860125e82',
    country: {
        id: 'e2f49a5a607f4f619a1356d860125e82',
        iso: 'CH',
        createdAt: new Date(),
        translated: {
            name: 'Switzerland',
        },
    },
    phoneNumber: '+41 71 554 81 00',
    email: 'info@zund.com',
    deleted: false,
    createdAt: new Date(),
};
