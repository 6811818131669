"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Downloads - Software API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSoftwareMediaFile = instanceOfSoftwareMediaFile;
exports.SoftwareMediaFileFromJSON = SoftwareMediaFileFromJSON;
exports.SoftwareMediaFileFromJSONTyped = SoftwareMediaFileFromJSONTyped;
exports.SoftwareMediaFileToJSON = SoftwareMediaFileToJSON;
/**
 * Check if a given object implements the SoftwareMediaFile interface.
 */
function instanceOfSoftwareMediaFile(value) {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "mimeType" in value;
    isInstance = isInstance && "extension" in value;
    return isInstance;
}
function SoftwareMediaFileFromJSON(json) {
    return SoftwareMediaFileFromJSONTyped(json, false);
}
function SoftwareMediaFileFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': json['key'],
        'name': json['name'],
        'size': json['size'],
        'mimeType': json['mimeType'],
        'extension': json['extension'],
    };
}
function SoftwareMediaFileToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
        'name': value.name,
        'size': value.size,
        'mimeType': value.mimeType,
        'extension': value.extension,
    };
}
