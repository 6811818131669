"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Activities - Activities API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfActivityReference = instanceOfActivityReference;
exports.ActivityReferenceFromJSON = ActivityReferenceFromJSON;
exports.ActivityReferenceFromJSONTyped = ActivityReferenceFromJSONTyped;
exports.ActivityReferenceToJSON = ActivityReferenceToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ActivityReference interface.
 */
function instanceOfActivityReference(value) {
    let isInstance = true;
    isInstance = isInstance && "zundId" in value;
    return isInstance;
}
function ActivityReferenceFromJSON(json) {
    return ActivityReferenceFromJSONTyped(json, false);
}
function ActivityReferenceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'shopId': !(0, runtime_1.exists)(json, 'shopId') ? undefined : json['shopId'],
        'zundId': json['zundId'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'data': !(0, runtime_1.exists)(json, 'data') ? undefined : json['data'],
    };
}
function ActivityReferenceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'shopId': value.shopId,
        'zundId': value.zundId,
        'name': value.name,
        'data': value.data,
    };
}
