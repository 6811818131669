"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCompanyAddressById = exports.getAddressesOfCompany = void 0;
const getAddressesOfCompany = (company) => {
    const allAddresses = [];
    if (company?.defaultBillingMassiveAddress) {
        allAddresses.push(company?.defaultBillingMassiveAddress);
    }
    if (company?.defaultShippingMassiveAddress) {
        allAddresses.push(company?.defaultShippingMassiveAddress);
    }
    if (company?.additionalMassiveAddresses) {
        company?.additionalMassiveAddresses?.forEach((address) => {
            allAddresses.push(address);
        });
    }
    return Object.values(allAddresses)
        .filter((options, address) => options !== undefined && address !== undefined)
        .reduce((options, address) => {
        return address.id
            ? {
                ...options,
                [address.id]: address,
            }
            : options;
    }, {});
};
exports.getAddressesOfCompany = getAddressesOfCompany;
const getCompanyAddressById = (id, company) => {
    const addresses = (0, exports.getAddressesOfCompany)(company);
    return id ? addresses[id] : undefined;
};
exports.getCompanyAddressById = getCompanyAddressById;
