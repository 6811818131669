"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactTableTheme = void 0;
exports.ReactTableTheme = {
    zkp: {
        text: {
            fontSize: '1rem',
            color: '#000000',
            selectedColor: '#000000',
            hoverColor: '#000000',
            fontFamily: 'IBMPlexSans,-apple-system,Segoe UI,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        },
        nodes: {
            folder: {
                bgColor: 'transparent',
                selectedBgColor: 'transparent',
                hoverBgColor: 'transparent',
            },
            leaf: {
                bgColor: 'transparent',
                selectedBgColor: 'transparent',
                hoverBgColor: 'transparent',
            },
            separator: {
                border: '1px solid',
                borderColor: 'transparent',
            },
            icons: {
                size: '1rem',
                folderColor: 'transparent',
                leafColor: 'transparent',
            },
        },
    },
};
