"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCmsSlotTranslated = instanceOfCmsSlotTranslated;
exports.CmsSlotTranslatedFromJSON = CmsSlotTranslatedFromJSON;
exports.CmsSlotTranslatedFromJSONTyped = CmsSlotTranslatedFromJSONTyped;
exports.CmsSlotTranslatedToJSON = CmsSlotTranslatedToJSON;
const runtime_1 = require("../runtime");
const CmsSlotTranslatedConfig_1 = require("./CmsSlotTranslatedConfig");
/**
 * Check if a given object implements the CmsSlotTranslated interface.
 */
function instanceOfCmsSlotTranslated(value) {
    let isInstance = true;
    return isInstance;
}
function CmsSlotTranslatedFromJSON(json) {
    return CmsSlotTranslatedFromJSONTyped(json, false);
}
function CmsSlotTranslatedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'config': !(0, runtime_1.exists)(json, 'config') ? undefined : (0, CmsSlotTranslatedConfig_1.CmsSlotTranslatedConfigFromJSON)(json['config']),
    };
}
function CmsSlotTranslatedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'config': (0, CmsSlotTranslatedConfig_1.CmsSlotTranslatedConfigToJSON)(value.config),
    };
}
