"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCalculatedTax = instanceOfCalculatedTax;
exports.CalculatedTaxFromJSON = CalculatedTaxFromJSON;
exports.CalculatedTaxFromJSONTyped = CalculatedTaxFromJSONTyped;
exports.CalculatedTaxToJSON = CalculatedTaxToJSON;
/**
 * Check if a given object implements the CalculatedTax interface.
 */
function instanceOfCalculatedTax(value) {
    let isInstance = true;
    isInstance = isInstance && "tax" in value;
    isInstance = isInstance && "taxRate" in value;
    isInstance = isInstance && "price" in value;
    return isInstance;
}
function CalculatedTaxFromJSON(json) {
    return CalculatedTaxFromJSONTyped(json, false);
}
function CalculatedTaxFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tax': json['tax'],
        'taxRate': json['taxRate'],
        'price': json['price'],
    };
}
function CalculatedTaxToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tax': value.tax,
        'taxRate': value.taxRate,
        'price': value.price,
    };
}
