"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const middleware_1 = require("../../../common/js/apis/helper/middleware");
const Audiences_1 = require("../../../common/js/constants/Audiences");
class ActivitiesMiddleware {
    post(context) {
        return (0, middleware_1.postWithToken)(context);
    }
    pre(context) {
        return (0, middleware_1.preWithToken)(context, Audiences_1.Audiences.ACTIVITIES, []);
    }
}
exports.default = new ActivitiesMiddleware();
