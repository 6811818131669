"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Audiences = void 0;
exports.Audiences = {
    ACTIVITIES: __APP__.AUTH0_AUDIENCE_ACTIVITIES,
    ASSETS: __APP__.AUTH0_AUDIENCE_ASSETS,
    DOWNLOADS: __APP__.AUTH0_AUDIENCE_DOWNLOADS,
    PORTAL: __APP__.AUTH0_AUDIENCE_PORTAL,
    SHOP: __APP__.AUTH0_AUDIENCE_ECOMMERCE,
    SULU: __APP__.AUTH0_AUDIENCE_SULU,
    VAULT: __APP__.AUTH0_AUDIENCE_VAULT,
};
