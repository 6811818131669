"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPropertyGroup = instanceOfPropertyGroup;
exports.PropertyGroupFromJSON = PropertyGroupFromJSON;
exports.PropertyGroupFromJSONTyped = PropertyGroupFromJSONTyped;
exports.PropertyGroupToJSON = PropertyGroupToJSON;
const runtime_1 = require("../runtime");
const PropertyGroupExtensions_1 = require("./PropertyGroupExtensions");
const PropertyGroupOption_1 = require("./PropertyGroupOption");
const PropertyGroupTranslated_1 = require("./PropertyGroupTranslated");
/**
 * Check if a given object implements the PropertyGroup interface.
 */
function instanceOfPropertyGroup(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function PropertyGroupFromJSON(json) {
    return PropertyGroupFromJSONTyped(json, false);
}
function PropertyGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, PropertyGroupTranslated_1.PropertyGroupTranslatedFromJSON)(json['translated']),
        'options': !(0, runtime_1.exists)(json, 'options') ? undefined : (0, PropertyGroupOption_1.PropertyGroupOptionFromJSON)(json['options']),
        'extensions': !(0, runtime_1.exists)(json, 'extensions') ? undefined : (0, PropertyGroupExtensions_1.PropertyGroupExtensionsFromJSON)(json['extensions']),
    };
}
function PropertyGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'translated': (0, PropertyGroupTranslated_1.PropertyGroupTranslatedToJSON)(value.translated),
        'options': (0, PropertyGroupOption_1.PropertyGroupOptionToJSON)(value.options),
        'extensions': (0, PropertyGroupExtensions_1.PropertyGroupExtensionsToJSON)(value.extensions),
    };
}
