"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCartDelivery = instanceOfCartDelivery;
exports.CartDeliveryFromJSON = CartDeliveryFromJSON;
exports.CartDeliveryFromJSONTyped = CartDeliveryFromJSONTyped;
exports.CartDeliveryToJSON = CartDeliveryToJSON;
const runtime_1 = require("../runtime");
const CartDeliveryCustomFields_1 = require("./CartDeliveryCustomFields");
const OrderAddress_1 = require("./OrderAddress");
const ShippingCosts_1 = require("./ShippingCosts");
const ShippingMethod_1 = require("./ShippingMethod");
/**
 * Check if a given object implements the CartDelivery interface.
 */
function instanceOfCartDelivery(value) {
    let isInstance = true;
    isInstance = isInstance && "shippingMethod" in value;
    isInstance = isInstance && "shippingCosts" in value;
    return isInstance;
}
function CartDeliveryFromJSON(json) {
    return CartDeliveryFromJSONTyped(json, false);
}
function CartDeliveryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'shippingMethod': (0, ShippingMethod_1.ShippingMethodFromJSON)(json['shippingMethod']),
        'shippingCosts': (0, ShippingCosts_1.ShippingCostsFromJSON)(json['shippingCosts']),
        'shippingOrderAddress': !(0, runtime_1.exists)(json, 'shippingOrderAddress') ? undefined : (0, OrderAddress_1.OrderAddressFromJSON)(json['shippingOrderAddress']),
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : (0, CartDeliveryCustomFields_1.CartDeliveryCustomFieldsFromJSON)(json['customFields']),
    };
}
function CartDeliveryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'shippingMethod': (0, ShippingMethod_1.ShippingMethodToJSON)(value.shippingMethod),
        'shippingCosts': (0, ShippingCosts_1.ShippingCostsToJSON)(value.shippingCosts),
        'shippingOrderAddress': (0, OrderAddress_1.OrderAddressToJSON)(value.shippingOrderAddress),
        'customFields': (0, CartDeliveryCustomFields_1.CartDeliveryCustomFieldsToJSON)(value.customFields),
    };
}
