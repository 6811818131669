"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Vault - Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSettingModel = instanceOfSettingModel;
exports.SettingModelFromJSON = SettingModelFromJSON;
exports.SettingModelFromJSONTyped = SettingModelFromJSONTyped;
exports.SettingModelToJSON = SettingModelToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the SettingModel interface.
 */
function instanceOfSettingModel(value) {
    let isInstance = true;
    return isInstance;
}
function SettingModelFromJSON(json) {
    return SettingModelFromJSONTyped(json, false);
}
function SettingModelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'language': !(0, runtime_1.exists)(json, 'language') ? undefined : json['language'],
    };
}
function SettingModelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'language': value.language,
    };
}
