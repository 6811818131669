"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSalutationTranslated = instanceOfSalutationTranslated;
exports.SalutationTranslatedFromJSON = SalutationTranslatedFromJSON;
exports.SalutationTranslatedFromJSONTyped = SalutationTranslatedFromJSONTyped;
exports.SalutationTranslatedToJSON = SalutationTranslatedToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the SalutationTranslated interface.
 */
function instanceOfSalutationTranslated(value) {
    let isInstance = true;
    return isInstance;
}
function SalutationTranslatedFromJSON(json) {
    return SalutationTranslatedFromJSONTyped(json, false);
}
function SalutationTranslatedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayName': !(0, runtime_1.exists)(json, 'displayName') ? undefined : json['displayName'],
        'letterName': !(0, runtime_1.exists)(json, 'letterName') ? undefined : json['letterName'],
    };
}
function SalutationTranslatedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayName': value.displayName,
        'letterName': value.letterName,
    };
}
