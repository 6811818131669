"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LocalStorageKeys;
(function (LocalStorageKeys) {
    LocalStorageKeys["anonymousUserNoticeClicked"] = "anonymousUserNoticeClicked";
    LocalStorageKeys["cutterSort"] = "cutterSort";
    LocalStorageKeys["language"] = "ZndLanguage";
    LocalStorageKeys["softwareSort"] = "softwareSort";
    LocalStorageKeys["offerSort"] = "offerSort";
    LocalStorageKeys["ordersSort"] = "ordersSort";
    LocalStorageKeys["popoverLoggedInClicked"] = "popoverLoggedInClicked";
    LocalStorageKeys["addressesSort"] = "addressesSort";
})(LocalStorageKeys || (LocalStorageKeys = {}));
exports.default = LocalStorageKeys;
