"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Sulu - Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsTeaserItemTypeEnum = void 0;
exports.instanceOfNewsTeaserItem = instanceOfNewsTeaserItem;
exports.NewsTeaserItemFromJSON = NewsTeaserItemFromJSON;
exports.NewsTeaserItemFromJSONTyped = NewsTeaserItemFromJSONTyped;
exports.NewsTeaserItemToJSON = NewsTeaserItemToJSON;
const runtime_1 = require("../runtime");
/**
 * @export
 */
exports.NewsTeaserItemTypeEnum = {
    Article: 'article',
    SocialMedia: 'social-media'
};
/**
 * Check if a given object implements the NewsTeaserItem interface.
 */
function instanceOfNewsTeaserItem(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "url" in value;
    return isInstance;
}
function NewsTeaserItemFromJSON(json) {
    return NewsTeaserItemFromJSONTyped(json, false);
}
function NewsTeaserItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': json['type'],
        'title': json['title'],
        'shortText': !(0, runtime_1.exists)(json, 'shortText') ? undefined : json['shortText'],
        'date': (new Date(json['date'])),
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : json['image'],
        'url': json['url'],
    };
}
function NewsTeaserItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'title': value.title,
        'shortText': value.shortText,
        'date': (value.date.toISOString().substr(0, 10)),
        'image': value.image,
        'url': value.url,
    };
}
