"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfGeneralFailureErrorResponse = instanceOfGeneralFailureErrorResponse;
exports.GeneralFailureErrorResponseFromJSON = GeneralFailureErrorResponseFromJSON;
exports.GeneralFailureErrorResponseFromJSONTyped = GeneralFailureErrorResponseFromJSONTyped;
exports.GeneralFailureErrorResponseToJSON = GeneralFailureErrorResponseToJSON;
const runtime_1 = require("../runtime");
const GeneralFailureError_1 = require("./GeneralFailureError");
/**
 * Check if a given object implements the GeneralFailureErrorResponse interface.
 */
function instanceOfGeneralFailureErrorResponse(value) {
    let isInstance = true;
    return isInstance;
}
function GeneralFailureErrorResponseFromJSON(json) {
    return GeneralFailureErrorResponseFromJSONTyped(json, false);
}
function GeneralFailureErrorResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errors': !(0, runtime_1.exists)(json, 'errors') ? undefined : (json['errors'] === null ? null : json['errors'].map(GeneralFailureError_1.GeneralFailureErrorFromJSON)),
    };
}
function GeneralFailureErrorResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'errors': value.errors === undefined ? undefined : (value.errors === null ? null : value.errors.map(GeneralFailureError_1.GeneralFailureErrorToJSON)),
    };
}
