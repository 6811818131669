"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAccountRegisterPersonal = instanceOfAccountRegisterPersonal;
exports.AccountRegisterPersonalFromJSON = AccountRegisterPersonalFromJSON;
exports.AccountRegisterPersonalFromJSONTyped = AccountRegisterPersonalFromJSONTyped;
exports.AccountRegisterPersonalToJSON = AccountRegisterPersonalToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the AccountRegisterPersonal interface.
 */
function instanceOfAccountRegisterPersonal(value) {
    let isInstance = true;
    isInstance = isInstance && "salutation" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    return isInstance;
}
function AccountRegisterPersonalFromJSON(json) {
    return AccountRegisterPersonalFromJSONTyped(json, false);
}
function AccountRegisterPersonalFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'salutation': json['salutation'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'role': !(0, runtime_1.exists)(json, 'role') ? undefined : json['role'],
        'department': !(0, runtime_1.exists)(json, 'department') ? undefined : json['department'],
        'email': json['email'],
        'phone': !(0, runtime_1.exists)(json, 'phone') ? undefined : json['phone'],
        'countryCode': !(0, runtime_1.exists)(json, 'countryCode') ? undefined : json['countryCode'],
        'countryStateCode': !(0, runtime_1.exists)(json, 'countryStateCode') ? undefined : json['countryStateCode'],
    };
}
function AccountRegisterPersonalToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'salutation': value.salutation,
        'title': value.title,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'role': value.role,
        'department': value.department,
        'email': value.email,
        'phone': value.phone,
        'countryCode': value.countryCode,
        'countryStateCode': value.countryStateCode,
    };
}
