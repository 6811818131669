"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfShippingMethod = instanceOfShippingMethod;
exports.ShippingMethodFromJSON = ShippingMethodFromJSON;
exports.ShippingMethodFromJSONTyped = ShippingMethodFromJSONTyped;
exports.ShippingMethodToJSON = ShippingMethodToJSON;
const runtime_1 = require("../runtime");
const Media_1 = require("./Media");
const PaymentMethodTranslation_1 = require("./PaymentMethodTranslation");
const ShippingMethodExtensions_1 = require("./ShippingMethodExtensions");
/**
 * Check if a given object implements the ShippingMethod interface.
 */
function instanceOfShippingMethod(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
function ShippingMethodFromJSON(json) {
    return ShippingMethodFromJSONTyped(json, false);
}
function ShippingMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'afterOrderEnabled': !(0, runtime_1.exists)(json, 'afterOrderEnabled') ? undefined : json['afterOrderEnabled'],
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : json['customFields'],
        'mediaId': !(0, runtime_1.exists)(json, 'mediaId') ? undefined : json['mediaId'],
        'position': !(0, runtime_1.exists)(json, 'position') ? undefined : json['position'],
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, PaymentMethodTranslation_1.PaymentMethodTranslationFromJSON)(json['translated']),
        'media': !(0, runtime_1.exists)(json, 'media') ? undefined : (0, Media_1.MediaFromJSON)(json['media']),
        'extensions': !(0, runtime_1.exists)(json, 'extensions') ? undefined : (0, ShippingMethodExtensions_1.ShippingMethodExtensionsFromJSON)(json['extensions']),
    };
}
function ShippingMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'active': value.active,
        'afterOrderEnabled': value.afterOrderEnabled,
        'customFields': value.customFields,
        'mediaId': value.mediaId,
        'position': value.position,
        'translated': (0, PaymentMethodTranslation_1.PaymentMethodTranslationToJSON)(value.translated),
        'media': (0, Media_1.MediaToJSON)(value.media),
        'extensions': (0, ShippingMethodExtensions_1.ShippingMethodExtensionsToJSON)(value.extensions),
    };
}
