"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCustomer = instanceOfCustomer;
exports.CustomerFromJSON = CustomerFromJSON;
exports.CustomerFromJSONTyped = CustomerFromJSONTyped;
exports.CustomerToJSON = CustomerToJSON;
const runtime_1 = require("../runtime");
const CustomerExtensions_1 = require("./CustomerExtensions");
const CustomerPropertiesCustomFields_1 = require("./CustomerPropertiesCustomFields");
const Salutation_1 = require("./Salutation");
/**
 * Check if a given object implements the Customer interface.
 */
function instanceOfCustomer(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "defaultPaymentMethodId" in value;
    isInstance = isInstance && "salesChannelId" in value;
    isInstance = isInstance && "languageId" in value;
    isInstance = isInstance && "defaultBillingAddressId" in value;
    isInstance = isInstance && "defaultShippingAddressId" in value;
    isInstance = isInstance && "customerNumber" in value;
    isInstance = isInstance && "salutationId" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    return isInstance;
}
function CustomerFromJSON(json) {
    return CustomerFromJSONTyped(json, false);
}
function CustomerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'groupId': json['groupId'],
        'defaultPaymentMethodId': json['defaultPaymentMethodId'],
        'salutation': !(0, runtime_1.exists)(json, 'salutation') ? undefined : (0, Salutation_1.SalutationFromJSON)(json['salutation']),
        'salesChannelId': json['salesChannelId'],
        'languageId': json['languageId'],
        'lastPaymentMethodId': !(0, runtime_1.exists)(json, 'lastPaymentMethodId') ? undefined : json['lastPaymentMethodId'],
        'defaultBillingAddressId': json['defaultBillingAddressId'],
        'defaultShippingAddressId': json['defaultShippingAddressId'],
        'autoIncrement': !(0, runtime_1.exists)(json, 'autoIncrement') ? undefined : json['autoIncrement'],
        'customerNumber': json['customerNumber'],
        'salutationId': json['salutationId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'company': !(0, runtime_1.exists)(json, 'company') ? undefined : json['company'],
        'email': json['email'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'affiliateCode': !(0, runtime_1.exists)(json, 'affiliateCode') ? undefined : json['affiliateCode'],
        'campaignCode': !(0, runtime_1.exists)(json, 'campaignCode') ? undefined : json['campaignCode'],
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'doubleOptInRegistration': !(0, runtime_1.exists)(json, 'doubleOptInRegistration') ? undefined : json['doubleOptInRegistration'],
        'doubleOptInEmailSentDate': !(0, runtime_1.exists)(json, 'doubleOptInEmailSentDate') ? undefined : (json['doubleOptInEmailSentDate'] === null ? null : new Date(json['doubleOptInEmailSentDate'])),
        'doubleOptInConfirmDate': !(0, runtime_1.exists)(json, 'doubleOptInConfirmDate') ? undefined : (json['doubleOptInConfirmDate'] === null ? null : new Date(json['doubleOptInConfirmDate'])),
        'hash': !(0, runtime_1.exists)(json, 'hash') ? undefined : json['hash'],
        'guest': !(0, runtime_1.exists)(json, 'guest') ? undefined : json['guest'],
        'firstLogin': !(0, runtime_1.exists)(json, 'firstLogin') ? undefined : (json['firstLogin'] === null ? null : new Date(json['firstLogin'])),
        'lastLogin': !(0, runtime_1.exists)(json, 'lastLogin') ? undefined : (json['lastLogin'] === null ? null : new Date(json['lastLogin'])),
        'newsletter': !(0, runtime_1.exists)(json, 'newsletter') ? undefined : json['newsletter'],
        'birthday': !(0, runtime_1.exists)(json, 'birthday') ? undefined : (json['birthday'] === null ? null : new Date(json['birthday'])),
        'lastOrderDate': !(0, runtime_1.exists)(json, 'lastOrderDate') ? undefined : (json['lastOrderDate'] === null ? null : new Date(json['lastOrderDate'])),
        'orderCount': !(0, runtime_1.exists)(json, 'orderCount') ? undefined : json['orderCount'],
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : (0, CustomerPropertiesCustomFields_1.CustomerPropertiesCustomFieldsFromJSON)(json['customFields']),
        'remoteAddress': !(0, runtime_1.exists)(json, 'remoteAddress') ? undefined : json['remoteAddress'],
        'extensions': !(0, runtime_1.exists)(json, 'extensions') ? undefined : (0, CustomerExtensions_1.CustomerExtensionsFromJSON)(json['extensions']),
    };
}
function CustomerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'groupId': value.groupId,
        'defaultPaymentMethodId': value.defaultPaymentMethodId,
        'salutation': (0, Salutation_1.SalutationToJSON)(value.salutation),
        'salesChannelId': value.salesChannelId,
        'languageId': value.languageId,
        'lastPaymentMethodId': value.lastPaymentMethodId,
        'defaultBillingAddressId': value.defaultBillingAddressId,
        'defaultShippingAddressId': value.defaultShippingAddressId,
        'customerNumber': value.customerNumber,
        'salutationId': value.salutationId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'company': value.company,
        'email': value.email,
        'title': value.title,
        'affiliateCode': value.affiliateCode,
        'campaignCode': value.campaignCode,
        'active': value.active,
        'doubleOptInRegistration': value.doubleOptInRegistration,
        'doubleOptInEmailSentDate': value.doubleOptInEmailSentDate === undefined ? undefined : (value.doubleOptInEmailSentDate === null ? null : value.doubleOptInEmailSentDate.toISOString()),
        'doubleOptInConfirmDate': value.doubleOptInConfirmDate === undefined ? undefined : (value.doubleOptInConfirmDate === null ? null : value.doubleOptInConfirmDate.toISOString()),
        'hash': value.hash,
        'guest': value.guest,
        'firstLogin': value.firstLogin === undefined ? undefined : (value.firstLogin === null ? null : value.firstLogin.toISOString()),
        'lastLogin': value.lastLogin === undefined ? undefined : (value.lastLogin === null ? null : value.lastLogin.toISOString()),
        'newsletter': value.newsletter,
        'birthday': value.birthday === undefined ? undefined : (value.birthday === null ? null : value.birthday.toISOString()),
        'customFields': (0, CustomerPropertiesCustomFields_1.CustomerPropertiesCustomFieldsToJSON)(value.customFields),
        'remoteAddress': value.remoteAddress,
        'extensions': (0, CustomerExtensions_1.CustomerExtensionsToJSON)(value.extensions),
    };
}
