"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCmsPageConfig = instanceOfCmsPageConfig;
exports.CmsPageConfigFromJSON = CmsPageConfigFromJSON;
exports.CmsPageConfigFromJSONTyped = CmsPageConfigFromJSONTyped;
exports.CmsPageConfigToJSON = CmsPageConfigToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CmsPageConfig interface.
 */
function instanceOfCmsPageConfig(value) {
    let isInstance = true;
    return isInstance;
}
function CmsPageConfigFromJSON(json) {
    return CmsPageConfigFromJSONTyped(json, false);
}
function CmsPageConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'backgroundColor': !(0, runtime_1.exists)(json, 'backgroundColor') ? undefined : json['backgroundColor'],
    };
}
function CmsPageConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'backgroundColor': value.backgroundColor,
    };
}
