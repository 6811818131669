"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCustomerGroup = instanceOfCustomerGroup;
exports.CustomerGroupFromJSON = CustomerGroupFromJSON;
exports.CustomerGroupFromJSONTyped = CustomerGroupFromJSONTyped;
exports.CustomerGroupToJSON = CustomerGroupToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CustomerGroup interface.
 */
function instanceOfCustomerGroup(value) {
    let isInstance = true;
    return isInstance;
}
function CustomerGroupFromJSON(json) {
    return CustomerGroupFromJSONTyped(json, false);
}
function CustomerGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'displayGross': !(0, runtime_1.exists)(json, 'displayGross') ? undefined : json['displayGross'],
        'registrationActive': !(0, runtime_1.exists)(json, 'registrationActive') ? undefined : json['registrationActive'],
    };
}
function CustomerGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'name': value.name,
        'displayGross': value.displayGross,
        'registrationActive': value.registrationActive,
    };
}
