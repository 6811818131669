"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSalesChannelContext = instanceOfSalesChannelContext;
exports.SalesChannelContextFromJSON = SalesChannelContextFromJSON;
exports.SalesChannelContextFromJSONTyped = SalesChannelContextFromJSONTyped;
exports.SalesChannelContextToJSON = SalesChannelContextToJSON;
const runtime_1 = require("../runtime");
const Currency_1 = require("./Currency");
const Customer_1 = require("./Customer");
const CustomerGroup_1 = require("./CustomerGroup");
const PaymentMethod_1 = require("./PaymentMethod");
const SalesChannel_1 = require("./SalesChannel");
const SalesChannelContextExtensions_1 = require("./SalesChannelContextExtensions");
const ShippingMethod_1 = require("./ShippingMethod");
/**
 * Check if a given object implements the SalesChannelContext interface.
 */
function instanceOfSalesChannelContext(value) {
    let isInstance = true;
    return isInstance;
}
function SalesChannelContextFromJSON(json) {
    return SalesChannelContextFromJSONTyped(json, false);
}
function SalesChannelContextFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'token': !(0, runtime_1.exists)(json, 'token') ? undefined : json['token'],
        'currentCustomerGroup': !(0, runtime_1.exists)(json, 'currentCustomerGroup') ? undefined : (0, CustomerGroup_1.CustomerGroupFromJSON)(json['currentCustomerGroup']),
        'fallbackCustomerGroup': !(0, runtime_1.exists)(json, 'fallbackCustomerGroup') ? undefined : (0, CustomerGroup_1.CustomerGroupFromJSON)(json['fallbackCustomerGroup']),
        'currency': !(0, runtime_1.exists)(json, 'currency') ? undefined : (0, Currency_1.CurrencyFromJSON)(json['currency']),
        'salesChannel': !(0, runtime_1.exists)(json, 'salesChannel') ? undefined : (0, SalesChannel_1.SalesChannelFromJSON)(json['salesChannel']),
        'customer': !(0, runtime_1.exists)(json, 'customer') ? undefined : (0, Customer_1.CustomerFromJSON)(json['customer']),
        'paymentMethod': !(0, runtime_1.exists)(json, 'paymentMethod') ? undefined : (0, PaymentMethod_1.PaymentMethodFromJSON)(json['paymentMethod']),
        'shippingMethod': !(0, runtime_1.exists)(json, 'shippingMethod') ? undefined : (0, ShippingMethod_1.ShippingMethodFromJSON)(json['shippingMethod']),
        'extensions': !(0, runtime_1.exists)(json, 'extensions') ? undefined : (0, SalesChannelContextExtensions_1.SalesChannelContextExtensionsFromJSON)(json['extensions']),
    };
}
function SalesChannelContextToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token': value.token,
        'currentCustomerGroup': (0, CustomerGroup_1.CustomerGroupToJSON)(value.currentCustomerGroup),
        'fallbackCustomerGroup': (0, CustomerGroup_1.CustomerGroupToJSON)(value.fallbackCustomerGroup),
        'currency': (0, Currency_1.CurrencyToJSON)(value.currency),
        'salesChannel': (0, SalesChannel_1.SalesChannelToJSON)(value.salesChannel),
        'customer': (0, Customer_1.CustomerToJSON)(value.customer),
        'paymentMethod': (0, PaymentMethod_1.PaymentMethodToJSON)(value.paymentMethod),
        'shippingMethod': (0, ShippingMethod_1.ShippingMethodToJSON)(value.shippingMethod),
        'extensions': (0, SalesChannelContextExtensions_1.SalesChannelContextExtensionsToJSON)(value.extensions),
    };
}
