"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Sulu - Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfResellerNavigationItem = instanceOfResellerNavigationItem;
exports.ResellerNavigationItemFromJSON = ResellerNavigationItemFromJSON;
exports.ResellerNavigationItemFromJSONTyped = ResellerNavigationItemFromJSONTyped;
exports.ResellerNavigationItemToJSON = ResellerNavigationItemToJSON;
/**
 * Check if a given object implements the ResellerNavigationItem interface.
 */
function instanceOfResellerNavigationItem(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "pathname" in value;
    isInstance = isInstance && "locale" in value;
    isInstance = isInstance && "children" in value;
    return isInstance;
}
function ResellerNavigationItemFromJSON(json) {
    return ResellerNavigationItemFromJSONTyped(json, false);
}
function ResellerNavigationItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'pathname': json['pathname'],
        'locale': json['locale'],
        'children': (json['children'].map(ResellerNavigationItemFromJSON)),
    };
}
function ResellerNavigationItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'pathname': value.pathname,
        'locale': value.locale,
        'children': (value.children.map(ResellerNavigationItemToJSON)),
    };
}
