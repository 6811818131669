"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShippingMethodType = void 0;
exports.ShippingMethodTypeFromJSON = ShippingMethodTypeFromJSON;
exports.ShippingMethodTypeFromJSONTyped = ShippingMethodTypeFromJSONTyped;
exports.ShippingMethodTypeToJSON = ShippingMethodTypeToJSON;
/**
 *
 * @export
 */
exports.ShippingMethodType = {
    Ups: 'UPS',
    FedEx: 'FedEx'
};
function ShippingMethodTypeFromJSON(json) {
    return ShippingMethodTypeFromJSONTyped(json, false);
}
function ShippingMethodTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ShippingMethodTypeToJSON(value) {
    return value;
}
