"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PATH_SHOP_PRODUCTS_SEARCH = exports.PATH_SHOP_PRODUCTS_SLUG = exports.PATH_SHOP_PRODUCTS = exports.PATH_SHOP_PRODUCT = exports.PATH_SOFTWARE_DETAIL = exports.PATH_SOFTWARE = exports.PATH_ASSETS_OFFER_EXPANDED = exports.PATH_ASSETS_OFFER = exports.PATH_ASSETS_SOFTWARE_EXPANDED = exports.PATH_ASSETS_SOFTWARE = exports.PATH_ASSETS_CUTTER_EXPANDED = exports.PATH_ASSETS_CUTTER = exports.PATH_ASSETS = exports.PATH_USER_PROFILE_ADDRESSES = exports.PATH_USER_PROFILE_ADMINISTRATION = exports.PATH_USER_PROFILE_ORDERS_EXPANDED = exports.PATH_USER_PROFILE_ORDERS = exports.PATH_USER_PROFILE_CONTACTS = exports.PATH_USER_PROFILE_ACCOUNT = exports.PATH_USER_PROFILE = exports.PATH_SHOP_CHECKOUT_PAY_ERROR = exports.PATH_SHOP_CHECKOUT_PAY = exports.PATH_SHOP_CHECKOUT_SUMMARY = exports.PATH_SHOP_CHECKOUT_SHIPPING = exports.PATH_SHOP_CHECKOUT_PAYMENT = exports.PATH_SHOP_CHECKOUT_CONFIRMATION = exports.PATH_SHOP_CHECKOUT = exports.PATH_SHOP_CART = exports.PATH_SHOP = exports.PATH_RESELLER_FILES = exports.PATH_RESELLER = exports.PATH_REGISTRATION_SUCCESS = exports.PATH_REGISTRATION_VERIFICATION_CODE = exports.PATH_REGISTRATION_SIGN_UP = exports.PATH_REGISTRATION_VERIFY_COMPANY = exports.PATH_REGISTRATION_PERSONAL_INFORMATION = exports.PATH_REGISTRATION_COMPANY_INFORMATION = exports.PATH_REGISTRATION = exports.PATH_MY_DOCUMENTS = exports.PATH_IOT = exports.PATH_DASHBOARD = exports.PATH_LOGIN = exports.PATH_HOME = void 0;
exports.PATH_HOME = '/:locale';
exports.PATH_LOGIN = '/:locale/login';
exports.PATH_DASHBOARD = '/:locale/dashboard';
exports.PATH_IOT = '/:locale/connect';
exports.PATH_MY_DOCUMENTS = '/:locale/mydocuments';
exports.PATH_REGISTRATION = '/:locale/registration';
exports.PATH_REGISTRATION_COMPANY_INFORMATION = '/:locale/registration/company-information';
exports.PATH_REGISTRATION_PERSONAL_INFORMATION = '/:locale/registration/personal-information';
exports.PATH_REGISTRATION_VERIFY_COMPANY = '/:locale/registration/verify-company';
exports.PATH_REGISTRATION_SIGN_UP = '/:locale/registration/sign-up';
exports.PATH_REGISTRATION_VERIFICATION_CODE = '/:locale/registration/verification-code';
exports.PATH_REGISTRATION_SUCCESS = '/:locale/registration/success';
exports.PATH_RESELLER = '/:locale/reseller';
exports.PATH_RESELLER_FILES = '/:locale/reseller/files';
exports.PATH_SHOP = '/:locale/shop';
exports.PATH_SHOP_CART = '/:locale/shop/cart';
exports.PATH_SHOP_CHECKOUT = '/:locale/shop/checkout';
exports.PATH_SHOP_CHECKOUT_CONFIRMATION = '/:locale/shop/checkout/confirmation/:orderId';
exports.PATH_SHOP_CHECKOUT_PAYMENT = '/:locale/shop/checkout/payment';
exports.PATH_SHOP_CHECKOUT_SHIPPING = '/:locale/shop/checkout/shipping';
exports.PATH_SHOP_CHECKOUT_SUMMARY = '/:locale/shop/checkout/summary';
exports.PATH_SHOP_CHECKOUT_PAY = '/:locale/shop/checkout/pay/:orderId';
exports.PATH_SHOP_CHECKOUT_PAY_ERROR = '/:locale/shop/checkout/pay/:orderId/error';
exports.PATH_USER_PROFILE = '/:locale/user/profile';
exports.PATH_USER_PROFILE_ACCOUNT = '/:locale/user/profile/account';
exports.PATH_USER_PROFILE_CONTACTS = '/:locale/user/profile/contacts';
exports.PATH_USER_PROFILE_ORDERS = '/:locale/user/profile/orders';
exports.PATH_USER_PROFILE_ORDERS_EXPANDED = '/:locale/user/profile/orders/:orderId';
exports.PATH_USER_PROFILE_ADMINISTRATION = '/:locale/user/profile/administration';
exports.PATH_USER_PROFILE_ADDRESSES = '/:locale/user/profile/addresses';
exports.PATH_ASSETS = '/:locale/myassets';
exports.PATH_ASSETS_CUTTER = '/:locale/myassets/cutter';
exports.PATH_ASSETS_CUTTER_EXPANDED = '/:locale/myassets/cutter/:cutterId';
exports.PATH_ASSETS_SOFTWARE = '/:locale/myassets/software';
exports.PATH_ASSETS_SOFTWARE_EXPANDED = '/:locale/myassets/software/:softwareId';
exports.PATH_ASSETS_OFFER = '/:locale/myassets/pending-software-invoices';
exports.PATH_ASSETS_OFFER_EXPANDED = '/:locale/myassets/pending-software-invoices/:offerId';
exports.PATH_SOFTWARE = '/:locale/software';
exports.PATH_SOFTWARE_DETAIL = '/:locale/software/:product';
// WARNING: Changing this route requires a change in the BE Route definition.
exports.PATH_SHOP_PRODUCT = '/:locale/shop/product/:product';
// WARNING: Changing this route requires a change in the BE Route definition.
exports.PATH_SHOP_PRODUCTS = '/:locale/shop/:parentCategories*/:category';
exports.PATH_SHOP_PRODUCTS_SLUG = '/:locale/shop/:slug';
exports.PATH_SHOP_PRODUCTS_SEARCH = '/:locale/shop/products/:searchTerm';
