"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MassiveCategoryDisplayedPropertyDisplayTypeEnum = void 0;
exports.instanceOfMassiveCategoryDisplayedProperty = instanceOfMassiveCategoryDisplayedProperty;
exports.MassiveCategoryDisplayedPropertyFromJSON = MassiveCategoryDisplayedPropertyFromJSON;
exports.MassiveCategoryDisplayedPropertyFromJSONTyped = MassiveCategoryDisplayedPropertyFromJSONTyped;
exports.MassiveCategoryDisplayedPropertyToJSON = MassiveCategoryDisplayedPropertyToJSON;
const runtime_1 = require("../runtime");
/**
 * @export
 */
exports.MassiveCategoryDisplayedPropertyDisplayTypeEnum = {
    Value: 'value',
    Full: 'full'
};
/**
 * Check if a given object implements the MassiveCategoryDisplayedProperty interface.
 */
function instanceOfMassiveCategoryDisplayedProperty(value) {
    let isInstance = true;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "displayType" in value;
    isInstance = isInstance && "position" in value;
    return isInstance;
}
function MassiveCategoryDisplayedPropertyFromJSON(json) {
    return MassiveCategoryDisplayedPropertyFromJSONTyped(json, false);
}
function MassiveCategoryDisplayedPropertyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'propertyId': json['propertyId'],
        'displayType': json['displayType'],
        'delimiterString': !(0, runtime_1.exists)(json, 'delimiterString') ? undefined : json['delimiterString'],
        'position': json['position'],
    };
}
function MassiveCategoryDisplayedPropertyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'propertyId': value.propertyId,
        'displayType': value.displayType,
        'delimiterString': value.delimiterString,
        'position': value.position,
    };
}
