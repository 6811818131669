"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPaymentMethodTranslation = instanceOfPaymentMethodTranslation;
exports.PaymentMethodTranslationFromJSON = PaymentMethodTranslationFromJSON;
exports.PaymentMethodTranslationFromJSONTyped = PaymentMethodTranslationFromJSONTyped;
exports.PaymentMethodTranslationToJSON = PaymentMethodTranslationToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the PaymentMethodTranslation interface.
 */
function instanceOfPaymentMethodTranslation(value) {
    let isInstance = true;
    return isInstance;
}
function PaymentMethodTranslationFromJSON(json) {
    return PaymentMethodTranslationFromJSONTyped(json, false);
}
function PaymentMethodTranslationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
    };
}
function PaymentMethodTranslationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
    };
}
