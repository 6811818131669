"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfLineItemPrice = instanceOfLineItemPrice;
exports.LineItemPriceFromJSON = LineItemPriceFromJSON;
exports.LineItemPriceFromJSONTyped = LineItemPriceFromJSONTyped;
exports.LineItemPriceToJSON = LineItemPriceToJSON;
const runtime_1 = require("../runtime");
const CalculatedTax_1 = require("./CalculatedTax");
const CartTaxRule_1 = require("./CartTaxRule");
const ListPrice_1 = require("./ListPrice");
/**
 * Check if a given object implements the LineItemPrice interface.
 */
function instanceOfLineItemPrice(value) {
    let isInstance = true;
    isInstance = isInstance && "unitPrice" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "totalPrice" in value;
    isInstance = isInstance && "calculatedTaxes" in value;
    isInstance = isInstance && "taxRules" in value;
    return isInstance;
}
function LineItemPriceFromJSON(json) {
    return LineItemPriceFromJSONTyped(json, false);
}
function LineItemPriceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'unitPrice': json['unitPrice'],
        'quantity': json['quantity'],
        'totalPrice': json['totalPrice'],
        'listPrice': !(0, runtime_1.exists)(json, 'listPrice') ? undefined : (0, ListPrice_1.ListPriceFromJSON)(json['listPrice']),
        'calculatedTaxes': (json['calculatedTaxes'].map(CalculatedTax_1.CalculatedTaxFromJSON)),
        'taxRules': (json['taxRules'].map(CartTaxRule_1.CartTaxRuleFromJSON)),
    };
}
function LineItemPriceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'unitPrice': value.unitPrice,
        'quantity': value.quantity,
        'totalPrice': value.totalPrice,
        'listPrice': (0, ListPrice_1.ListPriceToJSON)(value.listPrice),
        'calculatedTaxes': (value.calculatedTaxes.map(CalculatedTax_1.CalculatedTaxToJSON)),
        'taxRules': (value.taxRules.map(CartTaxRule_1.CartTaxRuleToJSON)),
    };
}
