"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfProductList = instanceOfProductList;
exports.ProductListFromJSON = ProductListFromJSON;
exports.ProductListFromJSONTyped = ProductListFromJSONTyped;
exports.ProductListToJSON = ProductListToJSON;
const runtime_1 = require("../runtime");
const Product_1 = require("./Product");
/**
 * Check if a given object implements the ProductList interface.
 */
function instanceOfProductList(value) {
    let isInstance = true;
    return isInstance;
}
function ProductListFromJSON(json) {
    return ProductListFromJSONTyped(json, false);
}
function ProductListFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sorting': !(0, runtime_1.exists)(json, 'sorting') ? undefined : json['sorting'],
        'currentFilters': !(0, runtime_1.exists)(json, 'currentFilters') ? undefined : json['currentFilters'],
        'page': !(0, runtime_1.exists)(json, 'page') ? undefined : json['page'],
        'limit': !(0, runtime_1.exists)(json, 'limit') ? undefined : json['limit'],
        'sortings': !(0, runtime_1.exists)(json, 'sortings') ? undefined : json['sortings'],
        'total': !(0, runtime_1.exists)(json, 'total') ? undefined : json['total'],
        'elements': !(0, runtime_1.exists)(json, 'elements') ? undefined : (json['elements'].map(Product_1.ProductFromJSON)),
    };
}
function ProductListToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sorting': value.sorting,
        'currentFilters': value.currentFilters,
        'page': value.page,
        'limit': value.limit,
        'sortings': value.sortings,
        'total': value.total,
        'elements': value.elements === undefined ? undefined : (value.elements.map(Product_1.ProductToJSON)),
    };
}
