"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertResellerStructureToTreeNodeList = void 0;
const convertResellerStructureToTreeNodeList = (data, parentId = null) => data.reduce((treeStructure, item) => {
    const children = item.children ?? [];
    const childNodes = children.filter((child) => (child.children?.length ?? 0) > 0);
    const childLeafs = children.filter((child) => (child.children?.length ?? 0) === 0);
    return [
        ...treeStructure,
        {
            id: item.path,
            label: item.name,
            parentId,
            items: (0, exports.convertResellerStructureToTreeNodeList)(childLeafs, item.path),
        },
        ...(0, exports.convertResellerStructureToTreeNodeList)(childNodes ?? [], item.path),
    ];
}, []);
exports.convertResellerStructureToTreeNodeList = convertResellerStructureToTreeNodeList;
