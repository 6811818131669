"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTranslationCode = instanceOfTranslationCode;
exports.TranslationCodeFromJSON = TranslationCodeFromJSON;
exports.TranslationCodeFromJSONTyped = TranslationCodeFromJSONTyped;
exports.TranslationCodeToJSON = TranslationCodeToJSON;
const runtime_1 = require("../runtime");
const TranslationCodeTranslated_1 = require("./TranslationCodeTranslated");
/**
 * Check if a given object implements the TranslationCode interface.
 */
function instanceOfTranslationCode(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
function TranslationCodeFromJSON(json) {
    return TranslationCodeFromJSONTyped(json, false);
}
function TranslationCodeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'code': json['code'],
        'name': json['name'],
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, TranslationCodeTranslated_1.TranslationCodeTranslatedFromJSON)(json['translated']),
    };
}
function TranslationCodeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'code': value.code,
        'name': value.name,
        'translated': (0, TranslationCodeTranslated_1.TranslationCodeTranslatedToJSON)(value.translated),
    };
}
