"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAdyenPaymentStatus = instanceOfAdyenPaymentStatus;
exports.AdyenPaymentStatusFromJSON = AdyenPaymentStatusFromJSON;
exports.AdyenPaymentStatusFromJSONTyped = AdyenPaymentStatusFromJSONTyped;
exports.AdyenPaymentStatusToJSON = AdyenPaymentStatusToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the AdyenPaymentStatus interface.
 */
function instanceOfAdyenPaymentStatus(value) {
    let isInstance = true;
    return isInstance;
}
function AdyenPaymentStatusFromJSON(json) {
    return AdyenPaymentStatusFromJSONTyped(json, false);
}
function AdyenPaymentStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isFinal': !(0, runtime_1.exists)(json, 'isFinal') ? undefined : json['isFinal'],
        'resultCode': !(0, runtime_1.exists)(json, 'resultCode') ? undefined : json['resultCode'],
        'action': !(0, runtime_1.exists)(json, 'action') ? undefined : json['action'],
        'additionalData': !(0, runtime_1.exists)(json, 'additionalData') ? undefined : json['additionalData'],
        'refusalReasonCode': !(0, runtime_1.exists)(json, 'refusalReasonCode') ? undefined : json['refusalReasonCode'],
    };
}
function AdyenPaymentStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isFinal': value.isFinal,
        'resultCode': value.resultCode,
        'action': value.action,
        'additionalData': value.additionalData,
        'refusalReasonCode': value.refusalReasonCode,
    };
}
