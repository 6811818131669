"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productHasBulkPrices = exports.unitPriceFromProduct = exports.formattedPriceFromProduct = exports.lowestPriceFromProduct = exports.priceFromProduct = exports.moneyFormat = void 0;
const moneyFormat = (amount, currency) => {
    const formatter = new Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency: currency,
    });
    return formatter.format(amount);
};
exports.moneyFormat = moneyFormat;
const priceFromProduct = (product, quantity = 1) => {
    const sortedPrices = product.calculatedPrices?.slice().sort((a, b) => (a.quantity ?? 0) - (b.quantity ?? 0)) ?? [];
    // TODO: 'calculatedPrice' is almost always wrong, we have to get rid of it.
    const prices = sortedPrices.length > 0 ? sortedPrices : [product.calculatedPrice];
    const calculatedPrice = prices.find((price, index, innerPrices) => {
        const lastIndex = innerPrices.length - 1;
        if (!price) {
            return false;
        }
        // `quantity` is always the upper limit except when there is no upper limit. Then it's the lower limit.
        if (index < lastIndex) {
            return quantity <= (price.quantity ?? 0);
        }
        else {
            return quantity >= (price.quantity ?? 0);
        }
    });
    return calculatedPrice?.unitPrice ?? 0;
};
exports.priceFromProduct = priceFromProduct;
const lowestPriceFromProduct = (product) => product.calculatedListingPrice?.from.unitPrice ?? (0, exports.priceFromProduct)(product);
exports.lowestPriceFromProduct = lowestPriceFromProduct;
const formattedPriceFromProduct = (product, options = {}) => {
    const { minQuantity = 1, quantity = 1, useFromPrice, additionalUnitPrice = 0, isIndividualPrice = true, multiplyBy = 1, } = options;
    const currency = product.extensions?.zkpProductData?.currencyIsoCode;
    const unitPrice = (0, exports.unitPriceFromProduct)(product, { quantity, useFromPrice, isIndividualPrice });
    const price = (unitPrice + additionalUnitPrice) * multiplyBy;
    if (!!currency) {
        const formatted = (0, exports.moneyFormat)(price, currency);
        return minQuantity > 1 ? `${minQuantity} x ${formatted}` : formatted;
    }
    return minQuantity > 1 ? `${minQuantity} x ${price}` : `${price}`;
};
exports.formattedPriceFromProduct = formattedPriceFromProduct;
const unitPriceFromProduct = (product, options = {}) => {
    const { quantity = 1, useFromPrice, isIndividualPrice = true } = options;
    if (!isIndividualPrice) {
        // TODO: 'calculatedPrice' is almost always wrong, we have to get rid of it.
        return product.calculatedPrice?.listPrice?.price ?? 0;
    }
    return useFromPrice ? (0, exports.lowestPriceFromProduct)(product) : (0, exports.priceFromProduct)(product, quantity);
};
exports.unitPriceFromProduct = unitPriceFromProduct;
const productHasBulkPrices = (product) => (product.calculatedPrices?.length ?? 0) > 1;
exports.productHasBulkPrices = productHasBulkPrices;
