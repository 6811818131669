"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCartTaxRule = instanceOfCartTaxRule;
exports.CartTaxRuleFromJSON = CartTaxRuleFromJSON;
exports.CartTaxRuleFromJSONTyped = CartTaxRuleFromJSONTyped;
exports.CartTaxRuleToJSON = CartTaxRuleToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CartTaxRule interface.
 */
function instanceOfCartTaxRule(value) {
    let isInstance = true;
    isInstance = isInstance && "taxRate" in value;
    return isInstance;
}
function CartTaxRuleFromJSON(json) {
    return CartTaxRuleFromJSONTyped(json, false);
}
function CartTaxRuleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'taxRate': json['taxRate'],
        'percentage': !(0, runtime_1.exists)(json, 'percentage') ? undefined : json['percentage'],
    };
}
function CartTaxRuleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'taxRate': value.taxRate,
        'percentage': value.percentage,
    };
}
