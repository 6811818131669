"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfProductCalculatedListingPrice = instanceOfProductCalculatedListingPrice;
exports.ProductCalculatedListingPriceFromJSON = ProductCalculatedListingPriceFromJSON;
exports.ProductCalculatedListingPriceFromJSONTyped = ProductCalculatedListingPriceFromJSONTyped;
exports.ProductCalculatedListingPriceToJSON = ProductCalculatedListingPriceToJSON;
const ProductCalculatedListingPriceItem_1 = require("./ProductCalculatedListingPriceItem");
/**
 * Check if a given object implements the ProductCalculatedListingPrice interface.
 */
function instanceOfProductCalculatedListingPrice(value) {
    let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    return isInstance;
}
function ProductCalculatedListingPriceFromJSON(json) {
    return ProductCalculatedListingPriceFromJSONTyped(json, false);
}
function ProductCalculatedListingPriceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'from': (0, ProductCalculatedListingPriceItem_1.ProductCalculatedListingPriceItemFromJSON)(json['from']),
        'to': (0, ProductCalculatedListingPriceItem_1.ProductCalculatedListingPriceItemFromJSON)(json['to']),
    };
}
function ProductCalculatedListingPriceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'from': (0, ProductCalculatedListingPriceItem_1.ProductCalculatedListingPriceItemToJSON)(value.from),
        'to': (0, ProductCalculatedListingPriceItem_1.ProductCalculatedListingPriceItemToJSON)(value.to),
    };
}
