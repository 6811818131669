"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassiveShippingMethod = instanceOfMassiveShippingMethod;
exports.MassiveShippingMethodFromJSON = MassiveShippingMethodFromJSON;
exports.MassiveShippingMethodFromJSONTyped = MassiveShippingMethodFromJSONTyped;
exports.MassiveShippingMethodToJSON = MassiveShippingMethodToJSON;
const runtime_1 = require("../runtime");
const ShippingMethodType_1 = require("./ShippingMethodType");
/**
 * Check if a given object implements the MassiveShippingMethod interface.
 */
function instanceOfMassiveShippingMethod(value) {
    let isInstance = true;
    isInstance = isInstance && "shippingMethodId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "position" in value;
    return isInstance;
}
function MassiveShippingMethodFromJSON(json) {
    return MassiveShippingMethodFromJSONTyped(json, false);
}
function MassiveShippingMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'shippingMethodId': json['shippingMethodId'],
        'enableCustomerId': !(0, runtime_1.exists)(json, 'enableCustomerId') ? undefined : json['enableCustomerId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, ShippingMethodType_1.ShippingMethodTypeFromJSON)(json['type']),
        'position': json['position'],
    };
}
function MassiveShippingMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'shippingMethodId': value.shippingMethodId,
        'enableCustomerId': value.enableCustomerId,
        'type': (0, ShippingMethodType_1.ShippingMethodTypeToJSON)(value.type),
        'position': value.position,
    };
}
