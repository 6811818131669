"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfShippingMethodExtensions = instanceOfShippingMethodExtensions;
exports.ShippingMethodExtensionsFromJSON = ShippingMethodExtensionsFromJSON;
exports.ShippingMethodExtensionsFromJSONTyped = ShippingMethodExtensionsFromJSONTyped;
exports.ShippingMethodExtensionsToJSON = ShippingMethodExtensionsToJSON;
const runtime_1 = require("../runtime");
const MassiveShippingMethod_1 = require("./MassiveShippingMethod");
/**
 * Check if a given object implements the ShippingMethodExtensions interface.
 */
function instanceOfShippingMethodExtensions(value) {
    let isInstance = true;
    return isInstance;
}
function ShippingMethodExtensionsFromJSON(json) {
    return ShippingMethodExtensionsFromJSONTyped(json, false);
}
function ShippingMethodExtensionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'massiveShippingMethod': !(0, runtime_1.exists)(json, 'massiveShippingMethod') ? undefined : (0, MassiveShippingMethod_1.MassiveShippingMethodFromJSON)(json['massiveShippingMethod']),
    };
}
function ShippingMethodExtensionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'massiveShippingMethod': (0, MassiveShippingMethod_1.MassiveShippingMethodToJSON)(value.massiveShippingMethod),
    };
}
