"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSalesChannelExtensions = instanceOfSalesChannelExtensions;
exports.SalesChannelExtensionsFromJSON = SalesChannelExtensionsFromJSON;
exports.SalesChannelExtensionsFromJSONTyped = SalesChannelExtensionsFromJSONTyped;
exports.SalesChannelExtensionsToJSON = SalesChannelExtensionsToJSON;
const runtime_1 = require("../runtime");
const MassiveSalesChannel_1 = require("./MassiveSalesChannel");
/**
 * Check if a given object implements the SalesChannelExtensions interface.
 */
function instanceOfSalesChannelExtensions(value) {
    let isInstance = true;
    return isInstance;
}
function SalesChannelExtensionsFromJSON(json) {
    return SalesChannelExtensionsFromJSONTyped(json, false);
}
function SalesChannelExtensionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'massiveSalesChannel': !(0, runtime_1.exists)(json, 'massiveSalesChannel') ? undefined : (0, MassiveSalesChannel_1.MassiveSalesChannelFromJSON)(json['massiveSalesChannel']),
    };
}
function SalesChannelExtensionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'massiveSalesChannel': (0, MassiveSalesChannel_1.MassiveSalesChannelToJSON)(value.massiveSalesChannel),
    };
}
