"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSalesChannel = instanceOfSalesChannel;
exports.SalesChannelFromJSON = SalesChannelFromJSON;
exports.SalesChannelFromJSONTyped = SalesChannelFromJSONTyped;
exports.SalesChannelToJSON = SalesChannelToJSON;
const runtime_1 = require("../runtime");
const SalesChannelExtensions_1 = require("./SalesChannelExtensions");
const SalesChannelTranslated_1 = require("./SalesChannelTranslated");
/**
 * Check if a given object implements the SalesChannel interface.
 */
function instanceOfSalesChannel(value) {
    let isInstance = true;
    return isInstance;
}
function SalesChannelFromJSON(json) {
    return SalesChannelFromJSONTyped(json, false);
}
function SalesChannelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'typeId': !(0, runtime_1.exists)(json, 'typeId') ? undefined : json['typeId'],
        'languageId': !(0, runtime_1.exists)(json, 'languageId') ? undefined : json['languageId'],
        'currencyId': !(0, runtime_1.exists)(json, 'currencyId') ? undefined : json['currencyId'],
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, SalesChannelTranslated_1.SalesChannelTranslatedFromJSON)(json['translated']),
        'paymentMethodId': !(0, runtime_1.exists)(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'shippingMethodId': !(0, runtime_1.exists)(json, 'shippingMethodId') ? undefined : json['shippingMethodId'],
        'countryId': !(0, runtime_1.exists)(json, 'countryId') ? undefined : json['countryId'],
        'navigationCategoryId': !(0, runtime_1.exists)(json, 'navigationCategoryId') ? undefined : json['navigationCategoryId'],
        'navigationCategoryDepth': !(0, runtime_1.exists)(json, 'navigationCategoryDepth') ? undefined : json['navigationCategoryDepth'],
        'footerCategoryId': !(0, runtime_1.exists)(json, 'footerCategoryId') ? undefined : json['footerCategoryId'],
        'serviceCategoryId': !(0, runtime_1.exists)(json, 'serviceCategoryId') ? undefined : json['serviceCategoryId'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'shortName': !(0, runtime_1.exists)(json, 'shortName') ? undefined : json['shortName'],
        'accessKey': !(0, runtime_1.exists)(json, 'accessKey') ? undefined : json['accessKey'],
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'maintenance': !(0, runtime_1.exists)(json, 'maintenance') ? undefined : json['maintenance'],
        'maintenanceIpWhitelist': !(0, runtime_1.exists)(json, 'maintenanceIpWhitelist') ? undefined : json['maintenanceIpWhitelist'],
        'mailHeaderFooterId': !(0, runtime_1.exists)(json, 'mailHeaderFooterId') ? undefined : json['mailHeaderFooterId'],
        'customerGroupId': !(0, runtime_1.exists)(json, 'customerGroupId') ? undefined : json['customerGroupId'],
        'hreflangActive': !(0, runtime_1.exists)(json, 'hreflangActive') ? undefined : json['hreflangActive'],
        'hreflangDefaultDomainId': !(0, runtime_1.exists)(json, 'hreflangDefaultDomainId') ? undefined : json['hreflangDefaultDomainId'],
        'analyticsId': !(0, runtime_1.exists)(json, 'analyticsId') ? undefined : json['analyticsId'],
        'extensions': !(0, runtime_1.exists)(json, 'extensions') ? undefined : (0, SalesChannelExtensions_1.SalesChannelExtensionsFromJSON)(json['extensions']),
    };
}
function SalesChannelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'typeId': value.typeId,
        'languageId': value.languageId,
        'currencyId': value.currencyId,
        'translated': (0, SalesChannelTranslated_1.SalesChannelTranslatedToJSON)(value.translated),
        'paymentMethodId': value.paymentMethodId,
        'shippingMethodId': value.shippingMethodId,
        'countryId': value.countryId,
        'navigationCategoryId': value.navigationCategoryId,
        'navigationCategoryDepth': value.navigationCategoryDepth,
        'footerCategoryId': value.footerCategoryId,
        'serviceCategoryId': value.serviceCategoryId,
        'name': value.name,
        'shortName': value.shortName,
        'accessKey': value.accessKey,
        'active': value.active,
        'maintenance': value.maintenance,
        'maintenanceIpWhitelist': value.maintenanceIpWhitelist,
        'mailHeaderFooterId': value.mailHeaderFooterId,
        'customerGroupId': value.customerGroupId,
        'hreflangActive': value.hreflangActive,
        'hreflangDefaultDomainId': value.hreflangDefaultDomainId,
        'analyticsId': value.analyticsId,
        'extensions': (0, SalesChannelExtensions_1.SalesChannelExtensionsToJSON)(value.extensions),
    };
}
