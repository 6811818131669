"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderCustomFieldsMassiveOrderTypeEnum = void 0;
exports.instanceOfOrderCustomFields = instanceOfOrderCustomFields;
exports.OrderCustomFieldsFromJSON = OrderCustomFieldsFromJSON;
exports.OrderCustomFieldsFromJSONTyped = OrderCustomFieldsFromJSONTyped;
exports.OrderCustomFieldsToJSON = OrderCustomFieldsToJSON;
const runtime_1 = require("../runtime");
/**
 * @export
 */
exports.OrderCustomFieldsMassiveOrderTypeEnum = {
    Order: 'order',
    Inquiry: 'inquiry'
};
/**
 * Check if a given object implements the OrderCustomFields interface.
 */
function instanceOfOrderCustomFields(value) {
    let isInstance = true;
    return isInstance;
}
function OrderCustomFieldsFromJSON(json) {
    return OrderCustomFieldsFromJSONTyped(json, false);
}
function OrderCustomFieldsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'massiveOrderType': !(0, runtime_1.exists)(json, 'massiveOrderType') ? undefined : json['massiveOrderType'],
        'massivePurchaseOrderNumber': !(0, runtime_1.exists)(json, 'massivePurchaseOrderNumber') ? undefined : json['massivePurchaseOrderNumber'],
        'massiveIsAdvancePayment': !(0, runtime_1.exists)(json, 'massiveIsAdvancePayment') ? undefined : json['massiveIsAdvancePayment'],
        'zkpSoftwareOfferId': !(0, runtime_1.exists)(json, 'zkpSoftwareOfferId') ? undefined : json['zkpSoftwareOfferId'],
    };
}
function OrderCustomFieldsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'massiveOrderType': value.massiveOrderType,
        'massivePurchaseOrderNumber': value.massivePurchaseOrderNumber,
        'massiveIsAdvancePayment': value.massiveIsAdvancePayment,
        'zkpSoftwareOfferId': value.zkpSoftwareOfferId,
    };
}
