"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductSalesChannelId;
(function (ProductSalesChannelId) {
    ProductSalesChannelId["m1"] = "9e48e5f6f8794c7786b956d0c09dc594";
    ProductSalesChannelId["m21"] = "8d3b6bd5a8544c7b99b72e02a442e1ae";
    ProductSalesChannelId["m22"] = "d95bbdfecfea4c1a91e387a036093620";
    ProductSalesChannelId["m23"] = "7f85dd2df0df4327bfa0e115db1920f9";
    ProductSalesChannelId["m24"] = "f83cd7735c214d7098b604517a5fcb1d";
    ProductSalesChannelId["m25"] = "308edb89970842a39112bebf231d89da";
    ProductSalesChannelId["m26"] = "3ff301305959413390546dc23097d86c";
    ProductSalesChannelId["m27"] = "d67b77fa8335495290aa88b134a7d58b";
    ProductSalesChannelId["m28"] = "f64ba6f328c44f108f46ff4583a158db";
    ProductSalesChannelId["m31"] = "834e00eb8c2646ea9c9584af2acf5793";
    ProductSalesChannelId["m41"] = "83306d1d37e341cd83e813d0ce700fc6";
    ProductSalesChannelId["m42"] = "30342a14e8b1479eb7e2d4a42b2bfd00";
    ProductSalesChannelId["m43"] = "53be8a9fb710495f8b3af0b8ad30d792";
    ProductSalesChannelId["m97"] = "eb831632b3374a6692cf54db67e277ff";
    ProductSalesChannelId["m98"] = "45fda18fb1634ab3a508217fddcb3744";
    ProductSalesChannelId["m99"] = "dd6a0840daa941c188431536f57661af";
})(ProductSalesChannelId || (ProductSalesChannelId = {}));
exports.default = ProductSalesChannelId;
