"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleErrorResponse = void 0;
const ecommerce_1 = require("../../api/ecommerce");
const handleErrorResponse = (response) => {
    response = response instanceof ecommerce_1.ResponseError ? (response?.response ?? null) : response;
    if (!(response instanceof Response) || 400 !== response.status) {
        return Promise.reject();
    }
    return response.json().then((data) => {
        return (0, ecommerce_1.GeneralFailureErrorResponseFromJSON)(data);
    });
};
exports.handleErrorResponse = handleErrorResponse;
