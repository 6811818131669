"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSalesChannelContextPatchRequestBody = instanceOfSalesChannelContextPatchRequestBody;
exports.SalesChannelContextPatchRequestBodyFromJSON = SalesChannelContextPatchRequestBodyFromJSON;
exports.SalesChannelContextPatchRequestBodyFromJSONTyped = SalesChannelContextPatchRequestBodyFromJSONTyped;
exports.SalesChannelContextPatchRequestBodyToJSON = SalesChannelContextPatchRequestBodyToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the SalesChannelContextPatchRequestBody interface.
 */
function instanceOfSalesChannelContextPatchRequestBody(value) {
    let isInstance = true;
    return isInstance;
}
function SalesChannelContextPatchRequestBodyFromJSON(json) {
    return SalesChannelContextPatchRequestBodyFromJSONTyped(json, false);
}
function SalesChannelContextPatchRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currencyId': !(0, runtime_1.exists)(json, 'currencyId') ? undefined : json['currencyId'],
        'billingAddressId': !(0, runtime_1.exists)(json, 'billingAddressId') ? undefined : json['billingAddressId'],
        'shippingAddressId': !(0, runtime_1.exists)(json, 'shippingAddressId') ? undefined : json['shippingAddressId'],
        'paymentMethodId': !(0, runtime_1.exists)(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'shippingMethodId': !(0, runtime_1.exists)(json, 'shippingMethodId') ? undefined : json['shippingMethodId'],
        'countryId': !(0, runtime_1.exists)(json, 'countryId') ? undefined : json['countryId'],
        'countryStateId': !(0, runtime_1.exists)(json, 'countryStateId') ? undefined : json['countryStateId'],
        'massiveShippingMethodCustomerId': !(0, runtime_1.exists)(json, 'massiveShippingMethodCustomerId') ? undefined : json['massiveShippingMethodCustomerId'],
        'massiveResellerId': !(0, runtime_1.exists)(json, 'massiveResellerId') ? undefined : json['massiveResellerId'],
        'massivePurchaseOrderNumber': !(0, runtime_1.exists)(json, 'massivePurchaseOrderNumber') ? undefined : json['massivePurchaseOrderNumber'],
        'upsServiceCode': !(0, runtime_1.exists)(json, 'upsServiceCode') ? undefined : json['upsServiceCode'],
        'fedexServiceCode': !(0, runtime_1.exists)(json, 'fedexServiceCode') ? undefined : json['fedexServiceCode'],
        'massiveBillingAddressId': !(0, runtime_1.exists)(json, 'massiveBillingAddressId') ? undefined : json['massiveBillingAddressId'],
        'massiveShippingAddressId': !(0, runtime_1.exists)(json, 'massiveShippingAddressId') ? undefined : json['massiveShippingAddressId'],
        'zkpTaxId': !(0, runtime_1.exists)(json, 'zkpTaxId') ? undefined : json['zkpTaxId'],
    };
}
function SalesChannelContextPatchRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'currencyId': value.currencyId,
        'billingAddressId': value.billingAddressId,
        'shippingAddressId': value.shippingAddressId,
        'paymentMethodId': value.paymentMethodId,
        'shippingMethodId': value.shippingMethodId,
        'countryId': value.countryId,
        'countryStateId': value.countryStateId,
        'massiveShippingMethodCustomerId': value.massiveShippingMethodCustomerId,
        'massiveResellerId': value.massiveResellerId,
        'massivePurchaseOrderNumber': value.massivePurchaseOrderNumber,
        'upsServiceCode': value.upsServiceCode,
        'fedexServiceCode': value.fedexServiceCode,
        'massiveBillingAddressId': value.massiveBillingAddressId,
        'massiveShippingAddressId': value.massiveShippingAddressId,
        'zkpTaxId': value.zkpTaxId,
    };
}
