"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryParentTypeEnum = void 0;
exports.instanceOfCategoryParent = instanceOfCategoryParent;
exports.CategoryParentFromJSON = CategoryParentFromJSON;
exports.CategoryParentFromJSONTyped = CategoryParentFromJSONTyped;
exports.CategoryParentToJSON = CategoryParentToJSON;
const runtime_1 = require("../runtime");
const Category_1 = require("./Category");
const CategoryExtensions_1 = require("./CategoryExtensions");
const CategoryTranslated_1 = require("./CategoryTranslated");
const Media_1 = require("./Media");
const Product_1 = require("./Product");
const Tag_1 = require("./Tag");
/**
 * @export
 */
exports.CategoryParentTypeEnum = {
    Page: 'page',
    Link: 'link',
    Folder: 'folder'
};
/**
 * Check if a given object implements the CategoryParent interface.
 */
function instanceOfCategoryParent(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "displayNestedProducts" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "visible" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "translated" in value;
    return isInstance;
}
function CategoryParentFromJSON(json) {
    return CategoryParentFromJSONTyped(json, false);
}
function CategoryParentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'versionId': !(0, runtime_1.exists)(json, 'versionId') ? undefined : json['versionId'],
        'parentId': !(0, runtime_1.exists)(json, 'parentId') ? undefined : json['parentId'],
        'parentVersionId': !(0, runtime_1.exists)(json, 'parentVersionId') ? undefined : json['parentVersionId'],
        'afterCategoryId': !(0, runtime_1.exists)(json, 'afterCategoryId') ? undefined : json['afterCategoryId'],
        'afterCategoryVersionId': !(0, runtime_1.exists)(json, 'afterCategoryVersionId') ? undefined : json['afterCategoryVersionId'],
        'mediaId': !(0, runtime_1.exists)(json, 'mediaId') ? undefined : json['mediaId'],
        'displayNestedProducts': json['displayNestedProducts'],
        'autoIncrement': !(0, runtime_1.exists)(json, 'autoIncrement') ? undefined : json['autoIncrement'],
        'breadcrumb': !(0, runtime_1.exists)(json, 'breadcrumb') ? undefined : json['breadcrumb'],
        'level': !(0, runtime_1.exists)(json, 'level') ? undefined : json['level'],
        'path': !(0, runtime_1.exists)(json, 'path') ? undefined : json['path'],
        'childCount': !(0, runtime_1.exists)(json, 'childCount') ? undefined : json['childCount'],
        'type': json['type'],
        'visible': json['visible'],
        'active': json['active'],
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : json['customFields'],
        'slotConfig': !(0, runtime_1.exists)(json, 'slotConfig') ? undefined : json['slotConfig'],
        'externalLink': !(0, runtime_1.exists)(json, 'externalLink') ? undefined : json['externalLink'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'metaTitle': !(0, runtime_1.exists)(json, 'metaTitle') ? undefined : json['metaTitle'],
        'metaDescription': !(0, runtime_1.exists)(json, 'metaDescription') ? undefined : json['metaDescription'],
        'keywords': !(0, runtime_1.exists)(json, 'keywords') ? undefined : json['keywords'],
        'cmsPageId': !(0, runtime_1.exists)(json, 'cmsPageId') ? undefined : json['cmsPageId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'translated': (0, CategoryTranslated_1.CategoryTranslatedFromJSON)(json['translated']),
        'extensions': !(0, runtime_1.exists)(json, 'extensions') ? undefined : (0, CategoryExtensions_1.CategoryExtensionsFromJSON)(json['extensions']),
        'parent': !(0, runtime_1.exists)(json, 'parent') ? undefined : CategoryParentFromJSON(json['parent']),
        'children': !(0, runtime_1.exists)(json, 'children') ? undefined : (json['children'] === null ? null : json['children'].map(Category_1.CategoryFromJSON)),
        'media': !(0, runtime_1.exists)(json, 'media') ? undefined : (0, Media_1.MediaFromJSON)(json['media']),
        'products': !(0, runtime_1.exists)(json, 'products') ? undefined : (json['products'] === null ? null : json['products'].map(Product_1.ProductFromJSON)),
        'nestedProducts': !(0, runtime_1.exists)(json, 'nestedProducts') ? undefined : (0, Product_1.ProductFromJSON)(json['nestedProducts']),
        'tags': !(0, runtime_1.exists)(json, 'tags') ? undefined : (json['tags'] === null ? null : json['tags'].map(Tag_1.TagFromJSON)),
        'mainCategories': !(0, runtime_1.exists)(json, 'mainCategories') ? undefined : (json['mainCategories'] === null ? null : json['mainCategories'].map(Category_1.CategoryFromJSON)),
    };
}
function CategoryParentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'versionId': value.versionId,
        'parentId': value.parentId,
        'parentVersionId': value.parentVersionId,
        'afterCategoryId': value.afterCategoryId,
        'afterCategoryVersionId': value.afterCategoryVersionId,
        'mediaId': value.mediaId,
        'displayNestedProducts': value.displayNestedProducts,
        'type': value.type,
        'visible': value.visible,
        'active': value.active,
        'customFields': value.customFields,
        'slotConfig': value.slotConfig,
        'externalLink': value.externalLink,
        'description': value.description,
        'metaTitle': value.metaTitle,
        'metaDescription': value.metaDescription,
        'keywords': value.keywords,
        'cmsPageId': value.cmsPageId,
        'translated': (0, CategoryTranslated_1.CategoryTranslatedToJSON)(value.translated),
        'extensions': (0, CategoryExtensions_1.CategoryExtensionsToJSON)(value.extensions),
        'parent': CategoryParentToJSON(value.parent),
        'children': value.children === undefined ? undefined : (value.children === null ? null : value.children.map(Category_1.CategoryToJSON)),
        'media': (0, Media_1.MediaToJSON)(value.media),
        'products': value.products === undefined ? undefined : (value.products === null ? null : value.products.map(Product_1.ProductToJSON)),
        'nestedProducts': (0, Product_1.ProductToJSON)(value.nestedProducts),
        'tags': value.tags === undefined ? undefined : (value.tags === null ? null : value.tags.map(Tag_1.TagToJSON)),
        'mainCategories': value.mainCategories === undefined ? undefined : (value.mainCategories === null ? null : value.mainCategories.map(Category_1.CategoryToJSON)),
    };
}
