"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Sulu - Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfResellerPage = instanceOfResellerPage;
exports.ResellerPageFromJSON = ResellerPageFromJSON;
exports.ResellerPageFromJSONTyped = ResellerPageFromJSONTyped;
exports.ResellerPageToJSON = ResellerPageToJSON;
const ResellerPageContent_1 = require("./ResellerPageContent");
/**
 * Check if a given object implements the ResellerPage interface.
 */
function instanceOfResellerPage(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "pathname" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "content" in value;
    return isInstance;
}
function ResellerPageFromJSON(json) {
    return ResellerPageFromJSONTyped(json, false);
}
function ResellerPageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'pathname': json['pathname'],
        'title': json['title'],
        'content': (json['content'].map(ResellerPageContent_1.ResellerPageContentFromJSON)),
    };
}
function ResellerPageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'pathname': value.pathname,
        'title': value.title,
        'content': (value.content.map(ResellerPageContent_1.ResellerPageContentToJSON)),
    };
}
