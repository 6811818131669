"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Sulu - Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardDisrupterApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class DashboardDisrupterApi extends runtime.BaseAPI {
    /**
     * Get dashboard teaser elements.
     */
    async getDisrupterItemsRaw(requestParameters, initOverrides) {
        if (requestParameters.locale === null || requestParameters.locale === undefined) {
            throw new runtime.RequiredError('locale', 'Required parameter requestParameters.locale was null or undefined when calling getDisrupterItems.');
        }
        const queryParameters = {};
        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/portal/v1/disrupter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.DisrupterItemFromJSON));
    }
    /**
     * Get dashboard teaser elements.
     */
    async getDisrupterItems(requestParameters, initOverrides) {
        const response = await this.getDisrupterItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.DashboardDisrupterApi = DashboardDisrupterApi;
