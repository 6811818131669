"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortingType = exports.SortingDirection = void 0;
var SortingDirection;
(function (SortingDirection) {
    SortingDirection["asc"] = "asc";
    SortingDirection["desc"] = "desc";
})(SortingDirection || (exports.SortingDirection = SortingDirection = {}));
var SortingType;
(function (SortingType) {
    SortingType["numeric"] = "numeric";
    SortingType["text"] = "text";
})(SortingType || (exports.SortingType = SortingType = {}));
