"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCartPrice = instanceOfCartPrice;
exports.CartPriceFromJSON = CartPriceFromJSON;
exports.CartPriceFromJSONTyped = CartPriceFromJSONTyped;
exports.CartPriceToJSON = CartPriceToJSON;
const runtime_1 = require("../runtime");
const CalculatedTax_1 = require("./CalculatedTax");
const CartTaxRule_1 = require("./CartTaxRule");
/**
 * Check if a given object implements the CartPrice interface.
 */
function instanceOfCartPrice(value) {
    let isInstance = true;
    isInstance = isInstance && "netPrice" in value;
    isInstance = isInstance && "totalPrice" in value;
    isInstance = isInstance && "calculatedTaxes" in value;
    isInstance = isInstance && "taxRules" in value;
    return isInstance;
}
function CartPriceFromJSON(json) {
    return CartPriceFromJSONTyped(json, false);
}
function CartPriceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'netPrice': json['netPrice'],
        'totalPrice': json['totalPrice'],
        'positionPrice': !(0, runtime_1.exists)(json, 'positionPrice') ? undefined : json['positionPrice'],
        'calculatedTaxes': (json['calculatedTaxes'].map(CalculatedTax_1.CalculatedTaxFromJSON)),
        'taxRules': (json['taxRules'].map(CartTaxRule_1.CartTaxRuleFromJSON)),
    };
}
function CartPriceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'netPrice': value.netPrice,
        'totalPrice': value.totalPrice,
        'positionPrice': value.positionPrice,
        'calculatedTaxes': (value.calculatedTaxes.map(CalculatedTax_1.CalculatedTaxToJSON)),
        'taxRules': (value.taxRules.map(CartTaxRule_1.CartTaxRuleToJSON)),
    };
}
