"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassiveIndustry = instanceOfMassiveIndustry;
exports.MassiveIndustryFromJSON = MassiveIndustryFromJSON;
exports.MassiveIndustryFromJSONTyped = MassiveIndustryFromJSONTyped;
exports.MassiveIndustryToJSON = MassiveIndustryToJSON;
const runtime_1 = require("../runtime");
const MassiveIndustryTranslated_1 = require("./MassiveIndustryTranslated");
/**
 * Check if a given object implements the MassiveIndustry interface.
 */
function instanceOfMassiveIndustry(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function MassiveIndustryFromJSON(json) {
    return MassiveIndustryFromJSONTyped(json, false);
}
function MassiveIndustryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'externalId': !(0, runtime_1.exists)(json, 'externalId') ? undefined : json['externalId'],
        'deleted': json['deleted'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, MassiveIndustryTranslated_1.MassiveIndustryTranslatedFromJSON)(json['translated']),
    };
}
function MassiveIndustryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'externalId': value.externalId,
        'deleted': value.deleted,
        'translated': (0, MassiveIndustryTranslated_1.MassiveIndustryTranslatedToJSON)(value.translated),
    };
}
