"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationSubjectEnum = void 0;
exports.instanceOfNotification = instanceOfNotification;
exports.NotificationFromJSON = NotificationFromJSON;
exports.NotificationFromJSONTyped = NotificationFromJSONTyped;
exports.NotificationToJSON = NotificationToJSON;
const runtime_1 = require("../runtime");
const NotificationParameter_1 = require("./NotificationParameter");
/**
 * @export
 */
exports.NotificationSubjectEnum = {
    Expiring: 'license_expiring',
    Inactive: 'license_inactive',
    MissingContact: 'license_missing_contact'
};
/**
 * Check if a given object implements the Notification interface.
 */
function instanceOfNotification(value) {
    let isInstance = true;
    isInstance = isInstance && "subject" in value;
    return isInstance;
}
function NotificationFromJSON(json) {
    return NotificationFromJSONTyped(json, false);
}
function NotificationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
        'path': !(0, runtime_1.exists)(json, 'path') ? undefined : json['path'],
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : json['type'],
        'subject': json['subject'],
        'parameters': !(0, runtime_1.exists)(json, 'parameters') ? undefined : (0, NotificationParameter_1.NotificationParameterFromJSON)(json['parameters']),
    };
}
function NotificationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'label': value.label,
        'path': value.path,
        'type': value.type,
        'subject': value.subject,
        'parameters': (0, NotificationParameter_1.NotificationParameterToJSON)(value.parameters),
    };
}
