"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LineItemTypeEnum = void 0;
exports.instanceOfLineItem = instanceOfLineItem;
exports.LineItemFromJSON = LineItemFromJSON;
exports.LineItemFromJSONTyped = LineItemFromJSONTyped;
exports.LineItemToJSON = LineItemToJSON;
const runtime_1 = require("../runtime");
const LineItemPayload_1 = require("./LineItemPayload");
const LineItemPrice_1 = require("./LineItemPrice");
const LineItemQuantityInformation_1 = require("./LineItemQuantityInformation");
const Media_1 = require("./Media");
/**
 * @export
 */
exports.LineItemTypeEnum = {
    Credit: 'credit',
    Custom: 'custom',
    Product: 'product',
    Promotion: 'promotion',
    Surcharge: 'surcharge',
    ZkpSoftwareOffer: 'zkpSoftwareOffer'
};
/**
 * Check if a given object implements the LineItem interface.
 */
function instanceOfLineItem(value) {
    let isInstance = true;
    return isInstance;
}
function LineItemFromJSON(json) {
    return LineItemFromJSONTyped(json, false);
}
function LineItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'referencedId': !(0, runtime_1.exists)(json, 'referencedId') ? undefined : json['referencedId'],
        'parentId': !(0, runtime_1.exists)(json, 'parentId') ? undefined : json['parentId'],
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : json['type'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
        'removable': !(0, runtime_1.exists)(json, 'removable') ? undefined : json['removable'],
        'stackable': !(0, runtime_1.exists)(json, 'stackable') ? undefined : json['stackable'],
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'quantityInformation': !(0, runtime_1.exists)(json, 'quantityInformation') ? undefined : (0, LineItemQuantityInformation_1.LineItemQuantityInformationFromJSON)(json['quantityInformation']),
        'payload': !(0, runtime_1.exists)(json, 'payload') ? undefined : (0, LineItemPayload_1.LineItemPayloadFromJSON)(json['payload']),
        'good': !(0, runtime_1.exists)(json, 'good') ? undefined : json['good'],
        'cover': !(0, runtime_1.exists)(json, 'cover') ? undefined : (0, Media_1.MediaFromJSON)(json['cover']),
        'price': !(0, runtime_1.exists)(json, 'price') ? undefined : (0, LineItemPrice_1.LineItemPriceFromJSON)(json['price']),
        'unitPrice': !(0, runtime_1.exists)(json, 'unitPrice') ? undefined : json['unitPrice'],
        'totalPrice': !(0, runtime_1.exists)(json, 'totalPrice') ? undefined : json['totalPrice'],
        'children': !(0, runtime_1.exists)(json, 'children') ? undefined : (json['children'] === null ? null : json['children'].map(LineItemFromJSON)),
        'productId': !(0, runtime_1.exists)(json, 'productId') ? undefined : json['productId'],
        'softwareOfferId': !(0, runtime_1.exists)(json, 'softwareOfferId') ? undefined : json['softwareOfferId'],
        'position': !(0, runtime_1.exists)(json, 'position') ? undefined : json['position'],
    };
}
function LineItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'referencedId': value.referencedId,
        'parentId': value.parentId,
        'type': value.type,
        'quantity': value.quantity,
        'removable': value.removable,
        'stackable': value.stackable,
        'label': value.label,
        'description': value.description,
        'quantityInformation': (0, LineItemQuantityInformation_1.LineItemQuantityInformationToJSON)(value.quantityInformation),
        'payload': (0, LineItemPayload_1.LineItemPayloadToJSON)(value.payload),
        'good': value.good,
        'cover': (0, Media_1.MediaToJSON)(value.cover),
        'price': (0, LineItemPrice_1.LineItemPriceToJSON)(value.price),
        'unitPrice': value.unitPrice,
        'totalPrice': value.totalPrice,
        'children': value.children === undefined ? undefined : (value.children === null ? null : value.children.map(LineItemToJSON)),
        'productId': value.productId,
        'softwareOfferId': value.softwareOfferId,
    };
}
