"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTaxRuleType = instanceOfTaxRuleType;
exports.TaxRuleTypeFromJSON = TaxRuleTypeFromJSON;
exports.TaxRuleTypeFromJSONTyped = TaxRuleTypeFromJSONTyped;
exports.TaxRuleTypeToJSON = TaxRuleTypeToJSON;
const runtime_1 = require("../runtime");
const TaxRule_1 = require("./TaxRule");
/**
 * Check if a given object implements the TaxRuleType interface.
 */
function instanceOfTaxRuleType(value) {
    let isInstance = true;
    isInstance = isInstance && "technicalName" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "typeName" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function TaxRuleTypeFromJSON(json) {
    return TaxRuleTypeFromJSONTyped(json, false);
}
function TaxRuleTypeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'technicalName': json['technicalName'],
        'position': json['position'],
        'typeName': json['typeName'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : json['translated'],
        'rules': !(0, runtime_1.exists)(json, 'rules') ? undefined : (json['rules'].map(TaxRule_1.TaxRuleFromJSON)),
    };
}
function TaxRuleTypeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'position': value.position,
        'typeName': value.typeName,
        'translated': value.translated,
        'rules': value.rules === undefined ? undefined : (value.rules.map(TaxRule_1.TaxRuleToJSON)),
    };
}
