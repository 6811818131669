"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DataLayerLabels;
(function (DataLayerLabels) {
    DataLayerLabels["dlvAddQuickOrderToCart"] = "dlv_add_quick_order_to_cart";
    DataLayerLabels["dlvContactPerson"] = "dlv_contact_person";
    DataLayerLabels["dlvContactPersonClickType"] = "dlv_kontaktanfrage_open";
    DataLayerLabels["dlvContactPersonTitle"] = "dlv_kontaktanfrage_title";
    DataLayerLabels["dlvCustomer"] = "dlv_customer";
    DataLayerLabels["dlvDownload"] = "dlv_download";
    DataLayerLabels["dlvNavigationLevelOne"] = "dlv_navigation_level1";
    DataLayerLabels["dlvNavigationLevelThree"] = "dlv_navigation_level3";
    DataLayerLabels["dlvNavigationLevelTwo"] = "dlv_navigation_level2";
    DataLayerLabels["dlvProductTitle"] = "dlv_produkt_titel";
    DataLayerLabels["dlvQuickOrder"] = "dlv_quick_order";
    DataLayerLabels["dlvSalesChannel"] = "dlv_sales_channel";
    DataLayerLabels["eventLabel"] = "event_label";
    DataLayerLabels["dlvDashboardShortcut"] = "dlv_dashboard_shortcut";
    DataLayerLabels["dlvSearch"] = "dlv_search";
    DataLayerLabels["dlvSearchClickProductTitle"] = "dlv_search_click_product_title";
    DataLayerLabels["dlvKontaktanfrageOpenValue"] = "dlv_kontaktanfrage_open_value";
    DataLayerLabels["dlvEcommerce"] = "ecommerce";
})(DataLayerLabels || (DataLayerLabels = {}));
exports.default = DataLayerLabels;
