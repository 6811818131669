"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCurrency = instanceOfCurrency;
exports.CurrencyFromJSON = CurrencyFromJSON;
exports.CurrencyFromJSONTyped = CurrencyFromJSONTyped;
exports.CurrencyToJSON = CurrencyToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the Currency interface.
 */
function instanceOfCurrency(value) {
    let isInstance = true;
    return isInstance;
}
function CurrencyFromJSON(json) {
    return CurrencyFromJSONTyped(json, false);
}
function CurrencyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'isoCode': !(0, runtime_1.exists)(json, 'isoCode') ? undefined : json['isoCode'],
        'factor': !(0, runtime_1.exists)(json, 'factor') ? undefined : json['factor'],
        'symbol': !(0, runtime_1.exists)(json, 'symbol') ? undefined : json['symbol'],
        'shortName': !(0, runtime_1.exists)(json, 'shortName') ? undefined : json['shortName'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'position': !(0, runtime_1.exists)(json, 'position') ? undefined : json['position'],
        'decimalPrecision': !(0, runtime_1.exists)(json, 'decimalPrecision') ? undefined : json['decimalPrecision'],
        'isSystemDefault': !(0, runtime_1.exists)(json, 'isSystemDefault') ? undefined : json['isSystemDefault'],
    };
}
function CurrencyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'isoCode': value.isoCode,
        'factor': value.factor,
        'symbol': value.symbol,
        'shortName': value.shortName,
        'name': value.name,
        'position': value.position,
        'decimalPrecision': value.decimalPrecision,
        'isSystemDefault': value.isSystemDefault,
    };
}
