"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfShippingCosts = instanceOfShippingCosts;
exports.ShippingCostsFromJSON = ShippingCostsFromJSON;
exports.ShippingCostsFromJSONTyped = ShippingCostsFromJSONTyped;
exports.ShippingCostsToJSON = ShippingCostsToJSON;
/**
 * Check if a given object implements the ShippingCosts interface.
 */
function instanceOfShippingCosts(value) {
    let isInstance = true;
    isInstance = isInstance && "unitPrice" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "totalPrice" in value;
    return isInstance;
}
function ShippingCostsFromJSON(json) {
    return ShippingCostsFromJSONTyped(json, false);
}
function ShippingCostsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'unitPrice': json['unitPrice'],
        'quantity': json['quantity'],
        'totalPrice': json['totalPrice'],
    };
}
function ShippingCostsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'unitPrice': value.unitPrice,
        'quantity': value.quantity,
        'totalPrice': value.totalPrice,
    };
}
