"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfProductResponse = instanceOfProductResponse;
exports.ProductResponseFromJSON = ProductResponseFromJSON;
exports.ProductResponseFromJSONTyped = ProductResponseFromJSONTyped;
exports.ProductResponseToJSON = ProductResponseToJSON;
const runtime_1 = require("../runtime");
const Configurator_1 = require("./Configurator");
const Product_1 = require("./Product");
/**
 * Check if a given object implements the ProductResponse interface.
 */
function instanceOfProductResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "product" in value;
    return isInstance;
}
function ProductResponseFromJSON(json) {
    return ProductResponseFromJSONTyped(json, false);
}
function ProductResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'product': (0, Product_1.ProductFromJSON)(json['product']),
        'configurator': !(0, runtime_1.exists)(json, 'configurator') ? undefined : (json['configurator'] === null ? null : json['configurator'].map(Configurator_1.ConfiguratorFromJSON)),
    };
}
function ProductResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'product': (0, Product_1.ProductToJSON)(value.product),
        'configurator': value.configurator === undefined ? undefined : (value.configurator === null ? null : value.configurator.map(Configurator_1.ConfiguratorToJSON)),
    };
}
