"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addResourcesToDom = exports.addStylesheetsToDom = exports.addScriptFilesToDom = exports.addScriptContentsToDom = void 0;
const addScriptContentsToDom = (scripts) => {
    return scripts.map((script) => {
        const scriptElement = document.createElement('script');
        script.getAttributeNames().forEach((attr) => {
            scriptElement.setAttribute(attr, script.getAttribute(attr) ?? '');
        });
        if (script.innerHTML) {
            scriptElement.innerHTML = script.innerHTML;
        }
        document.head.appendChild(scriptElement);
        return scriptElement;
    });
};
exports.addScriptContentsToDom = addScriptContentsToDom;
const addScriptFilesToDom = (assets) => {
    return assets
        .map((asset) => {
        if (document.querySelector(`script[src="${asset}"]`)) {
            return null;
        }
        const scriptElement = document.createElement('script');
        scriptElement.src = asset;
        scriptElement.async = true;
        scriptElement.defer = true;
        document.head.appendChild(scriptElement);
        return scriptElement;
    })
        .filter((scriptElement) => scriptElement !== null);
};
exports.addScriptFilesToDom = addScriptFilesToDom;
const addStylesheetsToDom = (assets) => {
    return assets
        .map((asset) => {
        if (document.querySelector(`link[href="${asset}"]`)) {
            return null;
        }
        const linkElement = document.createElement('link');
        linkElement.rel = 'stylesheet';
        linkElement.href = asset;
        document.head.appendChild(linkElement);
        return linkElement;
    })
        .filter((linkElement) => linkElement !== null);
};
exports.addStylesheetsToDom = addStylesheetsToDom;
const addResourcesToDom = (assets) => [
    ...(0, exports.addStylesheetsToDom)(assets.filter((source) => source.endsWith('.css'))),
    ...(0, exports.addScriptFilesToDom)(assets.filter((source) => source.endsWith('.js'))),
];
exports.addResourcesToDom = addResourcesToDom;
