"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLineItemPayloadDescriptions = void 0;
const translation_1 = require("../../common/js/helper/translation");
const getLineItemPayloadDescriptions = (lineItem) => {
    const licenseId = lineItem.payload?.zkpSoftwareOfferLicenseId;
    const offerId = lineItem.payload?.zkpSoftwareOfferId;
    const serialNumber = lineItem.payload?.zkpMachineSerialNumber;
    return [
        !!offerId ? (0, translation_1.t)('line_item.offer_id', { offerId }) : null,
        !!licenseId ? (0, translation_1.t)('line_item.license_id', { licenseId }) : null,
        !!serialNumber ? (0, translation_1.t)('line_item.serial_number', { serialNumber }) : null,
    ].filter((value) => !!value);
};
exports.getLineItemPayloadDescriptions = getLineItemPayloadDescriptions;
