"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfConfigurator = instanceOfConfigurator;
exports.ConfiguratorFromJSON = ConfiguratorFromJSON;
exports.ConfiguratorFromJSONTyped = ConfiguratorFromJSONTyped;
exports.ConfiguratorToJSON = ConfiguratorToJSON;
const runtime_1 = require("../runtime");
const PropertyGroupOption_1 = require("./PropertyGroupOption");
/**
 * Check if a given object implements the Configurator interface.
 */
function instanceOfConfigurator(value) {
    let isInstance = true;
    return isInstance;
}
function ConfiguratorFromJSON(json) {
    return ConfiguratorFromJSONTyped(json, false);
}
function ConfiguratorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'options': !(0, runtime_1.exists)(json, 'options') ? undefined : (json['options'].map(PropertyGroupOption_1.PropertyGroupOptionFromJSON)),
    };
}
function ConfiguratorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'options': value.options === undefined ? undefined : (value.options.map(PropertyGroupOption_1.PropertyGroupOptionToJSON)),
    };
}
