"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
class Route {
    history;
    localeOfUrl = null;
    location;
    constructor() {
        (0, mobx_1.makeObservable)(this, {
            history: mobx_1.observable,
            localeOfUrl: mobx_1.observable,
            location: mobx_1.observable,
        });
    }
}
exports.default = new Route();
