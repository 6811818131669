"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClaims = exports.getUser = void 0;
const Audiences_1 = require("../constants/Audiences");
const getUser = (auth0) => !!auth0?.isAuthenticated ? auth0?.user || null : null;
exports.getUser = getUser;
const getClaims = (auth0) => {
    const user = (0, exports.getUser)(auth0);
    return null !== user ? (user[Audiences_1.Audiences.PORTAL] ?? null) : null;
};
exports.getClaims = getClaims;
