"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCountry = instanceOfCountry;
exports.CountryFromJSON = CountryFromJSON;
exports.CountryFromJSONTyped = CountryFromJSONTyped;
exports.CountryToJSON = CountryToJSON;
const runtime_1 = require("../runtime");
const CountryExtensions_1 = require("./CountryExtensions");
const CountryState_1 = require("./CountryState");
const CountryTranslated_1 = require("./CountryTranslated");
/**
 * Check if a given object implements the Country interface.
 */
function instanceOfCountry(value) {
    let isInstance = true;
    isInstance = isInstance && "iso" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function CountryFromJSON(json) {
    return CountryFromJSONTyped(json, false);
}
function CountryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'iso': json['iso'],
        'position': !(0, runtime_1.exists)(json, 'position') ? undefined : json['position'],
        'taxFree': !(0, runtime_1.exists)(json, 'taxFree') ? undefined : json['taxFree'],
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'shippingAvailable': !(0, runtime_1.exists)(json, 'shippingAvailable') ? undefined : json['shippingAvailable'],
        'iso3': !(0, runtime_1.exists)(json, 'iso3') ? undefined : json['iso3'],
        'displayStateInRegistration': !(0, runtime_1.exists)(json, 'displayStateInRegistration') ? undefined : json['displayStateInRegistration'],
        'forceStateInRegistration': !(0, runtime_1.exists)(json, 'forceStateInRegistration') ? undefined : json['forceStateInRegistration'],
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : json['customFields'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, CountryTranslated_1.CountryTranslatedFromJSON)(json['translated']),
        'states': !(0, runtime_1.exists)(json, 'states') ? undefined : (json['states'] === null ? null : json['states'].map(CountryState_1.CountryStateFromJSON)),
        'extensions': !(0, runtime_1.exists)(json, 'extensions') ? undefined : (0, CountryExtensions_1.CountryExtensionsFromJSON)(json['extensions']),
    };
}
function CountryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'iso': value.iso,
        'position': value.position,
        'taxFree': value.taxFree,
        'active': value.active,
        'shippingAvailable': value.shippingAvailable,
        'iso3': value.iso3,
        'displayStateInRegistration': value.displayStateInRegistration,
        'forceStateInRegistration': value.forceStateInRegistration,
        'customFields': value.customFields,
        'translated': (0, CountryTranslated_1.CountryTranslatedToJSON)(value.translated),
        'states': value.states === undefined ? undefined : (value.states === null ? null : value.states.map(CountryState_1.CountryStateToJSON)),
        'extensions': (0, CountryExtensions_1.CountryExtensionsToJSON)(value.extensions),
    };
}
