"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ButtonVariant;
(function (ButtonVariant) {
    ButtonVariant["gray"] = "gray";
    ButtonVariant["light"] = "light";
    ButtonVariant["navigator"] = "navigator";
    ButtonVariant["primary"] = "primary";
    ButtonVariant["secondary"] = "secondary";
    ButtonVariant["text"] = "text";
    ButtonVariant["white"] = "white";
})(ButtonVariant || (ButtonVariant = {}));
exports.default = ButtonVariant;
