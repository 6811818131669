"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSearchRequestBodySortEnum = void 0;
exports.instanceOfProductSearchRequestBody = instanceOfProductSearchRequestBody;
exports.ProductSearchRequestBodyFromJSON = ProductSearchRequestBodyFromJSON;
exports.ProductSearchRequestBodyFromJSONTyped = ProductSearchRequestBodyFromJSONTyped;
exports.ProductSearchRequestBodyToJSON = ProductSearchRequestBodyToJSON;
const runtime_1 = require("../runtime");
/**
 * @export
 */
exports.ProductSearchRequestBodySortEnum = {
    Score: 'score',
    NameAsc: 'name-asc',
    NameDesc: 'name-desc',
    PriceAsc: 'price-asc',
    PriceDesc: 'price-desc'
};
/**
 * Check if a given object implements the ProductSearchRequestBody interface.
 */
function instanceOfProductSearchRequestBody(value) {
    let isInstance = true;
    isInstance = isInstance && "search" in value;
    return isInstance;
}
function ProductSearchRequestBodyFromJSON(json) {
    return ProductSearchRequestBodyFromJSONTyped(json, false);
}
function ProductSearchRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'search': json['search'],
        'limit': !(0, runtime_1.exists)(json, 'limit') ? undefined : json['limit'],
        'page': !(0, runtime_1.exists)(json, 'page') ? undefined : json['page'],
        'sort': !(0, runtime_1.exists)(json, 'sort') ? undefined : json['sort'],
        'associations': !(0, runtime_1.exists)(json, 'associations') ? undefined : json['associations'],
        'includes': !(0, runtime_1.exists)(json, 'includes') ? undefined : json['includes'],
    };
}
function ProductSearchRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'search': value.search,
        'limit': value.limit,
        'page': value.page,
        'sort': value.sort,
        'associations': value.associations,
        'includes': value.includes,
    };
}
