"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTaxRuleData = instanceOfTaxRuleData;
exports.TaxRuleDataFromJSON = TaxRuleDataFromJSON;
exports.TaxRuleDataFromJSONTyped = TaxRuleDataFromJSONTyped;
exports.TaxRuleDataToJSON = TaxRuleDataToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the TaxRuleData interface.
 */
function instanceOfTaxRuleData(value) {
    let isInstance = true;
    return isInstance;
}
function TaxRuleDataFromJSON(json) {
    return TaxRuleDataFromJSONTyped(json, false);
}
function TaxRuleDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'states': !(0, runtime_1.exists)(json, 'states') ? undefined : json['states'],
        'zipCode': !(0, runtime_1.exists)(json, 'zipCode') ? undefined : json['zipCode'],
        'fromZipCode': !(0, runtime_1.exists)(json, 'fromZipCode') ? undefined : json['fromZipCode'],
        'toZipCode': !(0, runtime_1.exists)(json, 'toZipCode') ? undefined : json['toZipCode'],
    };
}
function TaxRuleDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'states': value.states,
        'zipCode': value.zipCode,
        'fromZipCode': value.fromZipCode,
        'toZipCode': value.toZipCode,
    };
}
