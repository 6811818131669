"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMedia = instanceOfMedia;
exports.MediaFromJSON = MediaFromJSON;
exports.MediaFromJSONTyped = MediaFromJSONTyped;
exports.MediaToJSON = MediaToJSON;
const runtime_1 = require("../runtime");
const MediaMetaData_1 = require("./MediaMetaData");
const MediaThumbnail_1 = require("./MediaThumbnail");
const Tag_1 = require("./Tag");
/**
 * Check if a given object implements the Media interface.
 */
function instanceOfMedia(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function MediaFromJSON(json) {
    return MediaFromJSONTyped(json, false);
}
function MediaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'mimeType': !(0, runtime_1.exists)(json, 'mimeType') ? undefined : json['mimeType'],
        'fileExtension': !(0, runtime_1.exists)(json, 'fileExtension') ? undefined : json['fileExtension'],
        'uploadedAt': !(0, runtime_1.exists)(json, 'uploadedAt') ? undefined : (new Date(json['uploadedAt'])),
        'fileName': !(0, runtime_1.exists)(json, 'fileName') ? undefined : json['fileName'],
        'fileSize': !(0, runtime_1.exists)(json, 'fileSize') ? undefined : json['fileSize'],
        'metaData': !(0, runtime_1.exists)(json, 'metaData') ? undefined : (0, MediaMetaData_1.MediaMetaDataFromJSON)(json['metaData']),
        'alt': !(0, runtime_1.exists)(json, 'alt') ? undefined : json['alt'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'hasFile': !(0, runtime_1.exists)(json, 'hasFile') ? undefined : json['hasFile'],
        '_private': !(0, runtime_1.exists)(json, 'private') ? undefined : json['private'],
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : json['customFields'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : json['translated'],
        'tags': !(0, runtime_1.exists)(json, 'tags') ? undefined : (json['tags'].map(Tag_1.TagFromJSON)),
        'thumbnails': !(0, runtime_1.exists)(json, 'thumbnails') ? undefined : (json['thumbnails'].map(MediaThumbnail_1.MediaThumbnailFromJSON)),
    };
}
function MediaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'metaData': (0, MediaMetaData_1.MediaMetaDataToJSON)(value.metaData),
        'alt': value.alt,
        'title': value.title,
        'url': value.url,
        'hasFile': value.hasFile,
        'private': value._private,
        'customFields': value.customFields,
        'translated': value.translated,
        'tags': value.tags === undefined ? undefined : (value.tags.map(Tag_1.TagToJSON)),
        'thumbnails': value.thumbnails === undefined ? undefined : (value.thumbnails.map(MediaThumbnail_1.MediaThumbnailToJSON)),
    };
}
