"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCountryState = instanceOfCountryState;
exports.CountryStateFromJSON = CountryStateFromJSON;
exports.CountryStateFromJSONTyped = CountryStateFromJSONTyped;
exports.CountryStateToJSON = CountryStateToJSON;
const runtime_1 = require("../runtime");
const CountryStateTranslated_1 = require("./CountryStateTranslated");
/**
 * Check if a given object implements the CountryState interface.
 */
function instanceOfCountryState(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "countryId" in value;
    isInstance = isInstance && "shortCode" in value;
    return isInstance;
}
function CountryStateFromJSON(json) {
    return CountryStateFromJSONTyped(json, false);
}
function CountryStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'countryId': json['countryId'],
        'shortCode': json['shortCode'],
        'position': !(0, runtime_1.exists)(json, 'position') ? undefined : json['position'],
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : json['customFields'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, CountryStateTranslated_1.CountryStateTranslatedFromJSON)(json['translated']),
    };
}
function CountryStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'countryId': value.countryId,
        'shortCode': value.shortCode,
        'position': value.position,
        'active': value.active,
        'customFields': value.customFields,
        'translated': (0, CountryStateTranslated_1.CountryStateTranslatedToJSON)(value.translated),
    };
}
