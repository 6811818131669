"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DataLayerEvents;
(function (DataLayerEvents) {
    DataLayerEvents["dlvClickNotification"] = "dlv_click_notification";
    DataLayerEvents["dlvClickNotificationList"] = "dlv_click_notification_list";
    DataLayerEvents["dlvContactPerson"] = "dlv_kontaktanfrage_open";
    DataLayerEvents["dlvDashboardMachineClick"] = "dlv_dashboard_machine_click";
    DataLayerEvents["dlvDashboardNewsClick"] = "dlv_dashboard_news_click";
    DataLayerEvents["dlvDashboardShortcutClick"] = "dlv_dashboard_shortcut_click";
    DataLayerEvents["dlvDownloads"] = "dlv_downloads";
    DataLayerEvents["dlvEventAddQuickOrderToCart"] = "dlv_event_add_quickorder_to_cart";
    DataLayerEvents["dlvEventQuickOrder"] = "dlv_event_quickorder";
    DataLayerEvents["dlvEventSearch"] = "dlv_event_search";
    DataLayerEvents["dlvEventUserLogged"] = "dlv_event_user_logged";
    DataLayerEvents["dlvMainNavigation"] = "dlv_hauptnavigation";
    DataLayerEvents["dlvProduct"] = "dlv_produkte";
    DataLayerEvents["dlvEventSearchClick"] = "dlv_event_search_click";
    DataLayerEvents["dlvPurchase"] = "purchase";
})(DataLayerEvents || (DataLayerEvents = {}));
exports.default = DataLayerEvents;
