"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassiveCountry = instanceOfMassiveCountry;
exports.MassiveCountryFromJSON = MassiveCountryFromJSON;
exports.MassiveCountryFromJSONTyped = MassiveCountryFromJSONTyped;
exports.MassiveCountryToJSON = MassiveCountryToJSON;
/**
 * Check if a given object implements the MassiveCountry interface.
 */
function instanceOfMassiveCountry(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "countryId" in value;
    isInstance = isInstance && "availableForRegistration" in value;
    return isInstance;
}
function MassiveCountryFromJSON(json) {
    return MassiveCountryFromJSONTyped(json, false);
}
function MassiveCountryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'countryId': json['countryId'],
        'availableForRegistration': json['availableForRegistration'],
    };
}
function MassiveCountryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'countryId': value.countryId,
        'availableForRegistration': value.availableForRegistration,
    };
}
