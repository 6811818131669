"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPaymentMethod = instanceOfPaymentMethod;
exports.PaymentMethodFromJSON = PaymentMethodFromJSON;
exports.PaymentMethodFromJSONTyped = PaymentMethodFromJSONTyped;
exports.PaymentMethodToJSON = PaymentMethodToJSON;
const runtime_1 = require("../runtime");
const Media_1 = require("./Media");
const PaymentMethodExtensions_1 = require("./PaymentMethodExtensions");
const PaymentMethodTranslation_1 = require("./PaymentMethodTranslation");
/**
 * Check if a given object implements the PaymentMethod interface.
 */
function instanceOfPaymentMethod(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
function PaymentMethodFromJSON(json) {
    return PaymentMethodFromJSONTyped(json, false);
}
function PaymentMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'pluginId': !(0, runtime_1.exists)(json, 'pluginId') ? undefined : json['pluginId'],
        'position': !(0, runtime_1.exists)(json, 'position') ? undefined : json['position'],
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'afterOrderEnabled': !(0, runtime_1.exists)(json, 'afterOrderEnabled') ? undefined : json['afterOrderEnabled'],
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : json['customFields'],
        'availabilityRuleId': !(0, runtime_1.exists)(json, 'availabilityRuleId') ? undefined : json['availabilityRuleId'],
        'mediaId': !(0, runtime_1.exists)(json, 'mediaId') ? undefined : json['mediaId'],
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, PaymentMethodTranslation_1.PaymentMethodTranslationFromJSON)(json['translated']),
        'media': !(0, runtime_1.exists)(json, 'media') ? undefined : (0, Media_1.MediaFromJSON)(json['media']),
        'extensions': !(0, runtime_1.exists)(json, 'extensions') ? undefined : (0, PaymentMethodExtensions_1.PaymentMethodExtensionsFromJSON)(json['extensions']),
    };
}
function PaymentMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'pluginId': value.pluginId,
        'position': value.position,
        'active': value.active,
        'afterOrderEnabled': value.afterOrderEnabled,
        'customFields': value.customFields,
        'availabilityRuleId': value.availabilityRuleId,
        'mediaId': value.mediaId,
        'translated': (0, PaymentMethodTranslation_1.PaymentMethodTranslationToJSON)(value.translated),
        'media': (0, Media_1.MediaToJSON)(value.media),
        'extensions': (0, PaymentMethodExtensions_1.PaymentMethodExtensionsToJSON)(value.extensions),
    };
}
