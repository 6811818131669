"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Downloads - Software API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceApi = void 0;
const runtime = __importStar(require("../runtime"));
/**
 *
 */
class ServiceApi extends runtime.BaseAPI {
    /**
     * Downloads a previously uploaded file.
     */
    async getServiceMediaFileRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getServiceMediaFile.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerToken", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerTokenQuery", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/v1/media/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.BlobApiResponse(response);
    }
    /**
     * Downloads a previously uploaded file.
     */
    async getServiceMediaFile(requestParameters, initOverrides) {
        const response = await this.getServiceMediaFileRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Checks upload progress of a file. Uses flowjs/flow-php-server.
     */
    async getServiceMediaUploadRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.flow !== undefined) {
            formParams.append('flow', requestParameters.flow);
        }
        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file);
        }
        const response = await this.request({
            path: `/api/service/v1/media`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Checks upload progress of a file. Uses flowjs/flow-php-server.
     */
    async getServiceMediaUpload(requestParameters = {}, initOverrides) {
        await this.getServiceMediaUploadRaw(requestParameters, initOverrides);
    }
    /**
     * Generates an downloads a PDF file.
     */
    async getServicePdfRaw(requestParameters, initOverrides) {
        if (requestParameters.locale === null || requestParameters.locale === undefined) {
            throw new runtime.RequiredError('locale', 'Required parameter requestParameters.locale was null or undefined when calling getServicePdf.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling getServicePdf.');
        }
        const queryParameters = {};
        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerToken", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerTokenQuery", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/service/v1/pdf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body,
        }, initOverrides);
        return new runtime.BlobApiResponse(response);
    }
    /**
     * Generates an downloads a PDF file.
     */
    async getServicePdf(requestParameters, initOverrides) {
        const response = await this.getServicePdfRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Uploads (chunks of) a file. Uses flowjs/flow-php-server.
     */
    async postServiceMediaUploadRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.flow !== undefined) {
            formParams.append('flow', requestParameters.flow);
        }
        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file);
        }
        const response = await this.request({
            path: `/api/service/v1/media`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Uploads (chunks of) a file. Uses flowjs/flow-php-server.
     */
    async postServiceMediaUpload(requestParameters = {}, initOverrides) {
        await this.postServiceMediaUploadRaw(requestParameters, initOverrides);
    }
}
exports.ServiceApi = ServiceApi;
