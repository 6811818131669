"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCrossSellingProductsMapping = instanceOfCrossSellingProductsMapping;
exports.CrossSellingProductsMappingFromJSON = CrossSellingProductsMappingFromJSON;
exports.CrossSellingProductsMappingFromJSONTyped = CrossSellingProductsMappingFromJSONTyped;
exports.CrossSellingProductsMappingToJSON = CrossSellingProductsMappingToJSON;
const runtime_1 = require("../runtime");
const CrossSelling_1 = require("./CrossSelling");
const Product_1 = require("./Product");
/**
 * Check if a given object implements the CrossSellingProductsMapping interface.
 */
function instanceOfCrossSellingProductsMapping(value) {
    let isInstance = true;
    return isInstance;
}
function CrossSellingProductsMappingFromJSON(json) {
    return CrossSellingProductsMappingFromJSONTyped(json, false);
}
function CrossSellingProductsMappingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'crossSelling': !(0, runtime_1.exists)(json, 'crossSelling') ? undefined : (0, CrossSelling_1.CrossSellingFromJSON)(json['crossSelling']),
        'products': !(0, runtime_1.exists)(json, 'products') ? undefined : (json['products'] === null ? null : json['products'].map(Product_1.ProductFromJSON)),
    };
}
function CrossSellingProductsMappingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'crossSelling': (0, CrossSelling_1.CrossSellingToJSON)(value.crossSelling),
        'products': value.products === undefined ? undefined : (value.products === null ? null : value.products.map(Product_1.ProductToJSON)),
    };
}
