"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassiveSalesChannelContext = instanceOfMassiveSalesChannelContext;
exports.MassiveSalesChannelContextFromJSON = MassiveSalesChannelContextFromJSON;
exports.MassiveSalesChannelContextFromJSONTyped = MassiveSalesChannelContextFromJSONTyped;
exports.MassiveSalesChannelContextToJSON = MassiveSalesChannelContextToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the MassiveSalesChannelContext interface.
 */
function instanceOfMassiveSalesChannelContext(value) {
    let isInstance = true;
    return isInstance;
}
function MassiveSalesChannelContextFromJSON(json) {
    return MassiveSalesChannelContextFromJSONTyped(json, false);
}
function MassiveSalesChannelContextFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'checkoutRequiresTaxId': !(0, runtime_1.exists)(json, 'checkoutRequiresTaxId') ? undefined : json['checkoutRequiresTaxId'],
        'massiveBillingAddressId': !(0, runtime_1.exists)(json, 'massiveBillingAddressId') ? undefined : json['massiveBillingAddressId'],
        'massivePurchaseOrderNumber': !(0, runtime_1.exists)(json, 'massivePurchaseOrderNumber') ? undefined : json['massivePurchaseOrderNumber'],
        'massiveShippingAddressId': !(0, runtime_1.exists)(json, 'massiveShippingAddressId') ? undefined : json['massiveShippingAddressId'],
        'massiveShippingMethodCustomerId': !(0, runtime_1.exists)(json, 'massiveShippingMethodCustomerId') ? undefined : json['massiveShippingMethodCustomerId'],
        'massiveResellerId': !(0, runtime_1.exists)(json, 'massiveResellerId') ? undefined : json['massiveResellerId'],
        'upsServiceCode': !(0, runtime_1.exists)(json, 'upsServiceCode') ? undefined : json['upsServiceCode'],
        'fedexServiceCode': !(0, runtime_1.exists)(json, 'fedexServiceCode') ? undefined : json['fedexServiceCode'],
        'languageId': !(0, runtime_1.exists)(json, 'languageId') ? undefined : json['languageId'],
        'localeCode': !(0, runtime_1.exists)(json, 'localeCode') ? undefined : json['localeCode'],
        'systemDefaultLanguageId': !(0, runtime_1.exists)(json, 'systemDefaultLanguageId') ? undefined : json['systemDefaultLanguageId'],
        'shopHomePageId': !(0, runtime_1.exists)(json, 'shopHomePageId') ? undefined : json['shopHomePageId'],
        'shippingServiceLabel': !(0, runtime_1.exists)(json, 'shippingServiceLabel') ? undefined : json['shippingServiceLabel'],
        'fallbackShippingMethodId': !(0, runtime_1.exists)(json, 'fallbackShippingMethodId') ? undefined : json['fallbackShippingMethodId'],
        'massiveMessageDeactivatedSalesChannel': !(0, runtime_1.exists)(json, 'massiveMessageDeactivatedSalesChannel') ? undefined : json['massiveMessageDeactivatedSalesChannel'],
        'massiveTitleDeactivatedSalesChannel': !(0, runtime_1.exists)(json, 'massiveTitleDeactivatedSalesChannel') ? undefined : json['massiveTitleDeactivatedSalesChannel'],
        'massiveSalesChannelDeactivated': !(0, runtime_1.exists)(json, 'massiveSalesChannelDeactivated') ? undefined : json['massiveSalesChannelDeactivated'],
        'massiveConsumablesCheckoutDeactivated': !(0, runtime_1.exists)(json, 'massiveConsumablesCheckoutDeactivated') ? undefined : json['massiveConsumablesCheckoutDeactivated'],
        'taxId': !(0, runtime_1.exists)(json, 'taxId') ? undefined : json['taxId'],
    };
}
function MassiveSalesChannelContextToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'checkoutRequiresTaxId': value.checkoutRequiresTaxId,
        'massiveBillingAddressId': value.massiveBillingAddressId,
        'massivePurchaseOrderNumber': value.massivePurchaseOrderNumber,
        'massiveShippingAddressId': value.massiveShippingAddressId,
        'massiveShippingMethodCustomerId': value.massiveShippingMethodCustomerId,
        'massiveResellerId': value.massiveResellerId,
        'upsServiceCode': value.upsServiceCode,
        'fedexServiceCode': value.fedexServiceCode,
        'languageId': value.languageId,
        'localeCode': value.localeCode,
        'systemDefaultLanguageId': value.systemDefaultLanguageId,
        'shopHomePageId': value.shopHomePageId,
        'shippingServiceLabel': value.shippingServiceLabel,
        'fallbackShippingMethodId': value.fallbackShippingMethodId,
        'massiveMessageDeactivatedSalesChannel': value.massiveMessageDeactivatedSalesChannel,
        'massiveTitleDeactivatedSalesChannel': value.massiveTitleDeactivatedSalesChannel,
        'massiveSalesChannelDeactivated': value.massiveSalesChannelDeactivated,
        'massiveConsumablesCheckoutDeactivated': value.massiveConsumablesCheckoutDeactivated,
        'taxId': value.taxId,
    };
}
