"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCmsPage = instanceOfCmsPage;
exports.CmsPageFromJSON = CmsPageFromJSON;
exports.CmsPageFromJSONTyped = CmsPageFromJSONTyped;
exports.CmsPageToJSON = CmsPageToJSON;
const runtime_1 = require("../runtime");
const CmsPageConfig_1 = require("./CmsPageConfig");
const CmsSection_1 = require("./CmsSection");
const Media_1 = require("./Media");
/**
 * Check if a given object implements the CmsPage interface.
 */
function instanceOfCmsPage(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function CmsPageFromJSON(json) {
    return CmsPageFromJSONTyped(json, false);
}
function CmsPageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'type': json['type'],
        'entity': !(0, runtime_1.exists)(json, 'entity') ? undefined : json['entity'],
        'config': !(0, runtime_1.exists)(json, 'config') ? undefined : (0, CmsPageConfig_1.CmsPageConfigFromJSON)(json['config']),
        'previewMediaId': !(0, runtime_1.exists)(json, 'previewMediaId') ? undefined : json['previewMediaId'],
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : json['customFields'],
        'locked': !(0, runtime_1.exists)(json, 'locked') ? undefined : json['locked'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'sections': !(0, runtime_1.exists)(json, 'sections') ? undefined : (json['sections'].map(CmsSection_1.CmsSectionFromJSON)),
        'previewMedia': !(0, runtime_1.exists)(json, 'previewMedia') ? undefined : (0, Media_1.MediaFromJSON)(json['previewMedia']),
    };
}
function CmsPageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'type': value.type,
        'entity': value.entity,
        'config': (0, CmsPageConfig_1.CmsPageConfigToJSON)(value.config),
        'previewMediaId': value.previewMediaId,
        'customFields': value.customFields,
        'locked': value.locked,
        'sections': value.sections === undefined ? undefined : (value.sections.map(CmsSection_1.CmsSectionToJSON)),
        'previewMedia': (0, Media_1.MediaToJSON)(value.previewMedia),
    };
}
