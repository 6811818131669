"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DepartmentTypes;
(function (DepartmentTypes) {
    DepartmentTypes["BOARD"] = "Board";
    DepartmentTypes["EXTERNAL_ADVISOR"] = "External Advisor";
    DepartmentTypes["FINANCE"] = "Finance";
    DepartmentTypes["GENERAL_MANAGEMENT"] = "General Management";
    DepartmentTypes["HR"] = "HR";
    DepartmentTypes["IT"] = "IT";
    DepartmentTypes["MAINTENANCE"] = "Maintenance";
    DepartmentTypes["MARKETING"] = "Marketing";
    DepartmentTypes["PRODUCTION"] = "Production";
    DepartmentTypes["PROJECT_ENGINEERING"] = "Project Engineering";
    DepartmentTypes["PURCHASING"] = "Purchasing";
    DepartmentTypes["QUALITY"] = "Quality";
    DepartmentTypes["RD"] = "R&D";
    DepartmentTypes["RECEPTION"] = "Reception";
    DepartmentTypes["SAFETY"] = "Safety";
    DepartmentTypes["SALE"] = "Sales";
    DepartmentTypes["SERVICE"] = "Service";
})(DepartmentTypes || (DepartmentTypes = {}));
exports.default = DepartmentTypes;
