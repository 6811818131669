"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMassiveMaterial = instanceOfMassiveMaterial;
exports.MassiveMaterialFromJSON = MassiveMaterialFromJSON;
exports.MassiveMaterialFromJSONTyped = MassiveMaterialFromJSONTyped;
exports.MassiveMaterialToJSON = MassiveMaterialToJSON;
const runtime_1 = require("../runtime");
const MassiveMaterialGroup_1 = require("./MassiveMaterialGroup");
const MassiveMaterialTranslated_1 = require("./MassiveMaterialTranslated");
/**
 * Check if a given object implements the MassiveMaterial interface.
 */
function instanceOfMassiveMaterial(value) {
    let isInstance = true;
    isInstance = isInstance && "massiveMaterialGroupId" in value;
    isInstance = isInstance && "technicalName" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "translated" in value;
    return isInstance;
}
function MassiveMaterialFromJSON(json) {
    return MassiveMaterialFromJSONTyped(json, false);
}
function MassiveMaterialFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'massiveMaterialGroupId': json['massiveMaterialGroupId'],
        'technicalName': json['technicalName'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'translated': (0, MassiveMaterialTranslated_1.MassiveMaterialTranslatedFromJSON)(json['translated']),
        'massiveMaterialGroup': !(0, runtime_1.exists)(json, 'massiveMaterialGroup') ? undefined : (0, MassiveMaterialGroup_1.MassiveMaterialGroupFromJSON)(json['massiveMaterialGroup']),
    };
}
function MassiveMaterialToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'massiveMaterialGroupId': value.massiveMaterialGroupId,
        'technicalName': value.technicalName,
        'translated': (0, MassiveMaterialTranslated_1.MassiveMaterialTranslatedToJSON)(value.translated),
        'massiveMaterialGroup': (0, MassiveMaterialGroup_1.MassiveMaterialGroupToJSON)(value.massiveMaterialGroup),
    };
}
