"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Vault - Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCustomerModel = instanceOfCustomerModel;
exports.CustomerModelFromJSON = CustomerModelFromJSON;
exports.CustomerModelFromJSONTyped = CustomerModelFromJSONTyped;
exports.CustomerModelToJSON = CustomerModelToJSON;
/**
 * Check if a given object implements the CustomerModel interface.
 */
function instanceOfCustomerModel(value) {
    let isInstance = true;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "customerName" in value;
    return isInstance;
}
function CustomerModelFromJSON(json) {
    return CustomerModelFromJSONTyped(json, false);
}
function CustomerModelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'customerId': json['customerId'],
        'customerName': json['customerName'],
    };
}
function CustomerModelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'customerId': value.customerId,
        'customerName': value.customerName,
    };
}
