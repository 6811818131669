"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPaymentMethodsResponse = exports.isValidAdyenPaymentMethod = exports.isAdyenPaymentMethod = void 0;
const object_1 = require("../../common/js/helper/object");
const isAdyenPaymentMethod = (paymentMethod) => null !== (paymentMethod.extensions?.adyenData ?? null);
exports.isAdyenPaymentMethod = isAdyenPaymentMethod;
const isValidAdyenPaymentMethod = (paymentMethod) => (0, exports.isAdyenPaymentMethod)(paymentMethod) &&
    !!paymentMethod.extensions?.adyenData?.type &&
    !!paymentMethod.extensions?.adyenData?.paymentMethodConfig;
exports.isValidAdyenPaymentMethod = isValidAdyenPaymentMethod;
const isPaymentMethodsResponse = (data) => {
    if (!(0, object_1.isObject)(data)) {
        return false;
    }
    if (!(0, object_1.hasProperty)(data, 'paymentMethods')) {
        return false;
    }
    const methods = data.paymentMethods ?? null;
    return Array.isArray(methods);
};
exports.isPaymentMethodsResponse = isPaymentMethodsResponse;
