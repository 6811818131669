"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Activities - Activities API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfActivityData = instanceOfActivityData;
exports.ActivityDataFromJSON = ActivityDataFromJSON;
exports.ActivityDataFromJSONTyped = ActivityDataFromJSONTyped;
exports.ActivityDataToJSON = ActivityDataToJSON;
const runtime_1 = require("../runtime");
const ActivityDelta_1 = require("./ActivityDelta");
const ActivityReference_1 = require("./ActivityReference");
/**
 * Check if a given object implements the ActivityData interface.
 */
function instanceOfActivityData(value) {
    let isInstance = true;
    isInstance = isInstance && "topic" in value;
    isInstance = isInstance && "userReference" in value;
    isInstance = isInstance && "companyReference" in value;
    return isInstance;
}
function ActivityDataFromJSON(json) {
    return ActivityDataFromJSONTyped(json, false);
}
function ActivityDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'topic': json['topic'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'userReference': (0, ActivityReference_1.ActivityReferenceFromJSON)(json['userReference']),
        'companyReference': (0, ActivityReference_1.ActivityReferenceFromJSON)(json['companyReference']),
        'objectReference': !(0, runtime_1.exists)(json, 'objectReference') ? undefined : (0, ActivityReference_1.ActivityReferenceFromJSON)(json['objectReference']),
        'delta': !(0, runtime_1.exists)(json, 'delta') ? undefined : (json['delta'] === null ? null : json['delta'].map(ActivityDelta_1.ActivityDeltaFromJSON)),
        'data': !(0, runtime_1.exists)(json, 'data') ? undefined : json['data'],
    };
}
function ActivityDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'topic': value.topic,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'userReference': (0, ActivityReference_1.ActivityReferenceToJSON)(value.userReference),
        'companyReference': (0, ActivityReference_1.ActivityReferenceToJSON)(value.companyReference),
        'objectReference': (0, ActivityReference_1.ActivityReferenceToJSON)(value.objectReference),
        'delta': value.delta === undefined ? undefined : (value.delta === null ? null : value.delta.map(ActivityDelta_1.ActivityDeltaToJSON)),
        'data': value.data,
    };
}
