"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfUnit = instanceOfUnit;
exports.UnitFromJSON = UnitFromJSON;
exports.UnitFromJSONTyped = UnitFromJSONTyped;
exports.UnitToJSON = UnitToJSON;
const runtime_1 = require("../runtime");
const UnitTranslation_1 = require("./UnitTranslation");
/**
 * Check if a given object implements the Unit interface.
 */
function instanceOfUnit(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "shortCode" in value;
    return isInstance;
}
function UnitFromJSON(json) {
    return UnitFromJSONTyped(json, false);
}
function UnitFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'shortCode': json['shortCode'],
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : json['customFields'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, UnitTranslation_1.UnitTranslationFromJSON)(json['translated']),
    };
}
function UnitToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'shortCode': value.shortCode,
        'customFields': value.customFields,
        'translated': (0, UnitTranslation_1.UnitTranslationToJSON)(value.translated),
    };
}
