"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestBodyFilterInnerOperatorEnum = void 0;
exports.instanceOfRequestBodyFilterInner = instanceOfRequestBodyFilterInner;
exports.RequestBodyFilterInnerFromJSON = RequestBodyFilterInnerFromJSON;
exports.RequestBodyFilterInnerFromJSONTyped = RequestBodyFilterInnerFromJSONTyped;
exports.RequestBodyFilterInnerToJSON = RequestBodyFilterInnerToJSON;
const runtime_1 = require("../runtime");
/**
 * @export
 */
exports.RequestBodyFilterInnerOperatorEnum = {
    Or: 'OR',
    And: 'AND'
};
/**
 * Check if a given object implements the RequestBodyFilterInner interface.
 */
function instanceOfRequestBodyFilterInner(value) {
    let isInstance = true;
    return isInstance;
}
function RequestBodyFilterInnerFromJSON(json) {
    return RequestBodyFilterInnerFromJSONTyped(json, false);
}
function RequestBodyFilterInnerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'field': !(0, runtime_1.exists)(json, 'field') ? undefined : json['field'],
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : json['type'],
        'value': !(0, runtime_1.exists)(json, 'value') ? undefined : json['value'],
        'queries': !(0, runtime_1.exists)(json, 'queries') ? undefined : json['queries'],
        'operator': !(0, runtime_1.exists)(json, 'operator') ? undefined : json['operator'],
    };
}
function RequestBodyFilterInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'field': value.field,
        'type': value.type,
        'value': value.value,
        'queries': value.queries,
        'operator': value.operator,
    };
}
