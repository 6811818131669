"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAccountRegisterCompany = instanceOfAccountRegisterCompany;
exports.AccountRegisterCompanyFromJSON = AccountRegisterCompanyFromJSON;
exports.AccountRegisterCompanyFromJSONTyped = AccountRegisterCompanyFromJSONTyped;
exports.AccountRegisterCompanyToJSON = AccountRegisterCompanyToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the AccountRegisterCompany interface.
 */
function instanceOfAccountRegisterCompany(value) {
    let isInstance = true;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "taxId" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "zip" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "countryCode" in value;
    return isInstance;
}
function AccountRegisterCompanyFromJSON(json) {
    return AccountRegisterCompanyFromJSONTyped(json, false);
}
function AccountRegisterCompanyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'company': json['company'],
        'taxId': json['taxId'],
        'address': json['address'],
        'zip': json['zip'],
        'city': json['city'],
        'countryCode': json['countryCode'],
        'countryStateCode': !(0, runtime_1.exists)(json, 'countryStateCode') ? undefined : json['countryStateCode'],
    };
}
function AccountRegisterCompanyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'company': value.company,
        'taxId': value.taxId,
        'address': value.address,
        'zip': value.zip,
        'city': value.city,
        'countryCode': value.countryCode,
        'countryStateCode': value.countryStateCode,
    };
}
