"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeRequestChoices = void 0;
const ecommerce_1 = require("../../api/ecommerce");
exports.ChangeRequestChoices = {
    [ecommerce_1.ChangeRequestTopicEnum.Address]: [
        ecommerce_1.ChangeRequestObjectEnum.GeneralRequest,
        ecommerce_1.ChangeRequestObjectEnum.BillingAddress,
        ecommerce_1.ChangeRequestObjectEnum.ShippingAddress,
        ecommerce_1.ChangeRequestObjectEnum.CompanyAddress,
        ecommerce_1.ChangeRequestObjectEnum.AddressAddress,
    ],
    [ecommerce_1.ChangeRequestTopicEnum.Contact]: [ecommerce_1.ChangeRequestObjectEnum.GeneralRequest, ecommerce_1.ChangeRequestObjectEnum.PersonalContact],
    [ecommerce_1.ChangeRequestTopicEnum.Assets]: [ecommerce_1.ChangeRequestObjectEnum.CutterAssets, ecommerce_1.ChangeRequestObjectEnum.SoftwareAssets],
};
