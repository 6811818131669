"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSalesChannelContextExtensions = instanceOfSalesChannelContextExtensions;
exports.SalesChannelContextExtensionsFromJSON = SalesChannelContextExtensionsFromJSON;
exports.SalesChannelContextExtensionsFromJSONTyped = SalesChannelContextExtensionsFromJSONTyped;
exports.SalesChannelContextExtensionsToJSON = SalesChannelContextExtensionsToJSON;
const runtime_1 = require("../runtime");
const ContextAdyenData_1 = require("./ContextAdyenData");
const MassiveSalesChannelContext_1 = require("./MassiveSalesChannelContext");
/**
 * Check if a given object implements the SalesChannelContextExtensions interface.
 */
function instanceOfSalesChannelContextExtensions(value) {
    let isInstance = true;
    return isInstance;
}
function SalesChannelContextExtensionsFromJSON(json) {
    return SalesChannelContextExtensionsFromJSONTyped(json, false);
}
function SalesChannelContextExtensionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'massiveSalesChannelContext': !(0, runtime_1.exists)(json, 'massiveSalesChannelContext') ? undefined : (0, MassiveSalesChannelContext_1.MassiveSalesChannelContextFromJSON)(json['massiveSalesChannelContext']),
        'adyenData': !(0, runtime_1.exists)(json, 'adyenData') ? undefined : (0, ContextAdyenData_1.ContextAdyenDataFromJSON)(json['adyenData']),
    };
}
function SalesChannelContextExtensionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'massiveSalesChannelContext': (0, MassiveSalesChannelContext_1.MassiveSalesChannelContextToJSON)(value.massiveSalesChannelContext),
        'adyenData': (0, ContextAdyenData_1.ContextAdyenDataToJSON)(value.adyenData),
    };
}
