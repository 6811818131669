"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCountryData = instanceOfCountryData;
exports.CountryDataFromJSON = CountryDataFromJSON;
exports.CountryDataFromJSONTyped = CountryDataFromJSONTyped;
exports.CountryDataToJSON = CountryDataToJSON;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CountryData interface.
 */
function instanceOfCountryData(value) {
    let isInstance = true;
    return isInstance;
}
function CountryDataFromJSON(json) {
    return CountryDataFromJSONTyped(json, false);
}
function CountryDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'requiresTaxId': !(0, runtime_1.exists)(json, 'requiresTaxId') ? undefined : json['requiresTaxId'],
    };
}
function CountryDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'requiresTaxId': value.requiresTaxId,
    };
}
