"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * This file is autogenerated. Modifications will be overridden!
 * To regenerate run `bin/compile-icon-helper`.
 */
var Zupicons;
(function (Zupicons) {
    Zupicons["accessible"] = "accessible";
    Zupicons["adjust"] = "adjust";
    Zupicons["adressBook"] = "adress-book";
    Zupicons["adressCard"] = "adress-card";
    Zupicons["alarmClock"] = "alarm-clock";
    Zupicons["alarmPlus"] = "alarm-plus";
    Zupicons["album"] = "album";
    Zupicons["alien"] = "alien";
    Zupicons["alignCenter"] = "align-center";
    Zupicons["alignJustify"] = "align-justify";
    Zupicons["alignLeft"] = "align-left";
    Zupicons["alignRight"] = "align-right";
    Zupicons["allergies"] = "allergies";
    Zupicons["amazon"] = "amazon";
    Zupicons["ambulance"] = "ambulance";
    Zupicons["analytics"] = "analytics";
    Zupicons["analyticsPrimary"] = "analytics-primary";
    Zupicons["anchor"] = "anchor";
    Zupicons["angleDoubleDown"] = "angle-double-down";
    Zupicons["angleDoubleLeft"] = "angle-double-left";
    Zupicons["angleDoubleRight"] = "angle-double-right";
    Zupicons["angleDoubleUp"] = "angle-double-up";
    Zupicons["angleDown"] = "angle-down";
    Zupicons["angleLeft"] = "angle-left";
    Zupicons["angleRight"] = "angle-right";
    Zupicons["angleUp"] = "angle-up";
    Zupicons["angry"] = "angry";
    Zupicons["appStore"] = "app-store";
    Zupicons["apple"] = "apple";
    Zupicons["applePay"] = "apple-pay";
    Zupicons["archive"] = "archive";
    Zupicons["arrowAltCircleDown"] = "arrow-alt-circle-down";
    Zupicons["arrowAltCircleLeft"] = "arrow-alt-circle-left";
    Zupicons["arrowAltCircleRight"] = "arrow-alt-circle-right";
    Zupicons["arrowAltCircleUp"] = "arrow-alt-circle-up";
    Zupicons["arrowAltDown"] = "arrow-alt-down";
    Zupicons["arrowAltFromBottom"] = "arrow-alt-from-bottom";
    Zupicons["arrowAltFromLeft"] = "arrow-alt-from-left";
    Zupicons["arrowAltFromRight"] = "arrow-alt-from-right";
    Zupicons["arrowAltFromTop"] = "arrow-alt-from-top";
    Zupicons["arrowAltLeft"] = "arrow-alt-left";
    Zupicons["arrowAltRight"] = "arrow-alt-right";
    Zupicons["arrowAltSquareDown"] = "arrow-alt-square-down";
    Zupicons["arrowAltSquareLeft"] = "arrow-alt-square-left";
    Zupicons["arrowAltSquareRight"] = "arrow-alt-square-right";
    Zupicons["arrowAltSquareTop"] = "arrow-alt-square-top";
    Zupicons["arrowAltUp"] = "arrow-alt-up";
    Zupicons["arrowCircleDown"] = "arrow-circle-down";
    Zupicons["arrowCircleLeft"] = "arrow-circle-left";
    Zupicons["arrowCircleRight"] = "arrow-circle-right";
    Zupicons["arrowCircleTop"] = "arrow-circle-top";
    Zupicons["arrowDown"] = "arrow-down";
    Zupicons["arrowDownLeft"] = "arrow-down-left";
    Zupicons["arrowDownRight"] = "arrow-down-right";
    Zupicons["arrowLeft"] = "arrow-left";
    Zupicons["arrowRight"] = "arrow-right";
    Zupicons["arrowUp"] = "arrow-up";
    Zupicons["arrowUpLeft"] = "arrow-up-left";
    Zupicons["arrowUpRight"] = "arrow-up-right";
    Zupicons["arrows"] = "arrows";
    Zupicons["arrowsPrimary"] = "arrows-primary";
    Zupicons["arrowsVertical"] = "arrows-vertical";
    Zupicons["atSign"] = "at-sign";
    Zupicons["atlas"] = "atlas";
    Zupicons["atom"] = "atom";
    Zupicons["award"] = "award";
    Zupicons["axe"] = "axe";
    Zupicons["baby"] = "baby";
    Zupicons["backpack"] = "backpack";
    Zupicons["backward"] = "backward";
    Zupicons["badgeDollar"] = "badge-dollar";
    Zupicons["badgePercent"] = "badge-percent";
    Zupicons["bagShopping"] = "bag-shopping";
    Zupicons["ballot"] = "ballot";
    Zupicons["ban"] = "ban";
    Zupicons["barcode"] = "barcode";
    Zupicons["bars"] = "bars";
    Zupicons["basketball"] = "basketball";
    Zupicons["bat"] = "bat";
    Zupicons["batteryEmpty"] = "battery-empty";
    Zupicons["batteryFull"] = "battery-full";
    Zupicons["batteryHalf"] = "battery-half";
    Zupicons["batterySlash"] = "battery-slash";
    Zupicons["batteryThreeQuarters"] = "battery-three-quarters";
    Zupicons["bed"] = "bed";
    Zupicons["beer"] = "beer";
    Zupicons["bell"] = "bell";
    Zupicons["biohazard"] = "biohazard";
    Zupicons["blanket"] = "blanket";
    Zupicons["blog"] = "blog";
    Zupicons["bluetooth"] = "bluetooth";
    Zupicons["bolt"] = "bolt";
    Zupicons["book"] = "book";
    Zupicons["bookAlt"] = "book-alt";
    Zupicons["bookOpen"] = "book-open";
    Zupicons["bookmark"] = "bookmark";
    Zupicons["box"] = "box";
    Zupicons["boxFragile"] = "box-fragile";
    Zupicons["boxOpen"] = "box-open";
    Zupicons["boxUp"] = "box-up";
    Zupicons["brackets"] = "brackets";
    Zupicons["bracketsCurly"] = "brackets-curly";
    Zupicons["brain"] = "brain";
    Zupicons["briefcase"] = "briefcase";
    Zupicons["briefcaseMedical"] = "briefcase-medical";
    Zupicons["bringForward"] = "bring-forward";
    Zupicons["broadcastTower"] = "broadcast-tower";
    Zupicons["browser"] = "browser";
    Zupicons["brush"] = "brush";
    Zupicons["buffer"] = "buffer";
    Zupicons["building"] = "building";
    Zupicons["bullhorn"] = "bullhorn";
    Zupicons["bullseye"] = "bullseye";
    Zupicons["burn"] = "burn";
    Zupicons["bus"] = "bus";
    Zupicons["busAlt"] = "bus-alt";
    Zupicons["bycicle"] = "bycicle";
    Zupicons["calculator"] = "calculator";
    Zupicons["calendar"] = "calendar";
    Zupicons["calendarAlt"] = "calendar-alt";
    Zupicons["calendarCheck"] = "calendar-check";
    Zupicons["calendarDay"] = "calendar-day";
    Zupicons["calendarEdit"] = "calendar-edit";
    Zupicons["calendarMinus"] = "calendar-minus";
    Zupicons["calendarPlus"] = "calendar-plus";
    Zupicons["calendarStar"] = "calendar-star";
    Zupicons["calendarTimes"] = "calendar-times";
    Zupicons["camcorder"] = "camcorder";
    Zupicons["camera"] = "camera";
    Zupicons["cameraMovie"] = "camera-movie";
    Zupicons["campfire"] = "campfire";
    Zupicons["campground"] = "campground";
    Zupicons["capsules"] = "capsules";
    Zupicons["car"] = "car";
    Zupicons["carAlt"] = "car-alt";
    Zupicons["carBattery"] = "car-battery";
    Zupicons["carGarage"] = "car-garage";
    Zupicons["carMechanics"] = "car-mechanics";
    Zupicons["carSide"] = "car-side";
    Zupicons["carWash"] = "car-wash";
    Zupicons["caravan"] = "caravan";
    Zupicons["caretCircleAltDown"] = "caret-circle-alt-down";
    Zupicons["caretCircleAltLeft"] = "caret-circle-alt-left";
    Zupicons["caretCircleAltRight"] = "caret-circle-alt-right";
    Zupicons["caretCircleAltUp"] = "caret-circle-alt-up";
    Zupicons["caretCircleDown"] = "caret-circle-down";
    Zupicons["caretCircleLeft"] = "caret-circle-left";
    Zupicons["caretCircleRight"] = "caret-circle-right";
    Zupicons["caretCircleUp"] = "caret-circle-up";
    Zupicons["caretDown"] = "caret-down";
    Zupicons["caretLeft"] = "caret-left";
    Zupicons["caretRight"] = "caret-right";
    Zupicons["caretUp"] = "caret-up";
    Zupicons["cars"] = "cars";
    Zupicons["cartArrowDown"] = "cart-arrow-down";
    Zupicons["cartPlus"] = "cart-plus";
    Zupicons["ccAmazonPay"] = "cc-amazon-pay";
    Zupicons["ccAmex"] = "cc-amex";
    Zupicons["ccApplePay"] = "cc-apple-pay";
    Zupicons["ccDc"] = "cc-dc";
    Zupicons["ccDiscover"] = "cc-discover";
    Zupicons["ccJbc"] = "cc-jbc";
    Zupicons["ccMastercard"] = "cc-mastercard";
    Zupicons["ccPaypal"] = "cc-paypal";
    Zupicons["ccStripe"] = "cc-stripe";
    Zupicons["ccVisa"] = "cc-visa";
    Zupicons["chair"] = "chair";
    Zupicons["chargingStation"] = "charging-station";
    Zupicons["chartArea"] = "chart-area";
    Zupicons["chartBar"] = "chart-bar";
    Zupicons["chartLine"] = "chart-line";
    Zupicons["chartLineDown"] = "chart-line-down";
    Zupicons["chartNetwork"] = "chart-network";
    Zupicons["chartPie"] = "chart-pie";
    Zupicons["chartPieAlt"] = "chart-pie-alt";
    Zupicons["chartScatter"] = "chart-scatter";
    Zupicons["check"] = "check";
    Zupicons["checkCircle"] = "check-circle";
    Zupicons["checkDouble"] = "check-double";
    Zupicons["checkSquare"] = "check-square";
    Zupicons["checkSquareAlt"] = "check-square-alt";
    Zupicons["chevronCircleAltDown"] = "chevron-circle-alt-down";
    Zupicons["chevronCircleAltLeft"] = "chevron-circle-alt-left";
    Zupicons["chevronCircleAltRight"] = "chevron-circle-alt-right";
    Zupicons["chevronCircleAltUp"] = "chevron-circle-alt-up";
    Zupicons["chevronCircleDown"] = "chevron-circle-down";
    Zupicons["chevronCircleLeft"] = "chevron-circle-left";
    Zupicons["chevronCircleRight"] = "chevron-circle-right";
    Zupicons["chevronCircleUp"] = "chevron-circle-up";
    Zupicons["chrome"] = "chrome";
    Zupicons["chromecast"] = "chromecast";
    Zupicons["circle"] = "circle";
    Zupicons["clinicMedical"] = "clinic-medical";
    Zupicons["clipboard"] = "clipboard";
    Zupicons["clipboardCheck"] = "clipboard-check";
    Zupicons["clipboardList"] = "clipboard-list";
    Zupicons["clock"] = "clock";
    Zupicons["clone"] = "clone";
    Zupicons["close"] = "close";
    Zupicons["closeAlt"] = "close-alt";
    Zupicons["cloud"] = "cloud";
    Zupicons["cloudDrizzle"] = "cloud-drizzle";
    Zupicons["cloudShower"] = "cloud-shower";
    Zupicons["cloudSnow"] = "cloud-snow";
    Zupicons["code"] = "code";
    Zupicons["coffee"] = "coffee";
    Zupicons["cog"] = "cog";
    Zupicons["cogAlt"] = "cog-alt";
    Zupicons["coins"] = "coins";
    Zupicons["comment"] = "comment";
    Zupicons["commentAlt"] = "comment-alt";
    Zupicons["commentS"] = "comment-s";
    Zupicons["commentSAlt"] = "comment-s-alt";
    Zupicons["comments"] = "comments";
    Zupicons["commentsAlt"] = "comments-alt";
    Zupicons["commentsS"] = "comments-s";
    Zupicons["commentsSAlt"] = "comments-s-alt";
    Zupicons["compress"] = "compress";
    Zupicons["cookie"] = "cookie";
    Zupicons["copy"] = "copy";
    Zupicons["copyAlt"] = "copy-alt";
    Zupicons["copyright"] = "copyright";
    Zupicons["creativeCommons"] = "creative-commons";
    Zupicons["creditCard"] = "credit-card";
    Zupicons["creditCardAlt"] = "credit-card-alt";
    Zupicons["crop"] = "crop";
    Zupicons["crosshairs"] = "crosshairs";
    Zupicons["dashboard"] = "dashboard";
    Zupicons["database"] = "database";
    Zupicons["databaseAlt"] = "database-alt";
    Zupicons["dollar"] = "dollar";
    Zupicons["dolly"] = "dolly";
    Zupicons["download"] = "download";
    Zupicons["downloadAlt"] = "download-alt";
    Zupicons["downloadCloud"] = "download-cloud";
    Zupicons["edit"] = "edit";
    Zupicons["editAlt"] = "edit-alt";
    Zupicons["envelope"] = "envelope";
    Zupicons["envelopeOpen"] = "envelope-open";
    Zupicons["eraser"] = "eraser";
    Zupicons["euro"] = "euro";
    Zupicons["exchange"] = "exchange";
    Zupicons["exclamationTriangle"] = "exclamation-triangle";
    Zupicons["exclamationTriangleAlt"] = "exclamation-triangle-alt";
    Zupicons["expand"] = "expand";
    Zupicons["expandArrows"] = "expand-arrows";
    Zupicons["externalLink"] = "external-link";
    Zupicons["eye"] = "eye";
    Zupicons["eyeSlash"] = "eye-slash";
    Zupicons["facebook"] = "facebook";
    Zupicons["facebookCircle"] = "facebook-circle";
    Zupicons["facebookMessenger"] = "facebook-messenger";
    Zupicons["facebookSquare"] = "facebook-square";
    Zupicons["fastBackward"] = "fast-backward";
    Zupicons["fastForward"] = "fast-forward";
    Zupicons["fax"] = "fax";
    Zupicons["female"] = "female";
    Zupicons["figma"] = "figma";
    Zupicons["file"] = "file";
    Zupicons["fileAlt"] = "file-alt";
    Zupicons["fileArchive"] = "file-archive";
    Zupicons["fileCode"] = "file-code";
    Zupicons["fileContracts"] = "file-contracts";
    Zupicons["fileDownload"] = "file-download";
    Zupicons["fileEdit"] = "file-edit";
    Zupicons["fileExport"] = "file-export";
    Zupicons["fileImage"] = "file-image";
    Zupicons["fileImport"] = "file-import";
    Zupicons["fileMinus"] = "file-minus";
    Zupicons["fileMusic"] = "file-music";
    Zupicons["filePdf"] = "file-pdf";
    Zupicons["filePlus"] = "file-plus";
    Zupicons["filePowerpoint"] = "file-powerpoint";
    Zupicons["fileSearch"] = "file-search";
    Zupicons["fileUpload"] = "file-upload";
    Zupicons["fileUser"] = "file-user";
    Zupicons["fileVideo"] = "file-video";
    Zupicons["fileWord"] = "file-word";
    Zupicons["film"] = "film";
    Zupicons["filter"] = "filter";
    Zupicons["fingerprint"] = "fingerprint";
    Zupicons["firstAid"] = "first-aid";
    Zupicons["flag"] = "flag";
    Zupicons["flickr"] = "flickr";
    Zupicons["folder"] = "folder";
    Zupicons["folderDownload"] = "folder-download";
    Zupicons["folderMinus"] = "folder-minus";
    Zupicons["folderOpen"] = "folder-open";
    Zupicons["folderPlus"] = "folder-plus";
    Zupicons["folderUpload"] = "folder-upload";
    Zupicons["folders"] = "folders";
    Zupicons["fontCase"] = "font-case";
    Zupicons["fonts"] = "fonts";
    Zupicons["forward"] = "forward";
    Zupicons["forward1"] = "forward-1";
    Zupicons["gasPump"] = "gas-pump";
    Zupicons["gasPumpSlash"] = "gas-pump-slash";
    Zupicons["glass"] = "glass";
    Zupicons["glassAlt"] = "glass-alt";
    Zupicons["globe"] = "globe";
    Zupicons["google"] = "google";
    Zupicons["googleDrive"] = "google-drive";
    Zupicons["googlePlay"] = "google-play";
    Zupicons["googlePlus"] = "google-plus";
    Zupicons["googlePlusAlt"] = "google-plus-alt";
    Zupicons["graduation"] = "graduation";
    Zupicons["greaterThan"] = "greater-than";
    Zupicons["handPaper"] = "hand-paper";
    Zupicons["handPaperAlt"] = "hand-paper-alt";
    Zupicons["handPoint"] = "hand-point";
    Zupicons["handPointAlt"] = "hand-point-alt";
    Zupicons["handPointer"] = "hand-pointer";
    Zupicons["headSide"] = "head-side";
    Zupicons["heart"] = "heart";
    Zupicons["heartRate"] = "heart-rate";
    Zupicons["heat"] = "heat";
    Zupicons["history"] = "history";
    Zupicons["holdingSupport"] = "holding-support";
    Zupicons["home"] = "home";
    Zupicons["homeAlt"] = "home-alt";
    Zupicons["homeDamage"] = "home-damage";
    Zupicons["homeFilled"] = "home-filled";
    Zupicons["hospitalSymbol"] = "hospital-symbol";
    Zupicons["hourglass"] = "hourglass";
    Zupicons["idCard"] = "id-card";
    Zupicons["idCardAlt"] = "id-card-alt";
    Zupicons["image"] = "image";
    Zupicons["imageAlt"] = "image-alt";
    Zupicons["images"] = "images";
    Zupicons["inbox"] = "inbox";
    Zupicons["inboxIn"] = "inbox-in";
    Zupicons["inboxOut"] = "inbox-out";
    Zupicons["industry"] = "industry";
    Zupicons["industryAlt"] = "industry-alt";
    Zupicons["info"] = "info";
    Zupicons["info1"] = "info-1";
    Zupicons["infoAlt"] = "info-alt";
    Zupicons["instagram"] = "instagram";
    Zupicons["itunes"] = "itunes";
    Zupicons["joystick"] = "joystick";
    Zupicons["js"] = "js";
    Zupicons["jug"] = "jug";
    Zupicons["key"] = "key";
    Zupicons["keyAlt"] = "key-alt";
    Zupicons["keyboard"] = "keyboard";
    Zupicons["keynote"] = "keynote";
    Zupicons["knife"] = "knife";
    Zupicons["landmark"] = "landmark";
    Zupicons["language"] = "language";
    Zupicons["languageAlt"] = "language-alt";
    Zupicons["laptop"] = "laptop";
    Zupicons["layerGroup"] = "layer-group";
    Zupicons["layerMinus"] = "layer-minus";
    Zupicons["leaf"] = "leaf";
    Zupicons["lessThan"] = "less-than";
    Zupicons["lightbulb"] = "lightbulb";
    Zupicons["lightbulbExclamation"] = "lightbulb-exclamation";
    Zupicons["lineColumns"] = "line-columns";
    Zupicons["lineHeight"] = "line-height";
    Zupicons["link"] = "link";
    Zupicons["linkedin"] = "linkedin";
    Zupicons["lira"] = "lira";
    Zupicons["list"] = "list";
    Zupicons["listMusic"] = "list-music";
    Zupicons["listOl"] = "list-ol";
    Zupicons["location"] = "location";
    Zupicons["locationArrow"] = "location-arrow";
    Zupicons["locationCircle"] = "location-circle";
    Zupicons["lock"] = "lock";
    Zupicons["lockAlt"] = "lock-alt";
    Zupicons["lockOpen"] = "lock-open";
    Zupicons["lockOpenAlt"] = "lock-open-alt";
    Zupicons["luggageCart"] = "luggage-cart";
    Zupicons["magnet"] = "magnet";
    Zupicons["mailbox"] = "mailbox";
    Zupicons["male"] = "male";
    Zupicons["map"] = "map";
    Zupicons["mapMarker"] = "map-marker";
    Zupicons["mapMarkerMinus"] = "map-marker-minus";
    Zupicons["mapMarkerPlus"] = "map-marker-plus";
    Zupicons["mapPin"] = "map-pin";
    Zupicons["mapSigns"] = "map-signs";
    Zupicons["medal"] = "medal";
    Zupicons["megaphone"] = "megaphone";
    Zupicons["microphone"] = "microphone";
    Zupicons["microphoneAlt"] = "microphone-alt";
    Zupicons["microphoneAltSlash"] = "microphone-alt-slash";
    Zupicons["microphoneSlash"] = "microphone-slash";
    Zupicons["microsoft"] = "microsoft";
    Zupicons["microwave"] = "microwave";
    Zupicons["minus"] = "minus";
    Zupicons["minusCircle"] = "minus-circle";
    Zupicons["mobile"] = "mobile";
    Zupicons["mobileAlt"] = "mobile-alt";
    Zupicons["money"] = "money";
    Zupicons["moneyCheck"] = "money-check";
    Zupicons["moon"] = "moon";
    Zupicons["mountain"] = "mountain";
    Zupicons["mouse"] = "mouse";
    Zupicons["mouseAlt"] = "mouse-alt";
    Zupicons["mousePointer"] = "mouse-pointer";
    Zupicons["mousePointerAlt"] = "mouse-pointer-alt";
    Zupicons["mug"] = "mug";
    Zupicons["music"] = "music";
    Zupicons["neuter"] = "neuter";
    Zupicons["newspaper"] = "newspaper";
    Zupicons["notEqual"] = "not-equal";
    Zupicons["notesMedical"] = "notes-medical";
    Zupicons["oilCan"] = "oil-can";
    Zupicons["oilTemp"] = "oil-temp";
    Zupicons["omega"] = "omega";
    Zupicons["outdent"] = "outdent";
    Zupicons["outlet"] = "outlet";
    Zupicons["overline"] = "overline";
    Zupicons["pageBreak"] = "page-break";
    Zupicons["paintBrush"] = "paint-brush";
    Zupicons["palette"] = "palette";
    Zupicons["paperclip"] = "paperclip";
    Zupicons["paperplane"] = "paperplane";
    Zupicons["paragraph"] = "paragraph";
    Zupicons["parking"] = "parking";
    Zupicons["passport"] = "passport";
    Zupicons["paste"] = "paste";
    Zupicons["pause"] = "pause";
    Zupicons["pauseCircle"] = "pause-circle";
    Zupicons["paypal"] = "paypal";
    Zupicons["peace"] = "peace";
    Zupicons["pen"] = "pen";
    Zupicons["pencil"] = "pencil";
    Zupicons["percent"] = "percent";
    Zupicons["phone"] = "phone";
    Zupicons["phonePlus"] = "phone-plus";
    Zupicons["phoneRotary"] = "phone-rotary";
    Zupicons["phoneSlash"] = "phone-slash";
    Zupicons["phoneSquare"] = "phone-square";
    Zupicons["pi"] = "pi";
    Zupicons["pills"] = "pills";
    Zupicons["pinterest"] = "pinterest";
    Zupicons["pinterestCircle"] = "pinterest-circle";
    Zupicons["plane"] = "plane";
    Zupicons["planeArrival"] = "plane-arrival";
    Zupicons["planeDeparture"] = "plane-departure";
    Zupicons["play"] = "play";
    Zupicons["playAlt"] = "play-alt";
    Zupicons["playCircle"] = "play-circle";
    Zupicons["plug"] = "plug";
    Zupicons["plus"] = "plus";
    Zupicons["plus1"] = "plus-1";
    Zupicons["plusCircle"] = "plus-circle";
    Zupicons["podcast"] = "podcast";
    Zupicons["portrait"] = "portrait";
    Zupicons["pound"] = "pound";
    Zupicons["powerOff"] = "power-off";
    Zupicons["presentation"] = "presentation";
    Zupicons["print"] = "print";
    Zupicons["print1"] = "print-1";
    Zupicons["puzzlePiece"] = "puzzle-piece";
    Zupicons["qrCode"] = "qr-code";
    Zupicons["questionCircle"] = "question-circle";
    Zupicons["questionCircle1"] = "question-circle-1";
    Zupicons["quoteLeft"] = "quote-left";
    Zupicons["quoteRight"] = "quote-right";
    Zupicons["radiation"] = "radiation";
    Zupicons["radiationAlt"] = "radiation-alt";
    Zupicons["radio"] = "radio";
    Zupicons["random"] = "random";
    Zupicons["react"] = "react";
    Zupicons["recycle"] = "recycle";
    Zupicons["redo"] = "redo";
    Zupicons["repeat"] = "repeat";
    Zupicons["reply"] = "reply";
    Zupicons["replyAll"] = "reply-all";
    Zupicons["restroom"] = "restroom";
    Zupicons["retweet"] = "retweet";
    Zupicons["router"] = "router";
    Zupicons["rss"] = "rss";
    Zupicons["ruble"] = "ruble";
    Zupicons["running"] = "running";
    Zupicons["rupee"] = "rupee";
    Zupicons["sackDollar"] = "sack-dollar";
    Zupicons["satelliteDish"] = "satellite-dish";
    Zupicons["save"] = "save";
    Zupicons["save1"] = "save-1";
    Zupicons["scalpel"] = "scalpel";
    Zupicons["scannerImage"] = "scanner-image";
    Zupicons["scroll"] = "scroll";
    Zupicons["sdCard"] = "sd-card";
    Zupicons["search"] = "search";
    Zupicons["searchMinus"] = "search-minus";
    Zupicons["searchPlus"] = "search-plus";
    Zupicons["sensor"] = "sensor";
    Zupicons["sensorAlert"] = "sensor-alert";
    Zupicons["serialNumber"] = "serial-number";
    Zupicons["server"] = "server";
    Zupicons["share"] = "share";
    Zupicons["share1"] = "share-1";
    Zupicons["shareAll"] = "share-all";
    Zupicons["shareAll1"] = "share-all-1";
    Zupicons["shareAlt"] = "share-alt";
    Zupicons["shareAlt1"] = "share-alt-1";
    Zupicons["shield"] = "shield";
    Zupicons["shieldAlt"] = "shield-alt";
    Zupicons["shieldCheck"] = "shield-check";
    Zupicons["shoppingBag"] = "shopping-bag";
    Zupicons["shoppingBasket"] = "shopping-basket";
    Zupicons["shoppingCart"] = "shopping-cart";
    Zupicons["showel"] = "showel";
    Zupicons["shredder"] = "shredder";
    Zupicons["sigma"] = "sigma";
    Zupicons["signIn"] = "sign-in";
    Zupicons["signIn1"] = "sign-in-1";
    Zupicons["signOut"] = "sign-out";
    Zupicons["signOut1"] = "sign-out-1";
    Zupicons["signal"] = "signal";
    Zupicons["signal1"] = "signal-1";
    Zupicons["signalSlash"] = "signal-slash";
    Zupicons["signalStream"] = "signal-stream";
    Zupicons["simCard"] = "sim-card";
    Zupicons["siren"] = "siren";
    Zupicons["sitemap"] = "sitemap";
    Zupicons["sitemap1"] = "sitemap-1";
    Zupicons["skiJump"] = "ski-jump";
    Zupicons["skull"] = "skull";
    Zupicons["slidersH"] = "sliders-h";
    Zupicons["slidersV"] = "sliders-v";
    Zupicons["smile"] = "smile";
    Zupicons["smoking"] = "smoking";
    Zupicons["sms"] = "sms";
    Zupicons["snapchat"] = "snapchat";
    Zupicons["snooze"] = "snooze";
    Zupicons["snowflake"] = "snowflake";
    Zupicons["solarpanel"] = "solarpanel";
    Zupicons["sort"] = "sort";
    Zupicons["sortAlpha"] = "sort-alpha";
    Zupicons["sortAlt"] = "sort-alt";
    Zupicons["sortAmountDown"] = "sort-amount-down";
    Zupicons["sortAmountUp"] = "sort-amount-up";
    Zupicons["sortAplhaUp"] = "sort-aplha-up";
    Zupicons["spellCheck"] = "spell-check";
    Zupicons["star"] = "star";
    Zupicons["starFilled"] = "star-filled";
    Zupicons["stepBackwards"] = "step-backwards";
    Zupicons["stepForwards"] = "step-forwards";
    Zupicons["stickyNote"] = "sticky-note";
    Zupicons["stop"] = "stop";
    Zupicons["store"] = "store";
    Zupicons["stream"] = "stream";
    Zupicons["subway"] = "subway";
    Zupicons["subway1"] = "subway-1";
    Zupicons["suitcase"] = "suitcase";
    Zupicons["suitcase1"] = "suitcase-1";
    Zupicons["sun"] = "sun";
    Zupicons["sunglasses"] = "sunglasses";
    Zupicons["swimmer"] = "swimmer";
    Zupicons["sync"] = "sync";
    Zupicons["table"] = "table";
    Zupicons["tablet"] = "tablet";
    Zupicons["tabletAlt"] = "tablet-alt";
    Zupicons["tachometer"] = "tachometer";
    Zupicons["tag"] = "tag";
    Zupicons["tags"] = "tags";
    Zupicons["tally"] = "tally";
    Zupicons["tasks"] = "tasks";
    Zupicons["taxi"] = "taxi";
    Zupicons["temperature"] = "temperature";
    Zupicons["terminal"] = "terminal";
    Zupicons["thermometer"] = "thermometer";
    Zupicons["thumbsDown"] = "thumbs-down";
    Zupicons["thumbsUp"] = "thumbs-up";
    Zupicons["thumbtack"] = "thumbtack";
    Zupicons["thunderstorm"] = "thunderstorm";
    Zupicons["thunderstorm1"] = "thunderstorm-1";
    Zupicons["ticket"] = "ticket";
    Zupicons["trash"] = "trash";
    Zupicons["trashAlt"] = "trash-alt";
    Zupicons["trashRestore"] = "trash-restore";
    Zupicons["truck"] = "truck";
    Zupicons["tv"] = "tv";
    Zupicons["twitter"] = "twitter";
    Zupicons["typewriter"] = "typewriter";
    Zupicons["umbrella"] = "umbrella";
    Zupicons["undo"] = "undo";
    Zupicons["union"] = "union";
    Zupicons["university"] = "university";
    Zupicons["unlink"] = "unlink";
    Zupicons["unlock"] = "unlock";
    Zupicons["unlock1"] = "unlock-1";
    Zupicons["upload"] = "upload";
    Zupicons["upload1"] = "upload-1";
    Zupicons["uploadCloud"] = "upload-cloud";
    Zupicons["usb"] = "usb";
    Zupicons["usbDrive"] = "usb-drive";
    Zupicons["user"] = "user";
    Zupicons["userAlt"] = "user-alt";
    Zupicons["userAltSlash"] = "user-alt-slash";
    Zupicons["userEdit"] = "user-edit";
    Zupicons["userPlus"] = "user-plus";
    Zupicons["utensiles"] = "utensiles";
    Zupicons["video"] = "video";
    Zupicons["videoSlash"] = "video-slash";
    Zupicons["vimeo"] = "vimeo";
    Zupicons["voice"] = "voice";
    Zupicons["voiceSlash"] = "voice-slash";
    Zupicons["voicemail"] = "voicemail";
    Zupicons["volumeMute"] = "volume-mute";
    Zupicons["walking"] = "walking";
    Zupicons["wallet"] = "wallet";
    Zupicons["warehouse"] = "warehouse";
    Zupicons["waveform"] = "waveform";
    Zupicons["webcam"] = "webcam";
    Zupicons["weight"] = "weight";
    Zupicons["whatsapp"] = "whatsapp";
    Zupicons["wheelchair"] = "wheelchair";
    Zupicons["wifi"] = "wifi";
    Zupicons["wind"] = "wind";
    Zupicons["window"] = "window";
    Zupicons["windows"] = "windows";
    Zupicons["wrench"] = "wrench";
    Zupicons["yahoo"] = "yahoo";
    Zupicons["yen"] = "yen";
    Zupicons["yinYang"] = "yin-yang";
    Zupicons["youtube"] = "youtube";
})(Zupicons || (Zupicons = {}));
exports.default = Zupicons;
