"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfLineItemRequest = instanceOfLineItemRequest;
exports.LineItemRequestFromJSON = LineItemRequestFromJSON;
exports.LineItemRequestFromJSONTyped = LineItemRequestFromJSONTyped;
exports.LineItemRequestToJSON = LineItemRequestToJSON;
const LineItem_1 = require("./LineItem");
/**
 * Check if a given object implements the LineItemRequest interface.
 */
function instanceOfLineItemRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "items" in value;
    return isInstance;
}
function LineItemRequestFromJSON(json) {
    return LineItemRequestFromJSONTyped(json, false);
}
function LineItemRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'items': (json['items'].map(LineItem_1.LineItemFromJSON)),
    };
}
function LineItemRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'items': (value.items.map(LineItem_1.LineItemToJSON)),
    };
}
