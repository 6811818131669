"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Assets - Assets API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSoftwareOfferItem = instanceOfSoftwareOfferItem;
exports.SoftwareOfferItemFromJSON = SoftwareOfferItemFromJSON;
exports.SoftwareOfferItemFromJSONTyped = SoftwareOfferItemFromJSONTyped;
exports.SoftwareOfferItemToJSON = SoftwareOfferItemToJSON;
const runtime_1 = require("../runtime");
const SoftwareOfferItemTranslations_1 = require("./SoftwareOfferItemTranslations");
/**
 * Check if a given object implements the SoftwareOfferItem interface.
 */
function instanceOfSoftwareOfferItem(value) {
    let isInstance = true;
    isInstance = isInstance && "licenseId" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "currencyCode" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "isAdvancedPayment" in value;
    isInstance = isInstance && "translations" in value;
    return isInstance;
}
function SoftwareOfferItemFromJSON(json) {
    return SoftwareOfferItemFromJSONTyped(json, false);
}
function SoftwareOfferItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'licenseId': json['licenseId'],
        'productId': json['productId'],
        'customerId': json['customerId'],
        'startDate': !(0, runtime_1.exists)(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !(0, runtime_1.exists)(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'currencyCode': json['currencyCode'],
        'containerNumber': !(0, runtime_1.exists)(json, 'containerNumber') ? undefined : json['containerNumber'],
        'clientName': !(0, runtime_1.exists)(json, 'clientName') ? undefined : json['clientName'],
        'machineSerialNumber': !(0, runtime_1.exists)(json, 'machineSerialNumber') ? undefined : json['machineSerialNumber'],
        'price': json['price'],
        'orderedAt': !(0, runtime_1.exists)(json, 'orderedAt') ? undefined : (json['orderedAt'] === null ? null : new Date(json['orderedAt'])),
        'offerEndDate': !(0, runtime_1.exists)(json, 'offerEndDate') ? undefined : (json['offerEndDate'] === null ? null : new Date(json['offerEndDate'])),
        'isAdvancedPayment': json['isAdvancedPayment'],
        'licenseCount': !(0, runtime_1.exists)(json, 'licenseCount') ? undefined : json['licenseCount'],
        'orderItemNumber': !(0, runtime_1.exists)(json, 'orderItemNumber') ? undefined : json['orderItemNumber'],
        'translations': (0, SoftwareOfferItemTranslations_1.SoftwareOfferItemTranslationsFromJSON)(json['translations']),
        'shopOrderId': !(0, runtime_1.exists)(json, 'shopOrderId') ? undefined : json['shopOrderId'],
        'shopOrderNumber': !(0, runtime_1.exists)(json, 'shopOrderNumber') ? undefined : json['shopOrderNumber'],
    };
}
function SoftwareOfferItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'licenseId': value.licenseId,
        'productId': value.productId,
        'customerId': value.customerId,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'currencyCode': value.currencyCode,
        'containerNumber': value.containerNumber,
        'clientName': value.clientName,
        'machineSerialNumber': value.machineSerialNumber,
        'price': value.price,
        'orderedAt': value.orderedAt === undefined ? undefined : (value.orderedAt === null ? null : value.orderedAt.toISOString()),
        'offerEndDate': value.offerEndDate === undefined ? undefined : (value.offerEndDate === null ? null : value.offerEndDate.toISOString()),
        'isAdvancedPayment': value.isAdvancedPayment,
        'licenseCount': value.licenseCount,
        'orderItemNumber': value.orderItemNumber,
        'translations': (0, SoftwareOfferItemTranslations_1.SoftwareOfferItemTranslationsToJSON)(value.translations),
        'shopOrderId': value.shopOrderId,
        'shopOrderNumber': value.shopOrderNumber,
    };
}
