"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPropertyGroupExtensions = instanceOfPropertyGroupExtensions;
exports.PropertyGroupExtensionsFromJSON = PropertyGroupExtensionsFromJSON;
exports.PropertyGroupExtensionsFromJSONTyped = PropertyGroupExtensionsFromJSONTyped;
exports.PropertyGroupExtensionsToJSON = PropertyGroupExtensionsToJSON;
const runtime_1 = require("../runtime");
const MassivePropertyGroup_1 = require("./MassivePropertyGroup");
/**
 * Check if a given object implements the PropertyGroupExtensions interface.
 */
function instanceOfPropertyGroupExtensions(value) {
    let isInstance = true;
    return isInstance;
}
function PropertyGroupExtensionsFromJSON(json) {
    return PropertyGroupExtensionsFromJSONTyped(json, false);
}
function PropertyGroupExtensionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'massivePropertyGroup': !(0, runtime_1.exists)(json, 'massivePropertyGroup') ? undefined : (0, MassivePropertyGroup_1.MassivePropertyGroupFromJSON)(json['massivePropertyGroup']),
    };
}
function PropertyGroupExtensionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'massivePropertyGroup': (0, MassivePropertyGroup_1.MassivePropertyGroupToJSON)(value.massivePropertyGroup),
    };
}
