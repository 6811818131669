"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var OrderTransactionStates;
(function (OrderTransactionStates) {
    OrderTransactionStates["authorized"] = "authorized";
    OrderTransactionStates["cancelled"] = "cancelled";
    OrderTransactionStates["failed"] = "failed";
    OrderTransactionStates["inProgress"] = "in_progress";
    OrderTransactionStates["open"] = "open";
    OrderTransactionStates["paid"] = "paid";
    OrderTransactionStates["paidPartially"] = "paid_partially";
    OrderTransactionStates["refunded"] = "refunded";
    OrderTransactionStates["refundedPartially"] = "refunded_partially";
})(OrderTransactionStates || (OrderTransactionStates = {}));
exports.default = OrderTransactionStates;
