"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMediaThumbnail = instanceOfMediaThumbnail;
exports.MediaThumbnailFromJSON = MediaThumbnailFromJSON;
exports.MediaThumbnailFromJSONTyped = MediaThumbnailFromJSONTyped;
exports.MediaThumbnailToJSON = MediaThumbnailToJSON;
const runtime_1 = require("../runtime");
const MediaThumbnailMedia_1 = require("./MediaThumbnailMedia");
/**
 * Check if a given object implements the MediaThumbnail interface.
 */
function instanceOfMediaThumbnail(value) {
    let isInstance = true;
    isInstance = isInstance && "mediaId" in value;
    isInstance = isInstance && "width" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function MediaThumbnailFromJSON(json) {
    return MediaThumbnailFromJSONTyped(json, false);
}
function MediaThumbnailFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'mediaId': json['mediaId'],
        'width': json['width'],
        'height': json['height'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'customFields': !(0, runtime_1.exists)(json, 'customFields') ? undefined : json['customFields'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'media': !(0, runtime_1.exists)(json, 'media') ? undefined : (0, MediaThumbnailMedia_1.MediaThumbnailMediaFromJSON)(json['media']),
    };
}
function MediaThumbnailToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'mediaId': value.mediaId,
        'url': value.url,
        'customFields': value.customFields,
        'media': (0, MediaThumbnailMedia_1.MediaThumbnailMediaToJSON)(value.media),
    };
}
