"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPropertyGroupOption = instanceOfPropertyGroupOption;
exports.PropertyGroupOptionFromJSON = PropertyGroupOptionFromJSON;
exports.PropertyGroupOptionFromJSONTyped = PropertyGroupOptionFromJSONTyped;
exports.PropertyGroupOptionToJSON = PropertyGroupOptionToJSON;
const runtime_1 = require("../runtime");
const Media_1 = require("./Media");
const PropertyGroup_1 = require("./PropertyGroup");
const PropertyGroupOptionTranslated_1 = require("./PropertyGroupOptionTranslated");
/**
 * Check if a given object implements the PropertyGroupOption interface.
 */
function instanceOfPropertyGroupOption(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
function PropertyGroupOptionFromJSON(json) {
    return PropertyGroupOptionFromJSONTyped(json, false);
}
function PropertyGroupOptionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'groupId': json['groupId'],
        'mediaId': !(0, runtime_1.exists)(json, 'mediaId') ? undefined : json['mediaId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'translated': !(0, runtime_1.exists)(json, 'translated') ? undefined : (0, PropertyGroupOptionTranslated_1.PropertyGroupOptionTranslatedFromJSON)(json['translated']),
        'media': !(0, runtime_1.exists)(json, 'media') ? undefined : (0, Media_1.MediaFromJSON)(json['media']),
        'group': !(0, runtime_1.exists)(json, 'group') ? undefined : (0, PropertyGroup_1.PropertyGroupFromJSON)(json['group']),
    };
}
function PropertyGroupOptionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'groupId': value.groupId,
        'mediaId': value.mediaId,
        'translated': (0, PropertyGroupOptionTranslated_1.PropertyGroupOptionTranslatedToJSON)(value.translated),
        'media': (0, Media_1.MediaToJSON)(value.media),
        'group': (0, PropertyGroup_1.PropertyGroupToJSON)(value.group),
    };
}
