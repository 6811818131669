"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductType;
(function (ProductType) {
    ProductType["MINDCAD"] = "mindcad";
    ProductType["MINDCUT"] = "mindcut";
    ProductType["MINDGEST"] = "mindgest";
    ProductType["MINDGUEST"] = "mindguest";
    ProductType["PRIMECENTER"] = "primecenter";
    ProductType["ZUND_CONNECT"] = "zund_connect";
    ProductType["ZUND_CUT_CENTER"] = "zund_cut_center";
    ProductType["ZUND_DESIGN_CENTER"] = "zund_design_center";
    ProductType["ZUND_PRECUT_CENTER"] = "zund_precut_center";
})(ProductType || (ProductType = {}));
exports.default = ProductType;
