"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeRequestObjectEnum = exports.ChangeRequestTopicEnum = void 0;
exports.instanceOfChangeRequest = instanceOfChangeRequest;
exports.ChangeRequestFromJSON = ChangeRequestFromJSON;
exports.ChangeRequestFromJSONTyped = ChangeRequestFromJSONTyped;
exports.ChangeRequestToJSON = ChangeRequestToJSON;
/**
 * @export
 */
exports.ChangeRequestTopicEnum = {
    Address: 'address',
    Contact: 'contact',
    Assets: 'assets'
};
/**
 * @export
 */
exports.ChangeRequestObjectEnum = {
    AddressAddress: 'address_address',
    BillingAddress: 'billing_address',
    CompanyAddress: 'company_address',
    CutterAssets: 'cutter_assets',
    DeleteAddress: 'delete_address',
    GeneralRequest: 'general_request',
    PersonalContact: 'personal_contact',
    ShippingAddress: 'shipping_address',
    SoftwareAssets: 'software_assets'
};
/**
 * Check if a given object implements the ChangeRequest interface.
 */
function instanceOfChangeRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "topic" in value;
    isInstance = isInstance && "object" in value;
    isInstance = isInstance && "data" in value;
    return isInstance;
}
function ChangeRequestFromJSON(json) {
    return ChangeRequestFromJSONTyped(json, false);
}
function ChangeRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'topic': json['topic'],
        'object': json['object'],
        'data': json['data'],
    };
}
function ChangeRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'topic': value.topic,
        'object': value.object,
        'data': value.data,
    };
}
