"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MassiveMachineWarrantyStateEnum = exports.MassiveMachineTypeEnum = void 0;
exports.instanceOfMassiveMachine = instanceOfMassiveMachine;
exports.MassiveMachineFromJSON = MassiveMachineFromJSON;
exports.MassiveMachineFromJSONTyped = MassiveMachineFromJSONTyped;
exports.MassiveMachineToJSON = MassiveMachineToJSON;
const runtime_1 = require("../runtime");
const MassiveAddress_1 = require("./MassiveAddress");
const MassiveCompany_1 = require("./MassiveCompany");
const Media_1 = require("./Media");
/**
 * @export
 */
exports.MassiveMachineTypeEnum = {
    Cutter: 'cutter',
    Module: 'module'
};
/**
 * @export
 */
exports.MassiveMachineWarrantyStateEnum = {
    Within: 'within',
    Without: 'without'
};
/**
 * Check if a given object implements the MassiveMachine interface.
 */
function instanceOfMassiveMachine(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "massiveAddressId" in value;
    isInstance = isInstance && "massiveCompanyId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "typeNumber" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "warrantyDuration" in value;
    isInstance = isInstance && "externalId" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "warrantyState" in value;
    isInstance = isInstance && "shopSoftwareOrderEnabled" in value;
    return isInstance;
}
function MassiveMachineFromJSON(json) {
    return MassiveMachineFromJSONTyped(json, false);
}
function MassiveMachineFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'massiveAddressId': json['massiveAddressId'],
        'massiveCompanyId': json['massiveCompanyId'],
        'name': json['name'],
        'typeNumber': json['typeNumber'],
        'type': json['type'],
        'installationDate': !(0, runtime_1.exists)(json, 'installationDate') ? undefined : (new Date(json['installationDate'])),
        'deliveryDate': !(0, runtime_1.exists)(json, 'deliveryDate') ? undefined : (new Date(json['deliveryDate'])),
        'warrantyEndDate': !(0, runtime_1.exists)(json, 'warrantyEndDate') ? undefined : (new Date(json['warrantyEndDate'])),
        'warrantyDuration': json['warrantyDuration'],
        'externalId': json['externalId'],
        'deleted': json['deleted'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'massiveAddress': !(0, runtime_1.exists)(json, 'massiveAddress') ? undefined : (0, MassiveAddress_1.MassiveAddressFromJSON)(json['massiveAddress']),
        'massiveCompany': !(0, runtime_1.exists)(json, 'massiveCompany') ? undefined : (0, MassiveCompany_1.MassiveCompanyFromJSON)(json['massiveCompany']),
        'media': !(0, runtime_1.exists)(json, 'media') ? undefined : (0, Media_1.MediaFromJSON)(json['media']),
        'warrantyState': json['warrantyState'],
        'shopSoftwareOrderEnabled': json['shopSoftwareOrderEnabled'],
    };
}
function MassiveMachineToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'massiveAddressId': value.massiveAddressId,
        'massiveCompanyId': value.massiveCompanyId,
        'name': value.name,
        'typeNumber': value.typeNumber,
        'type': value.type,
        'installationDate': value.installationDate === undefined ? undefined : (value.installationDate.toISOString()),
        'deliveryDate': value.deliveryDate === undefined ? undefined : (value.deliveryDate.toISOString()),
        'warrantyEndDate': value.warrantyEndDate === undefined ? undefined : (value.warrantyEndDate.toISOString()),
        'warrantyDuration': value.warrantyDuration,
        'externalId': value.externalId,
        'deleted': value.deleted,
        'massiveAddress': (0, MassiveAddress_1.MassiveAddressToJSON)(value.massiveAddress),
        'massiveCompany': (0, MassiveCompany_1.MassiveCompanyToJSON)(value.massiveCompany),
        'media': (0, Media_1.MediaToJSON)(value.media),
        'warrantyState': value.warrantyState,
        'shopSoftwareOrderEnabled': value.shopSoftwareOrderEnabled,
    };
}
