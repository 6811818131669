"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class CartApi extends runtime.BaseAPI {
    /**
     * Add line items to cart.
     */
    async addLineItemsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/store-api/checkout/cart/line-item`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.LineItemRequestToJSON)(requestParameters.lineItemRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.CartFromJSON)(jsonValue));
    }
    /**
     * Add line items to cart.
     */
    async addLineItems(requestParameters = {}, initOverrides) {
        const response = await this.addLineItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Deletes the entire cart.
     */
    async deleteCartRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/store-api/checkout/cart`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Deletes the entire cart.
     */
    async deleteCart(initOverrides) {
        await this.deleteCartRaw(initOverrides);
    }
    /**
     * Deletes line items of cart.
     */
    async deleteLineItemsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/store-api/checkout/cart/line-item`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.DeleteByIdsRequestToJSON)(requestParameters.deleteByIdsRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.CartFromJSON)(jsonValue));
    }
    /**
     * Deletes line items of cart.
     */
    async deleteLineItems(requestParameters = {}, initOverrides) {
        const response = await this.deleteLineItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Fetches the current cart.
     */
    async readCartRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (requestParameters.portalArea !== undefined && requestParameters.portalArea !== null) {
            headerParameters['portal-area'] = String(requestParameters.portalArea);
        }
        const response = await this.request({
            path: `/store-api/checkout/cart`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.CartFromJSON)(jsonValue));
    }
    /**
     * Fetches the current cart.
     */
    async readCart(requestParameters = {}, initOverrides) {
        const response = await this.readCartRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Updates line items of cart.
     */
    async updateLineItemsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/store-api/checkout/cart/line-item`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.LineItemRequestToJSON)(requestParameters.lineItemRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.CartFromJSON)(jsonValue));
    }
    /**
     * Updates line items of cart.
     */
    async updateLineItems(requestParameters = {}, initOverrides) {
        const response = await this.updateLineItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.CartApi = CartApi;
